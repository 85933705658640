import {
    Button,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    getKeyValue,
    Tooltip
} from "@nextui-org/react";
import { IoIosRefresh } from "react-icons/io";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Api from "../../global/Api";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalState";
import { IoIosInformationCircle } from "react-icons/io";

const COLUMNS = [
    { key: "name", label: "Name" },
    { key: "steps", label: "Steps" },
    { key: "created", label: "Created" },
    { key: "actions", label: "Actions" }
];

export default function Templates() {
    const navigate = useNavigate();

    const templates = useHookstate(GlobalState.templates).get();

    const retrieveTemplates = useCallback(async () => {
        const response = await Api.templates.retrieveTemplates();
        if (!("error" in response)) {
            GlobalState.templates.set(response.templates);
        }
    }, []);

    useEffect(() => {
        retrieveTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [deleting, setDeleting] = useState(false);

    const rows = templates.map((template) => {
        return {
            templateId: template.templateId,
            name: template.name,
            steps: template.sequence.length,
            created: new Date(template.createdAt).toLocaleDateString()
        };
    });

    const deleteTemplate = useCallback(async (templateId: string) => {
        setDeleting(true);
        const response = await Api.templates.deleteTemplate(templateId);
        if (!("error" in response) && response.success) {
            GlobalState.templates.set((templates) =>
                templates.filter((t) => t.templateId !== templateId)
            );
        }
        setDeleting(false);
    }, []);

    return (
        <div className="flex-1 p-8 flex flex-col gap-y-12 overflow-y-scroll">
            <div className="flex flex-row justify-between">
                <div className="flex items-center">
                    <h1 className="text-2xl font-semibold">Overview of your Templates!</h1>
                    <Tooltip
                        content={
                            <div className="text-sm">
                                <p>Templates are a way of creating a reusable campaign sequence</p>
                                <p>When you are creating a campaign you can use a template</p>
                            </div>
                        }
                    >
                        <Button isIconOnly variant="ghost" radius="full" className="border-none">
                            <IoIosInformationCircle
                                className="text-purple font-medium"
                                size={"1.5em"}
                            />
                        </Button>
                    </Tooltip>
                </div>
                <div className="flex flex-row items-center">
                    <Button
                        color="default"
                        className="bg-black text-white mr-4"
                        onClick={retrieveTemplates}
                        isIconOnly
                    >
                        <IoIosRefresh />
                    </Button>
                    <Button
                        className="bg-purple text-white"
                        onClick={() => navigate("/templates/create")}
                    >
                        Add New Template
                    </Button>
                </div>
            </div>

            <Table aria-label="Table containing all the user's campaigns.">
                <TableHeader columns={COLUMNS}>
                    {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                </TableHeader>
                <TableBody items={rows} emptyContent={"Create a template to view it here."}>
                    {(item) => (
                        <TableRow key={item.templateId}>
                            {(columnKey) => (
                                <TableCell>
                                    {columnKey === "name" ? (
                                        <button
                                            className="underline"
                                            onClick={() =>
                                                navigate(`/templates/${item.templateId}`)
                                            }
                                        >
                                            {item.name}
                                        </button>
                                    ) : columnKey === "actions" ? (
                                        <div className="flex flex-row gap-x-4">
                                            <Button
                                                isLoading={deleting}
                                                size="sm"
                                                className="bg-red-500 text-white"
                                                onClick={() => deleteTemplate(item.templateId)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    ) : (
                                        getKeyValue(item, columnKey)
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
