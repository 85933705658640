import { useParams, useNavigate } from "react-router";
import {
    CampaignType,
    UserLeadsType,
    WorkflowExecutionType,
    WorkingHoursType
} from "../../../types/model";
import GlobalState from "../../../global/GlobalState";
import { ImmutableObject, useHookstate } from "@hookstate/core";
import {
    Button,
    Card,
    CardBody,
    Chip,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Input,
    Spinner,
    Switch,
    Tab,
    Tabs
} from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import Api from "../../../global/Api";
import CampaignStats from "./CampaignStats";
import CampaignLeads from "./CampaignLeads";
import CampaignSequence from "./CampaignSequence";
import { IoIosRefresh } from "react-icons/io";
import toast from "react-hot-toast";
import { CiEdit } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import AddLeadsToCampaign from "../overview/AddLeadsToCampaign";
import { RetrieveCampaignExecutionsResponseType } from "../../../types/api/campaign";
import WorkingHours from "../../settings/WorkingHours";
import WorkingHoursComponent from "../../../components/WorkingHoursComponent";

export default function CampaignDetails() {
    const params = useParams();
    const campaignId = params.id as string;
    const navigate = useNavigate();

    const userState = useHookstate(GlobalState.user);
    const userWorkingHours = useHookstate(GlobalState.workingHours);

    const campaign = useHookstate(GlobalState.campaigns)
        .find((c) => c.workflowId.get() === campaignId)
        ?.get() as ImmutableObject<CampaignType> | undefined;

    useEffect(() => {
        if (!campaign) {
            navigate("/campaigns");
        }
    }, [campaign, navigate]);

    const campaignWorkingHours = campaign?.overrideWorkingHours;

    const retrieveCampaign = useCallback(async () => {
        const response = await Api.campaign.retrieveCampaigns();
        if (response) {
            const campaigns = response.campaigns;
            campaigns.sort(
                (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
            GlobalState.campaigns.set(campaigns);
        }
    }, []);

    const retrieveExecutions = useCallback((campaignId: string) => {
        const executions: WorkflowExecutionType[] = [];
        const leads: UserLeadsType[] = [];

        const fetchIncrementally = async () => {
            let cursorId = undefined;
            console.log("Fetching campaign executions incrementally...");

            do {
                const response: RetrieveCampaignExecutionsResponseType | null =
                    await Api.campaign.retrieveCampaignExecutions(campaignId, cursorId);

                if (response && response.executions.length > 0) {
                    // Update the global state incrementally
                    executions.push(...response.executions);
                    leads.push(...response.leads);

                    // Update the global state
                    GlobalState.campaignExecutions[campaignId].set(executions);
                    GlobalState.campaignExecutionLeads[campaignId].set(leads);

                    cursorId = response.cursorId;
                } else {
                    break;
                }
            } while (true);
        };

        // Start the fetching process without awaiting it
        fetchIncrementally();
    }, []);

    const refresh = useCallback(async () => {
        // Refresh the campaign
        retrieveCampaign();

        // Refresh the campaign leads
        retrieveExecutions(campaignId);
    }, [campaignId, retrieveCampaign, retrieveExecutions]);

    const [nameEditingMode, setNameEditingMode] = useState(false);
    const [newName, setNewName] = useState(campaign && campaign.name);

    const [addLeadsToCampaign, setAddLeadsToCampaign] = useState(false);

    const [selectedDelay, setSelectedDelay] = useState(
        campaign?.waitUntilConnectionRequestAcceptedDelay || 7
    );

    const handleDelayChange = useCallback(
        async (delay: number) => {
            setSelectedDelay(delay);

            if (!campaign) {
                return;
            }

            const response = await Api.campaign.updateCampaignExclusions(
                campaignId,
                campaign.excludeLeadsFoundInOtherWorkflows,
                campaign.excludeLeadsFirstDegreeConnection,
                false,
                campaign.sendConnectionNoteAsMessageIfAlreadyConnected,
                campaign.waitUntilConnectionRequestAccepted,
                delay
            );

            if ("error" in response) {
                toast.error("Error updating the delay");
                setSelectedDelay(campaign.waitUntilConnectionRequestAcceptedDelay);
                return;
            }

            GlobalState.campaigns.set((prevState) =>
                prevState.map((c) => {
                    if (c.workflowId === campaignId) {
                        return response.campaign;
                    }
                    return c;
                })
            );
            toast.success("Delay updated successfully");
        },
        [campaignId, campaign]
    );

    const [createOverrideWorkingHoursLoading, setCreateOverrideWorkingHoursLoading] =
        useState(false);

    const deleteOverrideWorkingHours = useCallback(async () => {
        const response = await Api.campaign.deleteOverrideWorkingHours(campaignId);

        if ("error" in response) {
            toast.error("Error deleting override working hours");
            return;
        }

        // Update the campaign in the global state.
        GlobalState.campaigns.set((prevState) =>
            prevState.map((c) => {
                if (c.workflowId === campaignId) {
                    return response.workflow;
                }
                return c;
            })
        );
        toast.success("Override working hours deleted successfully");
    }, [campaignId]);

    const createOverrideWorkingHours = useCallback(async () => {
        setCreateOverrideWorkingHoursLoading(true);
        // Fake delay
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const response = await Api.campaign.overrideWorkingHours(
            campaignId,
            userWorkingHours.get() as WorkingHoursType[]
        );

        if ("error" in response) {
            toast.error("Error overriding working hours");
            setCreateOverrideWorkingHoursLoading(false);
            return;
        }

        // Update the campaign in the global state.
        GlobalState.campaigns.set((prevState) =>
            prevState.map((c) => {
                if (c.workflowId === campaignId) {
                    return response.workflow;
                }
                return c;
            })
        );

        toast.success("Working hours overridden successfully");
        setCreateOverrideWorkingHoursLoading(false);
    }, [campaignId, userWorkingHours]);

    const updateOverrideWorkingHours = useCallback(
        async (workingHours: WorkingHoursType[]) => {
            const response = await Api.campaign.overrideWorkingHours(campaignId, workingHours);

            if ("error" in response) {
                toast.error("Error overriding working hours");
                return;
            }

            // Update the campaign in the global state.
            GlobalState.campaigns.set((prevState) =>
                prevState.map((c) => {
                    if (c.workflowId === campaignId) {
                        return response.workflow;
                    }
                    return c;
                })
            );

            toast.success("Working hours updated successfully");
        },
        [campaignId]
    );

    return (
        <div className="flex-1 p-8 flex flex-col gap-y-6 overflow-y-scroll">
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    {nameEditingMode ? (
                        <div className="flex flex-row gap-x-2">
                            <Input
                                className="w-64"
                                classNames={{
                                    input: "text-xl font-semibold bg-transparent",
                                    base: "bg-transparent shadow-none",
                                    inputWrapper:
                                        "bg-transparent border-b-4 border-blue-500 rounded-none"
                                }}
                                defaultValue={campaign && campaign.name}
                                onChange={(e) => setNewName(e.target.value)}
                            />
                            <div className="flex flex-row gap-x-2">
                                <Button
                                    isIconOnly
                                    className="bg-black text-white"
                                    onClick={() => setNameEditingMode(false)}
                                >
                                    <IoMdClose size="1em" />
                                </Button>
                                <Button
                                    isIconOnly
                                    className="bg-purple text-white"
                                    onClick={async () => {
                                        const response = await Api.campaign.updateCampaign(
                                            campaignId,
                                            newName,
                                            undefined
                                        );
                                        if ("error" in response) {
                                            toast.error("Error updating the sequence.");
                                        } else {
                                            GlobalState.campaigns.set((prevState) =>
                                                prevState.map((c) => {
                                                    if (c.workflowId === campaignId) {
                                                        return response.campaign;
                                                    }
                                                    return c;
                                                })
                                            );
                                            toast.success("Campaign name updated successfully.");
                                        }
                                        setNameEditingMode(false);
                                    }}
                                >
                                    <FaCheck size="1em" />
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h1 className="text-2xl font-semibold">{campaign && campaign.name}</h1>
                            <Button
                                isIconOnly
                                className="bg-transparent"
                                onClick={() => setNameEditingMode(true)}
                            >
                                <CiEdit />
                            </Button>
                        </>
                    )}
                </div>

                <div className="flex flex-row gap-x-2 items-center">
                    <Button
                        color="default"
                        className="bg-black text-white self-end"
                        onClick={() => refresh()}
                        isIconOnly
                    >
                        <IoIosRefresh />
                    </Button>

                    <Button
                        className="bg-purple text-white w-fit"
                        startContent={<MdAdd />}
                        onClick={() => setAddLeadsToCampaign(true)}
                    >
                        Add leads
                    </Button>
                </div>
            </div>

            {campaign && (
                <AddLeadsToCampaign
                    isOpen={addLeadsToCampaign}
                    onClose={() => setAddLeadsToCampaign(false)}
                    shouldRefresh={refresh}
                    campaign={campaign}
                    firstTimeAddingLeads={userState.get()?.totalUniqueLeads === 0 ?? false}
                />
            )}

            {campaign && (
                <Tabs
                    aria-label="Options"
                    variant="underlined"
                    classNames={{ cursor: "bg-purple", tab: "text-lg" }}
                >
                    <Tab key="stats" title="Statistics">
                        <CampaignStats campaign={campaign} />
                    </Tab>

                    <Tab key="leads" title="Leads">
                        <CampaignLeads campaignId={campaignId} />
                    </Tab>

                    <Tab key="sequence" title="Sequence">
                        <CampaignSequence campaignId={campaignId} />
                    </Tab>

                    <Tab key="settings" title="Settings">
                        <Card className="w-fit p-4">
                            <CardBody className="gap-y-6">
                                <Switch
                                    className="min-w-[320px]"
                                    defaultSelected={campaign.excludeLeadsFoundInOtherWorkflows}
                                    onValueChange={async (isSelected: boolean) => {
                                        const response =
                                            await Api.campaign.updateCampaignExclusions(
                                                campaignId,
                                                isSelected,
                                                campaign.excludeLeadsFirstDegreeConnection,
                                                false,
                                                campaign.sendConnectionNoteAsMessageIfAlreadyConnected,
                                                campaign.waitUntilConnectionRequestAccepted
                                            );
                                        if ("error" in response) {
                                            toast.error("Error updating the exclusions");
                                            return;
                                        }

                                        // Update the campaign in the global state.
                                        GlobalState.campaigns.set((prevState) =>
                                            prevState.map((c) => {
                                                if (c.workflowId === campaignId) {
                                                    return response.campaign;
                                                }
                                                return c;
                                            })
                                        );
                                    }}
                                >
                                    Exclude leads found in other workflows
                                </Switch>

                                <Switch
                                    className="min-w-[320px]"
                                    defaultSelected={campaign.excludeLeadsFirstDegreeConnection}
                                    onValueChange={async (isSelected: boolean) => {
                                        const response =
                                            await Api.campaign.updateCampaignExclusions(
                                                campaignId,
                                                campaign.excludeLeadsFoundInOtherWorkflows,
                                                isSelected,
                                                false,
                                                campaign.sendConnectionNoteAsMessageIfAlreadyConnected,
                                                campaign.waitUntilConnectionRequestAccepted
                                            );
                                        if ("error" in response) {
                                            toast.error("Error updating the exclusions.");
                                            return;
                                        }

                                        // Update the campaign in the global state.
                                        GlobalState.campaigns.set((prevState) =>
                                            prevState.map((c) => {
                                                if (c.workflowId === campaignId) {
                                                    return response.campaign;
                                                }
                                                return c;
                                            })
                                        );
                                    }}
                                >
                                    Exclude first degree connection
                                </Switch>

                                <Switch
                                    className="min-w-[320px]"
                                    defaultSelected={
                                        campaign.sendConnectionNoteAsMessageIfAlreadyConnected
                                    }
                                    onValueChange={async (isSelected: boolean) => {
                                        const response =
                                            await Api.campaign.updateCampaignExclusions(
                                                campaignId,
                                                campaign.excludeLeadsFoundInOtherWorkflows,
                                                campaign.excludeLeadsFirstDegreeConnection,
                                                false,
                                                isSelected,
                                                campaign.waitUntilConnectionRequestAccepted
                                            );
                                        if ("error" in response) {
                                            toast.error("Error updating the exclusions.");
                                            return;
                                        }

                                        // Update the campaign in the global state.
                                        GlobalState.campaigns.set((prevState) =>
                                            prevState.map((c) => {
                                                if (c.workflowId === campaignId) {
                                                    return response.campaign;
                                                }
                                                return c;
                                            })
                                        );
                                    }}
                                >
                                    Send connection note as message (if 1st degree connection)
                                </Switch>

                                <div className="flex flex-row gap-x-2 items-center">
                                    <Switch
                                        className="min-w-[320px]"
                                        defaultSelected={
                                            campaign.waitUntilConnectionRequestAccepted
                                        }
                                        onValueChange={async (isSelected: boolean) => {
                                            const response =
                                                await Api.campaign.updateCampaignExclusions(
                                                    campaignId,
                                                    campaign.excludeLeadsFoundInOtherWorkflows,
                                                    campaign.excludeLeadsFirstDegreeConnection,
                                                    false,
                                                    campaign.sendConnectionNoteAsMessageIfAlreadyConnected,
                                                    isSelected
                                                );
                                            if ("error" in response) {
                                                toast.error("Error updating the exclusions");
                                                return;
                                            }

                                            // Update the campaign in the global state.
                                            GlobalState.campaigns.set((prevState) =>
                                                prevState.map((c) => {
                                                    if (c.workflowId === campaignId) {
                                                        return response.campaign;
                                                    }
                                                    return c;
                                                })
                                            );
                                        }}
                                    >
                                        Wait until connection request is accepted
                                    </Switch>

                                    {campaign.waitUntilConnectionRequestAccepted && (
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button
                                                    variant="bordered"
                                                    className="text-black border-purple"
                                                >
                                                    {selectedDelay} days
                                                </Button>
                                            </DropdownTrigger>
                                            <DropdownMenu>
                                                {[...Array(15)].map((_, index) => (
                                                    <DropdownItem
                                                        key={index + 7}
                                                        onClick={() => handleDelayChange(index + 7)}
                                                    >
                                                        {index + 7} days
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}

                                    <Chip color="success" className="text-white">
                                        Beta
                                    </Chip>
                                </div>
                            </CardBody>
                        </Card>
                    </Tab>

                    <Tab key="working-hours" title="Schedule" className="h-full w-full">
                        {campaignWorkingHours ? (
                            <div className="flex flex-col gap-y-4">
                                <WorkingHoursComponent
                                    workingHours={campaignWorkingHours as WorkingHoursType[]}
                                    onSave={(updatedWorkingHours) =>
                                        updateOverrideWorkingHours(updatedWorkingHours)
                                    }
                                    onDelete={deleteOverrideWorkingHours}
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col h-full w-full items-center justify-center gap-y-4">
                                {createOverrideWorkingHoursLoading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        <Button
                                            className="bg-purple text-white w-fit"
                                            onClick={createOverrideWorkingHours}
                                        >
                                            Override working hours
                                        </Button>
                                        <p className="text-sm text-gray-500">
                                            Global Working Hours are currently being used.
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                    </Tab>
                </Tabs>
            )}

            {!campaign && <div>Redirecting...</div>}
        </div>
    );
}
