import { useHookstate } from "@hookstate/core";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Chip,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Pagination,
    Progress,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    getKeyValue
} from "@nextui-org/react";
import GlobalState from "../../../global/GlobalState";
import { FaPlay, FaPause, FaPlus } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import Api from "../../../global/Api";
import { useNavigate } from "react-router";
import { IoIosRefresh } from "react-icons/io";
import moment from "moment";
import { formatTime } from "../../../helpers/utils";
import { IoMdClose } from "react-icons/io";
import { ErrorResponseType } from "../../../types/model";
import { ActivateCampaignResponseType } from "../../../types/api/campaign";
import AddLeadsToCampaign from "./AddLeadsToCampaign";
import toast from "react-hot-toast";
import { CiMenuKebab } from "react-icons/ci";
import { FaRegClone } from "react-icons/fa";
import { DeleteDocumentIcon } from "../../../components/DeleteDocumentIcon";
import { ChevronDownIcon } from "../../../components/ChevronDownIcon";
import { GoLinkExternal } from "react-icons/go";

const CAMPAIGN_COLUMNS = [
    { key: "name", label: "Name" },
    { key: "progress", label: "Progress" },
    { key: "leads", label: "Total Leads" },
    { key: "leadsAddedToSequence", label: "Accepted Leads" },
    { key: "reply", label: "Reply Count" },
    { key: "accepted", label: "Accepted Count" },
    { key: "active", label: "Status" },
    { key: "created", label: "Created" },
    { key: "actions", label: "Actions" }
];

type CampaignRowType = {
    key: string;
    name: string;
    progress: number;
    leads: number;
    leadsAddedToSequence: number;
    reply: number;
    accepted: number;
    created: string;
    active: boolean;
    externalCampaignId: string | null;
};

export default function Campaigns() {
    const userState = useHookstate(GlobalState.user);
    const campaignsState = useHookstate(GlobalState.campaigns);
    const campaigns = campaignsState.get();
    const pendingInvitationsCount = useHookstate(GlobalState.pendingInvitationsCount).get();

    const totalCampaigns = campaigns.length;
    const activeCampaigns = campaigns.filter((c) => c.active).length;

    const navigate = useNavigate();

    const [campaignId, setCampaignId] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [showSidebar, setShowSidebar] = useState(false);

    const retrieveCampaigns = useCallback(async () => {
        const response = await Api.campaign.retrieveCampaigns();
        if (response) {
            const campaigns = response.campaigns;
            campaigns.sort(
                (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
            campaignsState.set(campaigns);
            return campaigns;
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchAllCampaigns = async () => {
            if (campaigns.length === 0) {
                await retrieveCampaigns();
            }
        };

        fetchAllCampaigns();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const workingHours = useHookstate(GlobalState.workingHours).get();
    let outsideWorkingHours = false;
    for (const wh of workingHours) {
        const now = moment().tz(wh.timezone);
        if (wh.dayOfWeek === now.day()) {
            const start = moment.tz(formatTime(wh.start), "hh:mm", wh.timezone);
            const end = moment.tz(formatTime(wh.end), "hh:mm", wh.timezone);
            const now = moment().tz(wh.timezone);
            now.date(start.date()); // Set now to have the same date as start
            if (!now.isBetween(start, end) || !wh.active) {
                outsideWorkingHours = true;
                break;
            }
        }
    }

    const showWarning = useHookstate(GlobalState.hideOutsideWorkingHoursWarning).get();
    outsideWorkingHours = outsideWorkingHours && !showWarning;

    const rows: CampaignRowType[] = campaigns.map((campaign) => ({
        key: campaign.workflowId,
        name: campaign.name,
        progress:
            campaign.totalLeadsAdded === 0
                ? 0
                : Math.floor((campaign.totalLeadsFinished / campaign.totalLeadsAdded) * 100),
        leads: campaign.totalLeadsAdded,
        leadsAddedToSequence: campaign.totalLeadsAccepted,
        reply: campaign.replyCount,
        accepted: campaign.acceptCount,
        created: new Date(campaign.createdAt).toLocaleDateString(),
        active: campaign.active,
        externalCampaignId: campaign.externalCampaignId
    }));

    const cards = [
        {
            title: "Total Campaigns",
            value: totalCampaigns
        },
        {
            title: "Active Campaigns",
            value: activeCampaigns
        },
        {
            title: "Pending Invitations",
            value: pendingInvitationsCount
        }
    ];

    const updateCampaignStatus = useCallback(
        async (campaignId: string, active: boolean) => {
            console.log("Updating campaign status:", campaignId, active);
            let result: ActivateCampaignResponseType | ErrorResponseType;
            if (active) {
                result = await Api.campaign.markCampaignAsActive(campaignId);
            } else {
                result = await Api.campaign.markCampaignAsInactive(campaignId);
            }

            if ("error" in result) {
                toast.error(result.error.details);
            } else {
                await retrieveCampaigns();
            }
        },
        [retrieveCampaigns]
    );

    const pageSize = 10;
    const tableRows = rows.length;
    const filteredRows = rows.slice((page - 1) * pageSize, Math.min(page * pageSize, rows.length));

    const descriptionsMap = {
        quick: "Define a name and sequence of your choice.",
        guided: "Configure your campaign with all options available and add leads to it."
    };

    const labelsMap = {
        quick: "Quick Campaign Create",
        guided: "Guided Campaign Create"
    };

    // Convert the Set to an Array and get the first value.
    const [selectedOption, setSelectedOption] = useState<"quick" | "guided">("quick");

    // Add the ability to associate external IDs to campaigns.
    // Show a modal that takes an input for the external ID and a dropdown for the campaign.
    // On submit, associate the external ID to the campaign.
    const [showAssociateExternalIdModal, setShowAssociateExternalIdModal] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState<CampaignRowType | null>(null);
    const [externalId, setExternalId] = useState<string>(
        selectedCampaign?.externalCampaignId ?? ""
    );
    const handleAssociateExternalId = async () => {
        // Associate the external ID to the campaign.
        if (selectedCampaign) {
            const result = await Api.campaign.associateExternalId(selectedCampaign.key, externalId);
            if ("error" in result) {
                toast.error(result.error.details);
            } else {
                // Update the state to reflect the change.
                GlobalState.campaigns.set((state) =>
                    state.map((c) => (c.workflowId === selectedCampaign.key ? result.campaign : c))
                );
                setShowAssociateExternalIdModal(false);
                setSelectedCampaign(null);
                setExternalId("");
                toast.success("External ID associated successfully.");
            }
        }
    };

    return (
        <div className="flex-1 p-8 flex flex-col gap-y-12 overflow-y-scroll">
            {outsideWorkingHours && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative flex flex-row justify-between items-center"
                    role="alert"
                >
                    <span className="block sm:inline">
                        You are currently outside of your working hours.
                    </span>
                    <Button
                        className="bg-red-500 text-white"
                        isIconOnly
                        onClick={() => GlobalState.hideOutsideWorkingHoursWarning.set(true)}
                    >
                        <IoMdClose />
                    </Button>
                </div>
            )}

            <div className="flex flex-row justify-between items-start">
                {/* <h1 className="text-2xl font-semibold">Overview of your Campaigns!</h1> */}

                <div className="flex flex-row gap-x-8 w-full">
                    {cards.map((card, index) => (
                        <Card key={index} className="min-w-56 min-h-48 p-4">
                            <CardHeader className="text-purple text-base font-bold">
                                {card.title}
                            </CardHeader>
                            <CardBody className="justify-center">
                                <p className="text-5xl">{card.value}</p>
                            </CardBody>
                        </Card>
                    ))}
                </div>

                <div className="flex flex-row items-center">
                    <Button
                        color="default"
                        className="bg-black text-white mr-4"
                        onClick={() => retrieveCampaigns()}
                        isIconOnly
                    >
                        <IoIosRefresh />
                    </Button>

                    <ButtonGroup variant="flat">
                        <Button
                            className="bg-purple text-white min-w-48"
                            onClick={() => {
                                if (selectedOption === "quick") {
                                    navigate("/campaigns/create");
                                } else {
                                    navigate("/onboarding");
                                }
                            }}
                        >
                            {labelsMap[selectedOption]}
                        </Button>
                        <Dropdown placement="bottom-end">
                            <DropdownTrigger>
                                <Button className="bg-purple text-white" isIconOnly>
                                    <ChevronDownIcon />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                selectedKeys={new Set([selectedOption])}
                                selectionMode="single"
                                onSelectionChange={(e) => {
                                    setSelectedOption(
                                        (e as Set<"quick" | "guided">).values().next().value
                                    );
                                }}
                                className="max-w-[300px]"
                            >
                                <DropdownItem key="quick" description={descriptionsMap["quick"]}>
                                    {labelsMap["quick"]}
                                </DropdownItem>
                                <DropdownItem key="guided" description={descriptionsMap["guided"]}>
                                    {labelsMap["guided"]}
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </ButtonGroup>
                </div>
            </div>

            {campaignId && (
                <AddLeadsToCampaign
                    isOpen={showSidebar}
                    onClose={() => setShowSidebar(false)}
                    shouldRefresh={retrieveCampaigns}
                    campaign={campaigns.find((c) => c.workflowId === campaignId)!}
                    firstTimeAddingLeads={userState.get()?.totalUniqueLeads === 0 ?? false}
                />
            )}

            <Table
                aria-label="Table containing all the user's campaigns."
                bottomContent={
                    <div className="flex w-full justify-end">
                        <Pagination
                            classNames={{
                                cursor: "bg-purple"
                            }}
                            showControls={true}
                            variant="flat"
                            radius="full"
                            total={Math.ceil(tableRows / pageSize)}
                            page={page}
                            initialPage={page}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                }
            >
                <TableHeader columns={CAMPAIGN_COLUMNS}>
                    {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                </TableHeader>
                <TableBody items={filteredRows} emptyContent={"Add a campaign to view it here."}>
                    {(item) => (
                        <TableRow key={item.key}>
                            {(columnKey) => (
                                <TableCell>
                                    {columnKey === "name" && (
                                        <button
                                            className="underline hover:text-blue-800 hover:opacity-80"
                                            onClick={() => navigate(`/campaigns/${item.key}`)}
                                        >
                                            {item.name}
                                        </button>
                                    )}
                                    {columnKey === "active" && (
                                        <Chip
                                            className="capitalize"
                                            color={item.active ? "success" : "warning"}
                                            size="sm"
                                            variant="flat"
                                        >
                                            {item.active ? "Active" : "Paused"}
                                        </Chip>
                                    )}
                                    {columnKey === "actions" && (
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button className="bg-transparent" isIconOnly>
                                                    <CiMenuKebab />
                                                </Button>
                                            </DropdownTrigger>
                                            <DropdownMenu
                                                variant="faded"
                                                aria-label="Dropdown menu with icons"
                                            >
                                                <DropdownItem
                                                    key="add_leads"
                                                    startContent={<FaPlus />}
                                                    onClick={() => {
                                                        setCampaignId(item.key);
                                                        setShowSidebar(true);
                                                    }}
                                                >
                                                    Add Leads
                                                </DropdownItem>

                                                <DropdownItem
                                                    key="active"
                                                    startContent={
                                                        item.active ? <FaPause /> : <FaPlay />
                                                    }
                                                    onClick={async () =>
                                                        updateCampaignStatus(
                                                            item.key,
                                                            item.active ? false : true
                                                        )
                                                    }
                                                >
                                                    {item.active ? "Pause" : "Play"}
                                                </DropdownItem>

                                                <DropdownItem
                                                    key="clone"
                                                    startContent={<FaRegClone />}
                                                    onClick={() =>
                                                        navigate(
                                                            `/campaigns/create?clone=${item.key}`
                                                        )
                                                    }
                                                >
                                                    Clone
                                                </DropdownItem>

                                                <DropdownItem
                                                    key="associate"
                                                    startContent={<GoLinkExternal />}
                                                    onClick={() => {
                                                        setExternalId(
                                                            item.externalCampaignId ?? ""
                                                        );
                                                        setSelectedCampaign(item);
                                                        setShowAssociateExternalIdModal(true);
                                                    }}
                                                >
                                                    Zapier
                                                </DropdownItem>

                                                <DropdownItem
                                                    key="delete"
                                                    startContent={<DeleteDocumentIcon />}
                                                    className="text-danger"
                                                    onClick={async () => {
                                                        const result =
                                                            await Api.campaign.deleteCampaign(
                                                                item.key
                                                            );
                                                        result
                                                            ? toast.success(
                                                                  "Campaign queued for deletion"
                                                              )
                                                            : toast.error(
                                                                  "Failed to delete campaign"
                                                              );
                                                        if (result) {
                                                            GlobalState.campaigns.set((state) =>
                                                                state.filter(
                                                                    (c) => c.workflowId !== item.key
                                                                )
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}
                                    {columnKey === "progress" && (
                                        <Progress
                                            aria-label="Loading..."
                                            value={item.progress}
                                            showValueLabel={true}
                                            size="sm"
                                        />
                                    )}
                                    {columnKey === "reply" && (
                                        <p>
                                            {item.reply} (
                                            {item.leads > 0
                                                ? Math.ceil((item.reply / item.leads) * 100)
                                                : 0}
                                            %)
                                        </p>
                                    )}
                                    {columnKey === "accepted" && (
                                        <p>
                                            {item.accepted} (
                                            {item.leads > 0
                                                ? Math.ceil((item.accepted / item.leads) * 100)
                                                : 0}
                                            %)
                                        </p>
                                    )}
                                    {![
                                        "reply",
                                        "accepted",
                                        "actions",
                                        "active",
                                        "name",
                                        "progress"
                                    ].includes(columnKey as string) && getKeyValue(item, columnKey)}
                                </TableCell>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {/* Associate External ID Modal */}
            <Modal
                isOpen={showAssociateExternalIdModal}
                onClose={() => {
                    setExternalId("");
                    setSelectedCampaign(null);
                    setShowAssociateExternalIdModal(false);
                }}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">Zapier Settings</ModalHeader>
                    <ModalBody className="flex gap-y-4">
                        <p>
                            Associate an external ID to this campaign. You can then use this
                            external ID to refer to this campaign in Zapier.{" "}
                        </p>
                        <Input
                            label="External ID"
                            value={externalId}
                            onChange={(e) => setExternalId(e.target.value)}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            className="bg-purple text-white"
                            onClick={handleAssociateExternalId}
                        >
                            Associate
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
