import { Tab, Tabs } from "@nextui-org/react";
import Limits from "./Limits";
import WorkingHours from "./WorkingHours";
import Billing from "./Billing";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Settings() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [selected, setSelected] = useState("limits");

    useEffect(
        () => {
            const processStripePayment = async () => {
                if (
                    window.location.pathname === "/payment/stripe/callback" &&
                    urlSearchParams.get("success")
                ) {
                    // const success = urlSearchParams.get("success");
                    setSelected("billing");

                    // Reset the pathname
                    window.history.replaceState(null, "", "/settings");
                }
            };

            processStripePayment();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div className="flex-1 bg-white flex flex-col p-8 overflow-y-scroll">
            <Tabs
                aria-label="Options"
                variant="underlined"
                selectedKey={selected}
                onSelectionChange={(key) => setSelected(key as string)}
                classNames={{ cursor: "bg-purple", base: "mb-4", tab: "text-lg" }}
            >
                <Tab key="limits" title="Limits">
                    <Limits />
                </Tab>
                <Tab key="working-hours" title="Working Hours">
                    <WorkingHours />
                </Tab>
                <Tab key="billing" title="Billing">
                    <Billing />
                </Tab>
            </Tabs>
        </div>
    );
}
