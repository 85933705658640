import {
    Dropdown,
    DropdownTrigger,
    Button,
    DropdownMenu,
    DropdownItem,
    Tooltip
} from "@nextui-org/react";
import { Key, useState } from "react";
import { AiOutlineLike } from "react-icons/ai";
import { GiFinishLine } from "react-icons/gi";
import { IoPersonAddOutline, IoEyeSharp } from "react-icons/io5";
import { LuMessagesSquare } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import { SlUserFollowing } from "react-icons/sl";
import { VscDebugStart } from "react-icons/vsc";
import { SlUserUnfollow } from "react-icons/sl";
import { IoIosInformationCircle } from "react-icons/io";

const items = [
    {
        key: "start",
        label: "Start",
        startContent: <VscDebugStart />,
        endContent: (
            <Tooltip content="Must be the first action">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "connection",
        label: "Connection Request",
        startContent: <IoPersonAddOutline />,
        endContent: (
            <Tooltip content="LinkedIn Connection request to be sent to the lead">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "view",
        label: "Profile View",
        startContent: <IoEyeSharp />,
        endContent: (
            <Tooltip content="View the LinkedIn profile of the lead">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "message",
        label: "Send Message",
        startContent: <LuMessagesSquare />,
        endContent: (
            <Tooltip content="Send a LinkedIn DM">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "like",
        label: "Like Post",
        startContent: <AiOutlineLike />,
        endContent: (
            <Tooltip content="Like a post of the lead">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "follow",
        label: "Follow Profile",
        startContent: <SlUserFollowing />,
        endContent: (
            <Tooltip content="Follow the LinkedIn profile">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "unfollow",
        label: "Unfollow Profile",
        startContent: <SlUserUnfollow />,
        endContent: (
            <Tooltip content="Unfollow the LinkedIn profile">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "withdraw",
        label: "Withdraw",
        startContent: <RxCross1 />,
        endContent: (
            <Tooltip content="Withdraw LinkedIn connection request if still pending">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    },
    {
        key: "end",
        label: "End",
        startContent: <GiFinishLine />,
        endContent: (
            <Tooltip content="Must be the last action">
                <Button isIconOnly variant="ghost" radius="full" className="border-none">
                    <IoIosInformationCircle className="text-purple" />
                </Button>
            </Tooltip>
        )
    }
];

export type FlowDropdownProps = {
    onAction: (key: Key) => void;
    disabledKeys: string[];
};

export default function FlowDropDown(props: FlowDropdownProps) {
    const { onAction, disabledKeys } = props;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown isOpen={isOpen}>
            <DropdownTrigger>
                <Button
                    variant="bordered"
                    onMouseEnter={() => {
                        setIsOpen(true);
                    }}
                    onMouseLeave={() => {
                        setIsOpen(false);
                    }}
                >
                    + Add Action
                </Button>
            </DropdownTrigger>
            <DropdownMenu
                disabledKeys={disabledKeys}
                aria-label="Action to add to the campaign"
                items={items}
                onAction={onAction}
                onMouseEnter={() => {
                    setIsOpen(true);
                }}
                onMouseLeave={() => {
                    setIsOpen(false);
                }}
            >
                {(item) => (
                    <DropdownItem
                        startContent={item.startContent}
                        endContent={item.endContent}
                        key={item.key}
                    >
                        {item.label}
                    </DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
}
