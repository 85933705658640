import axios from "axios";
import {
    ActivateCampaignResponseType,
    AddLeadsToCampaignRequestType,
    AddLeadsToCampaignResponseType,
    ArchiveWorkflowExecutionResponseType,
    CreateNewCampaignRequestType,
    CreateNewCampaignResponseType,
    DeactivateCampaignResponseType,
    DeleteOverrideWorkingHoursResponseType,
    DeleteWorkflowExecutionResponseType,
    LeadAddedToCampaignType,
    OverrideWorkingHoursRequestType,
    OverrideWorkingHoursResponseType,
    RetrieveAllCampaignsForUserResponseType,
    RetrieveCampaignExecutionByIdResponseType,
    RetrieveCampaignExecutionsResponseType,
    UpdateCampaignExclusionsResponseType,
    UpdateCampaignResponseType
} from "../types/api/campaign";
import {
    CreateApiKeyResponseType,
    CreateNewUserRequestType,
    CreateNewUserResponseType,
    CurrentPendingInvitationsCountResponseType,
    RetrieveNewUserResponseType,
    RetrieveUserHourlyActivityResponseType,
    RetrieveUserLeadsResponseType,
    UpdateUserLimitsResponseType,
    UpdateUserWorkingHoursRequestType,
    UpdateUserWorkingHoursResponseType
} from "../types/api/user";
import { ErrorResponseType, SequenceType, UserLimitsType, WorkingHoursType } from "../types/model";
import {
    ConnectWithLinkedInRequestType,
    ConnectWithLinkedInResponseType,
    ConnectWithSlackRequestType,
    ConnectWithSlackResponseType,
    DisconnectLinkedInResponseType,
    ProvideLinkedInChallengeTokenRequestType,
    ProvideLinkedInChallengeTokenResponseType,
    RetrieveLinkedInIntegrationResponseType,
    RetrieveUserIntegrationResponseType
} from "../types/api/integrations";
import {
    CreateStripeCheckoutSessionRequestType,
    CreateStripeCheckoutSessionResponseType
} from "../types/api/payments";
import {
    CreateNewTemplateRequestType,
    CreateNewTemplateResponseType,
    DeleteTemplateResponseType,
    RetrieveTemplatesResponseType,
    UpdateTemplateRequestType,
    UpdateTemplateResponseType
} from "../types/api/templates";
import { RewriteWithAIResponse } from "../types/api/ai";

const baseURL =
    process.env.NODE_ENV === "production"
        ? "https://api.themagicdrip.com"
        : "http://localhost:3000";

const AxiosClient = axios.create({ baseURL, timeout: 60000 });

AxiosClient.interceptors.response.use(
    (response) => {
        // Do something with the response data
        return response;
    },
    (error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
            return Promise.reject({
                ...error,
                response: {
                    data: {
                        status: 500,
                        error: {
                            message: "Network error",
                            details: "Please check your internet connection",
                            code: 500
                        }
                    }
                }
            });
        }
        return Promise.reject(error);
    }
);

export default class Api {
    static setAuthToken(token: string) {
        AxiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    static user = class User {
        static async retrieveUser(): Promise<RetrieveNewUserResponseType | ErrorResponseType> {
            try {
                const response = await AxiosClient.get("/user");
                return response.data as RetrieveNewUserResponseType;
            } catch (error: any) {
                return error.response.data as ErrorResponseType;
            }
        }

        static async createNewUser(
            timezone: string
        ): Promise<CreateNewUserResponseType | ErrorResponseType> {
            try {
                const data: CreateNewUserRequestType = { timezone };
                const response = await AxiosClient.post("/user", data);
                return response.data as CreateNewUserResponseType;
            } catch (error: any) {
                console.error("Error creating new user:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async retrieveUserHourlyHistoricalActivity(): Promise<RetrieveUserHourlyActivityResponseType> {
            try {
                const response = await AxiosClient.get("/user/history");
                return response.data as RetrieveUserHourlyActivityResponseType;
            } catch (error) {
                console.error("Error retrieving user hourly historical activity:", error);
                return { hourlyActivities: [] };
            }
        }

        static async retrieveLeads(
            cursorId: string | null
        ): Promise<RetrieveUserLeadsResponseType | null> {
            try {
                const params = cursorId ? { cursorId } : {};
                const response = await AxiosClient.get("/user/leads", { params });
                return response.data;
            } catch (error) {
                console.error("Error retrieving leads:", error);
                return null;
            }
        }

        static async updateLimits(
            limits: UserLimitsType
        ): Promise<UpdateUserLimitsResponseType | ErrorResponseType> {
            try {
                const data: UpdateUserLimitsResponseType = { limits };
                const response = await AxiosClient.patch("/user/limits", data);
                return response.data as UpdateUserLimitsResponseType;
            } catch (error: any) {
                console.error("Error updating user limits:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async updateWorkingHours(
            workingHours: WorkingHoursType[]
        ): Promise<UpdateUserWorkingHoursResponseType | ErrorResponseType> {
            try {
                const data: UpdateUserWorkingHoursRequestType = { workingHours };
                const response = await AxiosClient.patch("/user/working-hours", data);
                return response.data as UpdateUserWorkingHoursResponseType;
            } catch (error: any) {
                console.error("Error updating user working hours:", error);
                return error?.response?.data as ErrorResponseType;
            }
        }

        static async currentPendingInvitationsCount(): Promise<CurrentPendingInvitationsCountResponseType> {
            try {
                const response = await AxiosClient.get("/user/pending-invitations");
                return response.data as CurrentPendingInvitationsCountResponseType;
            } catch (error) {
                console.error("Error retrieving pending invitations count:", error);
                return { count: 0 };
            }
        }

        static async createApiKey(): Promise<CreateApiKeyResponseType | null> {
            try {
                const response = await AxiosClient.post("/user/api-key");
                return response.data as CreateApiKeyResponseType;
            } catch (error) {
                console.error("Error creating API key:", error);
                return null;
            }
        }
    };

    static campaign = class Campaign {
        static async retrieveCampaigns(): Promise<RetrieveAllCampaignsForUserResponseType | null> {
            try {
                const response = await AxiosClient.get("/campaign");
                return response.data;
            } catch (error) {
                console.error("Error retrieving campaigns:", error);
                return null;
            }
        }

        static async markCampaignAsActive(
            campaignId: string
        ): Promise<ActivateCampaignResponseType | ErrorResponseType> {
            try {
                const response = await AxiosClient.post(`/campaign/${campaignId}/active`);
                return response.data as ActivateCampaignResponseType;
            } catch (error: any) {
                console.error("Error marking campaign as active:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async markCampaignAsInactive(
            campaignId: string
        ): Promise<DeactivateCampaignResponseType> {
            try {
                const response = await AxiosClient.post(`/campaign/${campaignId}/inactive`);
                return response.data as DeactivateCampaignResponseType;
            } catch (error) {
                console.error("Error marking campaign as inactive:", error);
                return { success: false } as DeactivateCampaignResponseType;
            }
        }

        static async deleteCampaign(campaignId: string): Promise<boolean> {
            try {
                const response = await AxiosClient.post(`/campaign/${campaignId}/archive`);
                const result: ArchiveWorkflowExecutionResponseType = response.data;
                return result.queued;
            } catch (error) {
                console.error("Error deleting campaign:", error);
                return false;
            }
        }

        static async createNewCampaign(
            name: string,
            sequence: SequenceType[],
            excludeLeadsFoundInOtherWorkflows: boolean
        ): Promise<CreateNewCampaignResponseType | ErrorResponseType> {
            try {
                const data: CreateNewCampaignRequestType = {
                    name,
                    sequence,
                    exclusions: {
                        excludeLeadsFoundInOtherWorkflows
                    }
                };
                const response = await AxiosClient.post("/campaign", data);
                return response.data as CreateNewCampaignResponseType;
            } catch (error: any) {
                console.error("Error creating new campaign:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async uploadLeadsToCampaign(
            campaignId: string,
            leadsWithCustomVariables: LeadAddedToCampaignType[]
        ): Promise<AddLeadsToCampaignResponseType | ErrorResponseType> {
            try {
                const data: AddLeadsToCampaignRequestType = { leadsWithCustomVariables, leads: [] };
                const response = await AxiosClient.post(`/campaign/leads/${campaignId}`, data);
                return response.data as AddLeadsToCampaignResponseType;
            } catch (error: any) {
                console.error("Error uploading leads to campaign:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async associateExternalId(
            campaignId: string,
            externalCampaignId: string
        ): Promise<UpdateCampaignResponseType | ErrorResponseType> {
            try {
                const result = await AxiosClient.patch(`/campaign/${campaignId}`, {
                    externalCampaignId
                });
                return result.data as UpdateCampaignResponseType;
            } catch (error: any) {
                console.error("Error associating external ID:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async updateCampaign(
            campaignId: string,
            name?: string,
            sequence?: SequenceType[]
        ): Promise<UpdateCampaignResponseType | ErrorResponseType> {
            try {
                const result = await AxiosClient.patch(`/campaign/${campaignId}`, {
                    name,
                    sequence
                });
                return result.data as UpdateCampaignResponseType;
            } catch (error: any) {
                console.error("Error updating campaign sequence:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async retrieveCampaignExecutions(
            campaignId: string,
            cursorId?: string | null
        ): Promise<RetrieveCampaignExecutionsResponseType | null> {
            try {
                const params = cursorId ? { cursorId } : {};
                const response = await AxiosClient.get(`/campaign/${campaignId}/executions`, {
                    params
                });
                return response.data as RetrieveCampaignExecutionsResponseType;
            } catch (error) {
                console.error("Error retrieving campaign executions", error);
                return null;
            }
        }

        static async deleteCampaignExecution(
            executionId: string
        ): Promise<DeleteWorkflowExecutionResponseType> {
            try {
                const response = await AxiosClient.delete(`campaign/execution/${executionId}`);
                return response.data as DeleteWorkflowExecutionResponseType;
            } catch (error) {
                console.error("Error deleting campaign execution", error);
                return { success: false } as DeleteWorkflowExecutionResponseType;
            }
        }

        static async markExecutionAsActive(executionId: string): Promise<boolean> {
            try {
                const response = await AxiosClient.post(`campaign/execution/${executionId}/active`);
                return response.data as boolean;
            } catch (error) {
                console.error("Error marking execution state", error);
                return false;
            }
        }

        static async markExecutionAsInActive(executionId: string): Promise<boolean> {
            try {
                const response = await AxiosClient.post(
                    `campaign/execution/${executionId}/inactive`
                );
                return response.data as boolean;
            } catch (error) {
                console.error("Error marking execution state", error);
                return false;
            }
        }

        static async retrieveCampaignExecutionById(
            executionId: string
        ): Promise<RetrieveCampaignExecutionByIdResponseType | ErrorResponseType> {
            try {
                const response = await AxiosClient.get(`/campaign/execution/${executionId}/`);
                return response.data as RetrieveCampaignExecutionByIdResponseType;
            } catch (error: any) {
                console.error("Error retrieving campaign executions", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async updateCampaignExclusions(
            campaignId: string,
            excludeLeadsFoundInOtherWorkflows: boolean,
            excludeLeadsFirstDegreeConnection: boolean,
            excludeLeadsWithNoPhoto: boolean,
            sendConnectionNoteAsMessageIfAlreadyConnected: boolean,
            waitUntilConnectionRequestAccepted: boolean,
            waitUntilConnectionRequestAcceptedDelay?: number
        ): Promise<UpdateCampaignExclusionsResponseType | ErrorResponseType> {
            try {
                const data = {
                    excludeLeadsFoundInOtherWorkflows,
                    excludeLeadsFirstDegreeConnection,
                    excludeLeadsWithNoPhoto,
                    sendConnectionNoteAsMessageIfAlreadyConnected,
                    waitUntilConnectionRequestAccepted,
                    waitUntilConnectionRequestAcceptedDelay
                };
                const response = await AxiosClient.patch(
                    `/campaign/${campaignId}/exclusions`,
                    data
                );
                return response.data as UpdateCampaignExclusionsResponseType;
            } catch (error: any) {
                console.error("Error updating campaign exclusions:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async overrideWorkingHours(
            workflowId: string,
            workingHours: WorkingHoursType[]
        ): Promise<OverrideWorkingHoursResponseType | ErrorResponseType> {
            try {
                const data: OverrideWorkingHoursRequestType = { workflowId, workingHours };
                const response = await AxiosClient.post(`/campaign/${workflowId}/wh`, data);
                return response.data as OverrideWorkingHoursResponseType;
            } catch (error: any) {
                console.error("Error overriding working hours:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async deleteOverrideWorkingHours(
            workflowId: string
        ): Promise<DeleteOverrideWorkingHoursResponseType | ErrorResponseType> {
            try {
                const response = await AxiosClient.delete(`/campaign/${workflowId}/wh`);
                return response.data as DeleteOverrideWorkingHoursResponseType;
            } catch (error: any) {
                console.error("Error deleting override working hours:", error);
                return error.response.data as ErrorResponseType;
            }
        }
    };

    static billing = class Billing {
        static async createStripeCheckoutSession(
            priceId: string
        ): Promise<CreateStripeCheckoutSessionResponseType | ErrorResponseType> {
            try {
                const data: CreateStripeCheckoutSessionRequestType = { priceId };
                const response = await AxiosClient.post("/payment/stripe/checkout", data);
                return response.data as CreateStripeCheckoutSessionResponseType;
            } catch (error: any) {
                console.error("Error creating Stripe checkout session:", error);
                return error.response.data as ErrorResponseType;
            }
        }
    };

    static integration = class Integration {
        static async retrieveLinkedInIntegration(): Promise<RetrieveLinkedInIntegrationResponseType | null> {
            try {
                const response = await AxiosClient.get("/integration/linkedin");
                return response.data as RetrieveLinkedInIntegrationResponseType;
            } catch (error) {
                console.error("Error retrieving LinkedIn integration:", error);
                return null;
            }
        }

        static async createLinkedInIntegration(
            username: string,
            password: string
        ): Promise<ConnectWithLinkedInResponseType | ErrorResponseType> {
            try {
                const data: ConnectWithLinkedInRequestType = { username, password };
                const config = { timeout: 60000 * 2 }; // 60 seconds
                const response = await AxiosClient.post("/integration/linkedin", data, config);
                return response.data as ConnectWithLinkedInResponseType;
            } catch (error: any) {
                console.error("Error creating LinkedIn integration:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async disconnectLinkedInIntegration(): Promise<
            ErrorResponseType | DisconnectLinkedInResponseType
        > {
            try {
                const response = await AxiosClient.delete("/integration/linkedin");
                return response.data as DisconnectLinkedInResponseType;
            } catch (error: any) {
                console.error("Error disconnecting LinkedIn integration:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async provideLinkedInChallengeToken(
            verificationSentToApp: boolean,
            challenge: string | null
        ): Promise<ProvideLinkedInChallengeTokenResponseType | ErrorResponseType> {
            try {
                const data: ProvideLinkedInChallengeTokenRequestType = {
                    challenge,
                    verificationSentToApp
                };
                const response = await AxiosClient.post("/integration/linkedin/verify", data);
                return response.data as ProvideLinkedInChallengeTokenResponseType;
            } catch (error: any) {
                console.error("Error providing LinkedIn challenge token:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async createSlackIntegration(
            slackToken: string
        ): Promise<ErrorResponseType | ConnectWithSlackResponseType> {
            try {
                const data: ConnectWithSlackRequestType = { oauthToken: slackToken };
                const response = await AxiosClient.post("/integration/slack", data);
                return response.data as ConnectWithSlackResponseType;
            } catch (error: any) {
                console.error("Error creating Slack integration:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async disconnectSlackIntegration(): Promise<boolean> {
            try {
                await AxiosClient.delete("/integration/slack");
                return true;
            } catch (error: any) {
                console.error("Error disconnecting Slack integration:", error);
                return false;
            }
        }

        static async retrieveIntegrations(): Promise<
            ErrorResponseType | RetrieveUserIntegrationResponseType
        > {
            try {
                const response = await AxiosClient.get("/integrations");
                return response.data as RetrieveUserIntegrationResponseType;
            } catch (error: any) {
                console.error("Error retrieving Slack integration:", error);
                return error.response.data as ErrorResponseType;
            }
        }
    };

    static templates = class Templates {
        static async retrieveTemplates(): Promise<
            RetrieveTemplatesResponseType | ErrorResponseType
        > {
            try {
                const response = await AxiosClient.get("/templates");
                return response.data as RetrieveTemplatesResponseType;
            } catch (error: any) {
                console.error("Error retrieving templates:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async createTemplate(
            name: string,
            sequence: SequenceType[]
        ): Promise<CreateNewTemplateResponseType | ErrorResponseType> {
            try {
                const data: CreateNewTemplateRequestType = { name, sequence };
                const response = await AxiosClient.post("/templates", data);
                return response.data as CreateNewTemplateResponseType;
            } catch (error: any) {
                console.error("Error creating template:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async deleteTemplate(
            templateId: string
        ): Promise<ErrorResponseType | DeleteTemplateResponseType> {
            try {
                const response = await AxiosClient.delete(`/templates/${templateId}`);
                return response.data as DeleteTemplateResponseType;
            } catch (error: any) {
                console.error("Error deleting template:", error);
                return error.response.data as ErrorResponseType;
            }
        }

        static async updateTemplate(
            templateId: string,
            sequence: SequenceType[]
        ): Promise<UpdateTemplateResponseType | ErrorResponseType> {
            try {
                const data: UpdateTemplateRequestType = { sequence };
                const response = await AxiosClient.patch(`/templates/${templateId}`, data);
                return response.data as UpdateTemplateResponseType;
            } catch (error: any) {
                console.error("Error updating template:", error);
                return error.response.data as ErrorResponseType;
            }
        }
    };

    static ai = class AI {
        static async rewriteWithAI(
            text: string,
            connectionNote: boolean
        ): Promise<RewriteWithAIResponse | null> {
            try {
                const response = await AxiosClient.post("/ai/rewrite", { text, connectionNote });
                return response.data as RewriteWithAIResponse;
            } catch (error) {
                return null;
            }
        }
    };
}
