import { Button, Divider, Select, SelectItem, Selection, Spinner } from "@nextui-org/react";
import Sidebar from "../../../components/Sidebar";
import { IoCloudUploadOutline } from "react-icons/io5";
import { CampaignType } from "../../../types/model";
import { ImmutableObject } from "@hookstate/core";
import { useCallback, useState } from "react";
import Api from "../../../global/Api";
import { IconContext } from "react-icons";
import { Set } from "typescript";
import { LeadAddedToCampaignType } from "../../../types/api/campaign";
import Papa from "papaparse";
import { RxCross2 } from "react-icons/rx";
import { FaLinkedin } from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { TbLetterF } from "react-icons/tb";
import { TbLetterL } from "react-icons/tb";
import { LiaLinkedinIn } from "react-icons/lia";
import { HiOutlineVariable } from "react-icons/hi";
import toast from "react-hot-toast";
import confetti from "canvas-confetti";
import { readUploadedFileAsText } from "../../../helpers/utils";

type AddLeadsToCampaignProps = {
    isOpen: boolean;
    onClose: () => void;
    campaign: ImmutableObject<CampaignType>;
    shouldRefresh?: () => void;
    firstTimeAddingLeads?: boolean;
};

function parseCSVToMap(csvString: string): { [key: string]: string[] } {
    // Parse the CSV string using PapaParse
    const parsed = Papa.parse(csvString, { header: true, skipEmptyLines: true });

    // Initialize the dictionary
    const csvDict: { [key: string]: string[] } = {};

    // Populate the dictionary
    parsed.meta.fields?.forEach((field) => {
        csvDict[field] = [];
    });

    // Iterate over each row in the parsed data
    parsed.data.forEach((row: any) => {
        parsed.meta.fields?.forEach((field) => {
            csvDict[field].push(row[field].trim());
        });
    });

    return csvDict;
}

export default function AddLeadsToCampaign({
    isOpen,
    onClose,
    campaign,
    shouldRefresh,
    firstTimeAddingLeads
}: AddLeadsToCampaignProps) {
    const campaignId = campaign.workflowId;
    const [file, setFile] = useState<File | null>(null);
    const [leads, setLeads] = useState<string[]>([]);
    const [processedLeads, setProcessedLeads] = useState<boolean>(false);
    const [csvMap, setCsvMap] = useState<{ [key: string]: string[] }>({}); // CSV map
    const [selectedKeysForHeader, setSelectedKeysForHeader] = useState<{ [key: string]: string }>(
        {}
    );

    const [refresh, setRefresh] = useState<boolean>(false);

    const onCloseHandler = () => {
        onClose();
        refresh && shouldRefresh && shouldRefresh();
        setRefresh(false);
        setFile(null);
        setLeads([]);
        setProcessedLeads(false);
        setSelectedKeysForHeader({});
    };

    const readFile = useCallback(
        async (file: File) => {
            try {
                const allLeads: string[] = [];
                const fileContents = (await readUploadedFileAsText(file)) as string;

                const csvMap = parseCSVToMap(fileContents);

                const rows = fileContents.split("\n");
                for (const row of rows) {
                    const items = row.split(",");
                    for (let i = 0; i < items.length; i++) {
                        // This regex pattern matches LinkedIn profile URLs
                        // It allows for http or https, with or without www
                        // The pattern captures the username part of the URL
                        const regex =
                            /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/([a-zA-Z0-9-]+)\/?/;
                        // Attempt to match the current item against the regex pattern
                        const match = items[i].match(regex);
                        console.log(match);
                        if (match) {
                            allLeads.push(match[0]);
                        }
                    }
                }

                setTimeout(() => {
                    setCsvMap(csvMap);
                    setProcessedLeads(true);
                    setLeads(allLeads);
                    setSelectedKeysForHeader({});
                }, 1000); // 1 second delay to show the loading spinner
            } catch (e) {
                console.error(e);
                setSelectedKeysForHeader({});
                setCsvMap({});
                setProcessedLeads(true);
                setLeads([]);
                toast.error("Error reading file");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [readUploadedFileAsText]
    );

    const uploadLeadsToCampaign = useCallback(
        async (campaignId: string, csvMap: { [key: string]: string[] }) => {
            // Check if the header that is selected for LinkedIn Handle has a valid URL
            const linkedInHandleHeader = Object.keys(selectedKeysForHeader).find(
                (key) => selectedKeysForHeader[key] === "1"
            );
            if (!linkedInHandleHeader) {
                toast.error("Please map at least one CSV column to 'LinkedIn Handle'");
                return;
            }

            // Construct LeadAddedToCampaignType from the CSV map
            const leads = csvMap[linkedInHandleHeader as string].map((value, index) => {
                const lead: LeadAddedToCampaignType = {
                    linkedInPublicUrl: value,
                    customVariables: {},
                    firstName: null,
                    lastName: null,
                    title: null,
                    company: null,
                    companyDomain: null,
                    companyLinkedInUrl: null
                };

                // Populate the lead with the selected values
                Object.keys(selectedKeysForHeader).forEach((key) => {
                    const selection = selectedKeysForHeader[key];
                    if (selection === "0") {
                        return;
                    }
                    if (selection === "8") {
                        // replace all # with nothing
                        // lowercase and replace spaces with underscores
                        const customVariableKey = key
                            .replace(/#/g, "")
                            .trim()
                            .toLowerCase()
                            .replace(/ /g, "_");
                        lead.customVariables[customVariableKey] = csvMap[key][index];
                        return;
                    }
                    if (selection === "2") {
                        lead.firstName = csvMap[key][index];
                        return;
                    }
                    if (selection === "3") {
                        lead.lastName = csvMap[key][index];
                        return;
                    }
                    if (selection === "4") {
                        lead.title = csvMap[key][index];
                        return;
                    }
                    if (selection === "5") {
                        lead.company = csvMap[key][index];
                        return;
                    }
                    if (selection === "6") {
                        lead.companyDomain = csvMap[key][index];
                        return;
                    }
                    if (selection === "7") {
                        lead.companyLinkedInUrl = csvMap[key][index];
                        return;
                    }
                });

                return lead;
            });

            const response = await Api.campaign.uploadLeadsToCampaign(campaignId, leads);
            if ("error" in response) {
                toast.error("Error uploading leads to campaign");
            } else {
                // Show a toast with the number of leads added to the campaign
                toast.success(`Added ${response.totalLeadsAddedToWorklow} leads to the campaign.`);

                if (firstTimeAddingLeads) {
                    confetti({
                        particleCount: 200,
                        spread: 90,
                        gravity: 0.8,
                        origin: {
                            y: 1
                        }
                    });
                }

                // Show a toast with the number of leads that already exist in the campaign
                // if (leads.length !== response.totalLeadsAddedToWorklow) {
                //     toast(
                //         `${leads.length - response.totalLeadsAddedToWorklow} already exist in the campaign.`
                //     );
                // }

                setRefresh(true);
                setLeads([]);
                setFile(null);
                setProcessedLeads(false);
                setSelectedKeysForHeader({});
            }
        },
        [firstTimeAddingLeads, selectedKeysForHeader]
    );

    // If we find a new file, then we extract all the possible leads.
    if (file && !processedLeads) {
        readFile(file);
    }

    const availableSelections = [
        {
            key: "0",
            label: "Do not import",
            image: <RxCross2 />
        },
        {
            key: "1",
            label: "LinkedIn Handle",
            image: <FaLinkedin />
        },
        {
            key: "2",
            label: "First Name",
            image: <TbLetterF />
        },
        {
            key: "3",
            label: "Last Name",
            image: <TbLetterL />
        },
        // {
        //     key: "4",
        //     label: "Title",
        //     image: <MdOutlineTitle />
        // },
        {
            key: "5",
            label: "Company Name",
            image: <GoOrganization />
        },
        // {
        //     key: "6",
        //     label: "Company Domain",
        //     image: <GrDomain />
        // },
        {
            key: "7",
            label: "Company LinkedIn URL",
            image: <LiaLinkedinIn />
        },
        {
            key: "8",
            label: "Custom Variable",
            image: <HiOutlineVariable />
        }
    ];

    const colors = ["text-purple-600", "text-blue-600", "text-green-600"];

    return (
        <>
            <Sidebar isOpen={isOpen} onClose={onCloseHandler} header="Add Leads to your Campaign">
                {file && !processedLeads ? (
                    <div className="flex flex-row gap-x-4 w-full h-full justify-center content-center">
                        <Spinner />
                        <p className="self-center text-xl text-black">Loading</p>
                    </div>
                ) : (
                    <div className="flex flex-col p-8 justify-center h-auto items-center gap-y-4">
                        <label htmlFor={campaignId} className="cursor-pointer">
                            <Button
                                isIconOnly
                                className="pointer-events-none bg-white w-auto h-auto p-4 rounded-full shadow-md"
                                color="default"
                            >
                                <IconContext.Provider
                                    value={{ className: "shared-class", size: "100" }}
                                >
                                    <IoCloudUploadOutline />
                                </IconContext.Provider>
                            </Button>
                        </label>
                        {!file && <p className="text-black">Upload a CSV file</p>}
                        <input
                            type="file"
                            id={campaignId}
                            accept=".csv"
                            className="hidden"
                            key={campaignId}
                            onChange={(e) => {
                                setProcessedLeads(false);
                                setLeads([]);
                                setSelectedKeysForHeader({});
                                setFile(e.target.files ? e.target.files[0] : null);
                                e.target.value = "";
                            }}
                        />

                        {file && (
                            <>
                                <p className="text-black">{file.name}</p>
                                <p className="text-green-600">Found {leads.length} leads</p>

                                {leads.length > 0 && (
                                    <div className="w-full flex flex-col gap-y-8">
                                        <Divider
                                            orientation="horizontal"
                                            className="w-full mt-8 bg-black"
                                        />

                                        <div className="grid grid-cols-3 gap-x-16 w-full mt-16">
                                            <h3 className="text-2xl font-bold text-purple underline">
                                                Column Name
                                            </h3>
                                            <h3 className="text-2xl font-bold text-purple underline">
                                                Select Type
                                            </h3>
                                            <h3 className="text-2xl font-bold text-purple underline">
                                                Samples
                                            </h3>
                                        </div>

                                        <div className="grid grid-cols-3 gap-y-32 gap-x-16 w-full mt-16 mb-8">
                                            {Object.keys(csvMap).map((header) => (
                                                <>
                                                    <p className="text-lg font-bold text-black">
                                                        {header}
                                                    </p>

                                                    <Select
                                                        isRequired
                                                        label="Mapping"
                                                        placeholder="Select a mapping"
                                                        className="max-w-xs"
                                                        disabledKeys={Object.values(
                                                            selectedKeysForHeader
                                                        ).filter((s) => s !== "0" && s !== "8")}
                                                        defaultSelectedKeys={"0"} // do not import
                                                        onSelectionChange={(key: Selection) => {
                                                            const selection = (
                                                                key as Set<React.Key>
                                                            )
                                                                .values()
                                                                .next().value as string;
                                                            setSelectedKeysForHeader(
                                                                (prevSelection) => ({
                                                                    ...prevSelection,
                                                                    [header]: selection
                                                                })
                                                            );
                                                        }}
                                                    >
                                                        {availableSelections.map((as) => (
                                                            <SelectItem
                                                                key={as.key}
                                                                startContent={as.image}
                                                            >
                                                                {as.label}
                                                            </SelectItem>
                                                        ))}
                                                    </Select>

                                                    <div className="flex flex-col gap-y-2">
                                                        {csvMap[header]
                                                            .slice(0, 3)
                                                            .map((value, index) => (
                                                                <p
                                                                    className={`${colors[index]} font-semibold`}
                                                                    key={index}
                                                                >
                                                                    {value}
                                                                </p>
                                                            ))}
                                                    </div>
                                                </>
                                            ))}
                                        </div>

                                        <Button
                                            isDisabled={leads.length === 0}
                                            key={campaignId}
                                            color="primary"
                                            className="self-center"
                                            onClick={(e) =>
                                                uploadLeadsToCampaign(campaignId, csvMap)
                                            }
                                        >
                                            Add Leads
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </Sidebar>
        </>
    );
}
