import { Button, Input, Switch } from "@nextui-org/react";
import { Edge, Node } from "reactflow";
import { useCallback, useRef, useState } from "react";

import FlowBuilder from "../../../components/flow/FlowBuilder";
import { ErrorResponseType, SequenceType } from "../../../types/model";
import Api from "../../../global/Api";
import { CreateNewCampaignResponseType } from "../../../types/api/campaign";
import { useNavigate } from "react-router";
import { NodeConfig } from "../../../types/internal";
import { createSequenceFromReactFlow } from "../../../helpers/utils";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import GlobalState from "../../../global/GlobalState";
import { ImmutableArray } from "@hookstate/core";

async function createNewCampaignButtonClick(
    campaignName: string,
    nodes: Node[],
    edges: Edge[],
    config: NodeConfig,
    excludeLeadsFoundInOtherWorkflows: boolean
) {
    if (!campaignName) {
        toast.error("Campaign Name is required");
        return;
    }

    const sequence: SequenceType[] | string = createSequenceFromReactFlow(nodes, edges, config);

    if (typeof sequence === "string") {
        toast.error(sequence);
        return;
    }

    return await Api.campaign.createNewCampaign(
        campaignName,
        sequence,
        excludeLeadsFoundInOtherWorkflows
    );
}

export default function CampaignCreate() {
    // Check if we are cloning a workflow
    let cloneSequence: ImmutableArray<SequenceType> | undefined = undefined;
    let cloneName: string | undefined = undefined;
    const [searchParams] = useSearchParams();
    const cloneFromWorkflowId = searchParams.get("clone");
    if (cloneFromWorkflowId) {
        // Fetch the workflow and set the nodes and edges
        const workflow = GlobalState.campaigns
            .get()
            .find((w) => w.workflowId === cloneFromWorkflowId);
        if (workflow) {
            cloneSequence = workflow.sequence;
            cloneName = workflow.name + " (Clone)";
        }
    }

    const [excludeLeadsFoundInOtherWorkflows, setExcludeLeadsFoundInOtherWorkflows] =
        useState(true);
    const [campaignName, setCampaignName] = useState(cloneName ?? "");

    // UI Button loader
    const [creating, setCreating] = useState(false);

    const navigate = useNavigate();

    const nodeConfig = useRef({} as NodeConfig);

    const nodes = useRef([] as Node[]);
    const edges = useRef([] as Edge[]);

    const setNodesCallback = (newNodes: Node[]) => {
        nodes.current = newNodes;
    };

    const setEdgesCallback = (newEdges: Edge[]) => {
        edges.current = newEdges;
    };

    const onNodeConfigChange = useCallback(
        (
            id: string,
            startNode: boolean,
            endNode: boolean,
            delay: number,
            note: string | null,
            message: string | null,
            altMessage: string | null
        ) => {
            nodeConfig.current[id] = { startNode, endNode, delay, note, message, altMessage };
        },
        []
    );

    const onNodeConfigDelete = useCallback((id: string) => {
        delete nodeConfig.current[id];
    }, []);

    const buttonHandler = useCallback(async () => {
        // Loader on the button
        setCreating(true);

        const response: CreateNewCampaignResponseType | ErrorResponseType | undefined =
            await createNewCampaignButtonClick(
                campaignName,
                nodes.current,
                edges.current,
                nodeConfig.current,
                excludeLeadsFoundInOtherWorkflows
            );

        // Turn off the loader
        setCreating(false);

        if (response) {
            if ("error" in response) {
                toast.error("Failed to create campaign");
                return;
            }

            toast.success("Campaign created successfully!");
            navigate("/campaigns");
        }
    }, [campaignName, excludeLeadsFoundInOtherWorkflows, navigate]);

    return (
        <div className="flex-1 p-8 flex flex-col gap-y-8 overflow-y-scroll">
            <h1 className="text-2xl font-semibold">Let's create a new campaign!</h1>
            <div className="flex flex-row justify-between">
                <Input
                    labelPlacement="outside-left"
                    label="Campaign Name"
                    placeholder="Enter a campaign name"
                    size="md"
                    value={campaignName}
                    onValueChange={setCampaignName}
                    classNames={{
                        inputWrapper: "w-60"
                    }}
                />
                <Switch
                    className="min-w-[320px]"
                    defaultSelected={excludeLeadsFoundInOtherWorkflows}
                    onValueChange={setExcludeLeadsFoundInOtherWorkflows}
                >
                    Exclude leads found in other workflows
                </Switch>
            </div>

            <FlowBuilder
                onNodeConfigChange={onNodeConfigChange}
                onNodeConfigDelete={onNodeConfigDelete}
                setNodesCallback={setNodesCallback}
                setEdgesCallback={setEdgesCallback}
                readonly={false}
                showTemplates={true}
                cloneFromWorkflow={cloneSequence}
            />

            <Button
                size="lg"
                variant="solid"
                className="bg-purple text-white w-36 self-end min-h-[40px]"
                onClick={buttonHandler}
                isLoading={creating}
            >
                Create
            </Button>
        </div>
    );
}
