import { Button } from "@nextui-org/react";
import { IoMdClose } from "react-icons/io";

type SidebarProps = {
    children?: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    header: string;
    width?: string;
    ref?: React.RefObject<HTMLDivElement>;
};

export default function Sidebar({ children, isOpen, onClose, header, width, ref }: SidebarProps) {
    return (
        <div
            ref={ref}
            className={`top-0 right-0 ${width ?? "w-[70vw]"} bg-[#F6F8FA] p-8
                text-white absolute h-full z-40 ease-in-out duration-300 
                ${isOpen ? "translate-x-0 " : "translate-x-full"}
                flex flex-col gap-y-4 shadow-md overflow-y-scroll overflow-x-hidden`}
        >
            {isOpen && (
                <div className="flex flex-row justify-between items-center">
                    <h1 className="text-2xl font-semibold text-purple">{header}</h1>
                    <Button
                        isIconOnly
                        className="text-4xl text-black cursor-pointer bg-transparent"
                        onClick={onClose}
                    >
                        <IoMdClose />
                    </Button>
                </div>
            )}
            {children}
        </div>
    );
}
