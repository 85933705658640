import { Button, Card, CardBody, CardHeader, CircularProgress, Tooltip } from "@nextui-org/react";
import { IoIosInformationCircle } from "react-icons/io";
import colors from "tailwindcss/colors";
import { CampaignType } from "../../../types/model";
import { ImmutableObject } from "@hookstate/core";
import { BarChart } from "@tremor/react";

type CampaignStatsProps = {
    campaign: ImmutableObject<CampaignType>;
};

export default function CampaignStats(props: CampaignStatsProps) {
    const { campaign } = props;

    const { totalLeadsAdded, totalLeadsInProgress, totalLeadsFinished, totalLeadsRejected } =
        campaign;

    const totalLeadsWaitingToEnterSequence = Math.max(
        0,
        totalLeadsAdded - totalLeadsFinished - totalLeadsInProgress
    );

    const statsData = [
        { x: 1, y: campaign?.connectionRequestCount ?? 0, fill: colors.red[400] },
        { x: 2, y: campaign?.messageCount ?? 0, fill: colors.green[400] },
        { x: 3, y: campaign?.profileViewCount ?? 0, fill: colors.slate[400] },
        { x: 4, y: campaign?.followCount ?? 0, fill: colors.purple[200] },
        { x: 5, y: campaign?.likeCount ?? 0, fill: colors.blue[400] },
        { x: 6, y: campaign?.withdrawCount ?? 0, fill: colors.amber[400] }
    ];

    return (
        <div className="flex flex-col w-full gap-y-4">
            <div className="flex flex-row gap-x-4">
                <Card className="min-w-48 min-h-48 p-4">
                    <CardHeader className="text-purple text-base font-bold">Progress</CardHeader>
                    <CardBody className="items-center justify-center">
                        <CircularProgress
                            classNames={{
                                svg: "w-32 h-32 drop-shadow-sm",
                                indicator: "stroke-purple",
                                track: "stroke-slate-200",
                                value: "text-xl font-semibold text-black"
                            }}
                            value={
                                totalLeadsAdded > 0
                                    ? (totalLeadsFinished / totalLeadsAdded) * 100
                                    : 0
                            }
                            valueLabel={
                                totalLeadsAdded > 0
                                    ? `${totalLeadsFinished} / ${totalLeadsAdded}`
                                    : 0
                            }
                            strokeWidth={4}
                            showValueLabel={true}
                        />
                    </CardBody>
                </Card>

                <Card className="min-w-48 min-h-48 p-4">
                    <CardHeader className="text-purple text-base font-bold">
                        Accept Count
                    </CardHeader>
                    <CardBody className="justify-center">
                        <p className="text-5xl">{campaign.acceptCount}</p>
                    </CardBody>
                </Card>

                <Card className="min-w-48 min-h-48 p-4">
                    <CardHeader className="text-purple text-base font-bold">Reply Count</CardHeader>
                    <CardBody className="justify-center">
                        <p className="text-5xl">{campaign.replyCount}</p>
                    </CardBody>
                </Card>

                <Card className="min-w-48 min-h-48 p-4">
                    <CardHeader className="text-purple text-base font-bold">
                        Leads Entering Sequence
                    </CardHeader>
                    <CardBody className="justify-center">
                        <p className="text-5xl">{totalLeadsWaitingToEnterSequence}</p>
                        {/* <Progress
                            aria-label="Scheduling"
                            size="md"
                            value={leadsWaitingToEnterSequence}
                            minValue={0}
                            maxValue={totalLeadsAdded}
                            formatOptions={{ style: "decimal" }}
                            color="primary"
                            showValueLabel={true}
                            className="max-w-md"
                        /> */}
                    </CardBody>
                </Card>

                <Card className="min-w-48 min-h-48 p-4">
                    <CardHeader className="flex flex-row justify-between">
                        <p className="text-purple text-base font-bold">Excluded Leads</p>
                        <Tooltip content="Leads excluded based on the filters selected">
                            <Button
                                isIconOnly
                                variant="ghost"
                                radius="full"
                                className="border-none w-fit h-fit"
                            >
                                <IoIosInformationCircle className="text-purple" size={"1.25em"} />
                            </Button>
                        </Tooltip>
                    </CardHeader>
                    <CardBody className="justify-center">
                        <p className="text-5xl">{totalLeadsRejected}</p>
                    </CardBody>
                </Card>
            </div>

            <Card className="p-4">
                <CardHeader className="text-purple text-base font-bold">
                    Campaign Analytics
                </CardHeader>
                <CardBody>
                    <CampaignStatsGraph data={statsData} />
                </CardBody>
            </Card>
        </div>
    );
}

function CampaignStatsGraph({ data }: { data: { x: number; y: number; fill?: string }[] }) {
    if (!data) {
        return <p>No data to display.</p>;
    }

    const categories = [
        "Connection Requests",
        "Messages Sent",
        "Profile Views",
        "Follows",
        "Likes",
        "Withdraws"
    ];

    // Rewrite x values to avoid any gaps and transform data for Tremor
    const chartData = data.map((d, i) => ({
        name: categories[i],
        "Connection Requests": d.x === 1 ? d.y : 0,
        "Messages Sent": d.x === 2 ? d.y : 0,
        "Profile Views": d.x === 3 ? d.y : 0,
        Follows: d.x === 4 ? d.y : 0,
        Likes: d.x === 5 ? d.y : 0,
        Withdraws: d.x === 6 ? d.y : 0
    }));

    return (
        <BarChart
            className="h-[28rem]"
            data={chartData}
            index="name"
            categories={categories}
            colors={["red", "green", "slate", "purple", "blue", "amber"]}
            yAxisWidth={48}
            yAxisLabel="Count"
            xAxisLabel="Activity Type"
        />
    );
}
