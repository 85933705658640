import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Button,
    Input,
    Switch,
    Progress,
    Divider,
    Chip,
    Select,
    SelectItem,
    Spinner,
    Selection,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import FlowBuilder from "../components/flow/FlowBuilder";
import AddLeadsToCampaign from "./campaigns/overview/AddLeadsToCampaign";
import { Node, Edge, useEdges } from "reactflow";
import { NodeConfig } from "../types/internal";
import { CampaignType, SequenceType } from "../types/model";
import toast, { Toaster } from "react-hot-toast";
import logo from "../assets/long_logo.svg";
import { createSequenceFromReactFlow, readUploadedFileAsText } from "../helpers/utils";
import Papa from "papaparse";
import { IconContext } from "react-icons";
import { FaLinkedin } from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { HiOutlineVariable } from "react-icons/hi";
import { IoCloudUploadOutline } from "react-icons/io5";
import { LiaLinkedinIn } from "react-icons/lia";
import { RxCross2 } from "react-icons/rx";
import { TbLetterF, TbLetterL } from "react-icons/tb";
import { LeadAddedToCampaignType } from "../types/api/campaign";
import OnboardingFlowBuilder from "../components/flow/OnboardingFlowBuilder";
import Api from "../global/Api";
import GlobalState from "../global/GlobalState";

type InlineAddLeadsToCampaignProps = {
    setResult: (leads: LeadAddedToCampaignType[]) => void;
    setTotalLeads: (totalLeads: number) => void;
};

function InlineAddLeadsToCampaign(props: InlineAddLeadsToCampaignProps) {
    const [file, setFile] = useState<File | null>(null);
    const [leads, setLeads] = useState<string[]>([]);
    const [processedLeads, setProcessedLeads] = useState<boolean>(false);
    const [csvMap, setCsvMap] = useState<{ [key: string]: string[] }>({}); // CSV map
    const [selectedKeysForHeader, setSelectedKeysForHeader] = useState<{ [key: string]: string }>(
        {}
    );

    const readFile = useCallback(async (file: File) => {
        try {
            const allLeads: string[] = [];
            const fileContents = (await readUploadedFileAsText(file)) as string;

            const csvMap = parseCSVToMap(fileContents);

            const rows = fileContents.split("\n");
            for (const row of rows) {
                const items = row.split(",");
                for (let i = 0; i < items.length; i++) {
                    const regex = /^https?:\/\/www\.linkedin\.com\/in\/([a-zA-Z0-9-]+)\/?/;
                    const match = items[i].match(regex);
                    if (match) {
                        allLeads.push(match[0]);
                    }
                }
            }

            setTimeout(() => {
                setCsvMap(csvMap);
                setProcessedLeads(true);
                setLeads(allLeads);
                setSelectedKeysForHeader({});
                props.setTotalLeads(allLeads.length);
            }, 1000); // 1 second delay to show the loading spinner
        } catch (e) {
            console.error(e);
            setSelectedKeysForHeader({});
            setCsvMap({});
            setProcessedLeads(true);
            setLeads([]);
            props.setTotalLeads(0);
        }
    }, []);

    const finalizeLeads = useCallback(
        async (
            csvMap: { [key: string]: string[] },
            selectedKeysForHeader: { [key: string]: string }
        ) => {
            // Check if the header that is selected for LinkedIn Handle has a valid URL
            const linkedInHandleHeader = Object.keys(selectedKeysForHeader).find(
                (key) => selectedKeysForHeader[key] === "1"
            );

            if (!linkedInHandleHeader) {
                props.setResult([]);
                return;
            }

            // Construct LeadAddedToCampaignType from the CSV map
            const leads = csvMap[linkedInHandleHeader as string].map((value, index) => {
                const lead: LeadAddedToCampaignType = {
                    linkedInPublicUrl: value,
                    customVariables: {},
                    firstName: null,
                    lastName: null,
                    title: null,
                    company: null,
                    companyDomain: null,
                    companyLinkedInUrl: null
                };

                // Populate the lead with the selected values
                Object.keys(selectedKeysForHeader).forEach((key) => {
                    const selection = selectedKeysForHeader[key];
                    if (selection === "0") {
                        return;
                    }
                    if (selection === "8") {
                        // replace all # with nothing
                        // lowercase and replace spaces with underscores
                        const customVariableKey = key
                            .replace(/#/g, "")
                            .trim()
                            .toLowerCase()
                            .replace(/ /g, "_");
                        lead.customVariables[customVariableKey] = csvMap[key][index];
                        return;
                    }
                    if (selection === "2") {
                        lead.firstName = csvMap[key][index];
                        return;
                    }
                    if (selection === "3") {
                        lead.lastName = csvMap[key][index];
                        return;
                    }
                    if (selection === "4") {
                        lead.title = csvMap[key][index];
                        return;
                    }
                    if (selection === "5") {
                        lead.company = csvMap[key][index];
                        return;
                    }
                    if (selection === "6") {
                        lead.companyDomain = csvMap[key][index];
                        return;
                    }
                    if (selection === "7") {
                        lead.companyLinkedInUrl = csvMap[key][index];
                        return;
                    }
                });

                return lead;
            });

            props.setResult(leads);
        },
        [props]
    );

    useEffect(() => {
        finalizeLeads(csvMap, selectedKeysForHeader);
    }, [csvMap, selectedKeysForHeader]);

    // If we find a new file, then we extract all the possible leads.
    if (file && !processedLeads) {
        readFile(file);
    }

    const availableSelections = [
        {
            key: "0",
            label: "Do not import",
            image: <RxCross2 />
        },
        {
            key: "1",
            label: "LinkedIn Handle",
            image: <FaLinkedin />
        },
        {
            key: "2",
            label: "First Name",
            image: <TbLetterF />
        },
        {
            key: "3",
            label: "Last Name",
            image: <TbLetterL />
        },
        // {
        //     key: "4",
        //     label: "Title",
        //     image: <MdOutlineTitle />
        // },
        {
            key: "5",
            label: "Company Name",
            image: <GoOrganization />
        },
        // {
        //     key: "6",
        //     label: "Company Domain",
        //     image: <GrDomain />
        // },
        {
            key: "7",
            label: "Company LinkedIn URL",
            image: <LiaLinkedinIn />
        },
        {
            key: "8",
            label: "Custom Variable",
            image: <HiOutlineVariable />
        }
    ];

    const colors = ["text-purple-600", "text-blue-600", "text-green-600"];

    return (
        <>
            {file && !processedLeads ? (
                <div className="flex flex-row gap-x-4 w-full h-full justify-center content-center">
                    <Spinner />
                    <p className="self-center text-xl text-black">Loading</p>
                </div>
            ) : (
                <div className="flex flex-col p-8 justify-center h-auto items-center gap-y-4">
                    <label htmlFor="file-input" className="cursor-pointer">
                        <Button
                            isIconOnly
                            className="pointer-events-none bg-white w-auto h-auto p-4 rounded-full shadow-md"
                            color="default"
                        >
                            <IconContext.Provider
                                value={{ className: "shared-class", size: "100" }}
                            >
                                <IoCloudUploadOutline />
                            </IconContext.Provider>
                        </Button>
                    </label>
                    {!file && <p className="text-black">Upload a CSV file</p>}
                    <input
                        type="file"
                        id="file-input"
                        accept=".csv"
                        className="hidden"
                        onChange={(e) => {
                            setProcessedLeads(false);
                            setLeads([]);
                            setSelectedKeysForHeader({});
                            setFile(e.target.files ? e.target.files[0] : null);
                            e.target.value = "";
                        }}
                    />

                    {file && (
                        <>
                            <p className="text-black">{file.name}</p>
                            <p className="text-green-600">Found {leads.length} leads</p>

                            {leads.length > 0 && (
                                <div className="w-full flex flex-col gap-y-8">
                                    <Divider
                                        orientation="horizontal"
                                        className="w-full mt-8 bg-black"
                                    />

                                    <div className="grid grid-cols-3 gap-x-16 w-full mt-16">
                                        <h3 className="text-2xl font-bold text-purple underline">
                                            Column Name
                                        </h3>
                                        <h3 className="text-2xl font-bold text-purple underline">
                                            Select Type
                                        </h3>
                                        <h3 className="text-2xl font-bold text-purple underline">
                                            Samples
                                        </h3>
                                    </div>

                                    <div className="grid grid-cols-3 gap-y-32 gap-x-16 w-full mt-16 mb-8">
                                        {Object.keys(csvMap).map((header) => (
                                            <>
                                                <p className="text-lg font-bold text-black">
                                                    {header}
                                                </p>

                                                <Select
                                                    isRequired
                                                    label="Mapping"
                                                    placeholder="Select a mapping"
                                                    className="max-w-xs"
                                                    disabledKeys={Object.values(
                                                        selectedKeysForHeader
                                                    ).filter((s) => s !== "0" && s !== "8")}
                                                    defaultSelectedKeys={"0"} // do not import
                                                    onSelectionChange={(key: Selection) => {
                                                        const selection = (key as Set<React.Key>)
                                                            .values()
                                                            .next().value as string;
                                                        setSelectedKeysForHeader(
                                                            (prevSelection) => ({
                                                                ...prevSelection,
                                                                [header]: selection
                                                            })
                                                        );
                                                    }}
                                                >
                                                    {availableSelections.map((as) => (
                                                        <SelectItem
                                                            key={as.key}
                                                            startContent={as.image}
                                                        >
                                                            {as.label}
                                                        </SelectItem>
                                                    ))}
                                                </Select>

                                                <div className="flex flex-col gap-y-2">
                                                    {csvMap[header]
                                                        .slice(0, 3)
                                                        .map((value, index) => (
                                                            <p
                                                                className={`${colors[index]} font-semibold`}
                                                                key={index}
                                                            >
                                                                {value}
                                                            </p>
                                                        ))}
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}

const Onboarding: React.FC = () => {
    const [step, setStep] = useState(1);

    const [campaignName, setCampaignName] = useState("");
    const [excludeLeadsFoundInOtherWorkflows, setExcludeLeadsFoundInOtherWorkflows] =
        useState(false);
    const [excludeFirstDegreeConnection, setExcludeFirstDegreeConnection] = useState(false);
    const [sendConnectionNoteAsMessage, setSendConnectionNoteAsMessage] = useState(false);
    const [waitUntilConnectionRequestAccepted, setWaitUntilConnectionRequestAccepted] =
        useState(false);
    const [selectedDelay, setSelectedDelay] = useState(7);

    const [sequence, setSequence] = useState<SequenceType[]>([]);

    const [leads, setLeads] = useState<LeadAddedToCampaignType[]>([]);
    const [totalLeads, setTotalLeads] = useState(0);

    const navigate = useNavigate();

    const handleSkip = () => {
        GlobalState.onboarding.set(false);
        navigate("/");
    };

    const [nodes, setNodes] = useState([] as Node[]);
    const [edges, setEdges] = useState([] as Edge[]);
    const [nodeConfig, setNodeConfig] = useState({} as NodeConfig);

    const handleNext = async () => {
        if (step === 1) {
            if (!campaignName) {
                toast.error("Please enter a campaign name");
                return;
            }
            setStep(2);
        } else if (step === 2) {
            const sequence = createSequenceFromReactFlow(nodes, edges, nodeConfig);
            if (typeof sequence === "string") {
                toast.error(sequence);
                return;
            }

            setSequence(sequence);
            setStep(3);
        } else if (step === 3) {
            if (totalLeads > 0 && leads.length === 0) {
                toast.error('Please map the linkedin url to the "LinkedIn Handle" column');
                return;
            }

            // Create campaign
            const response = await Api.campaign.createNewCampaign(
                campaignName,
                sequence,
                excludeLeadsFoundInOtherWorkflows
            );

            if ("error" in response) {
                toast.error("Error creating campaign: " + response.error.message);
                return;
            } else {
                toast.success("Campaign created successfully!");
            }

            const campaign = response.campaign;
            const campaignId = campaign.workflowId;

            // Update campaign settings
            const updateResponse = await Api.campaign.updateCampaignExclusions(
                campaignId,
                excludeLeadsFoundInOtherWorkflows,
                excludeFirstDegreeConnection,
                campaign.excludeLeadsWithNoPhoto,
                sendConnectionNoteAsMessage,
                waitUntilConnectionRequestAccepted,
                selectedDelay
            );

            if ("error" in updateResponse) {
                toast.error("Error updating campaign settings");
            }

            // Add leads to the campaign if any
            if (leads.length > 0) {
                const addLeadsResponse = await Api.campaign.uploadLeadsToCampaign(
                    campaignId,
                    leads
                );
                if ("error" in addLeadsResponse) {
                    toast.error("Error adding leads to campaign: " + addLeadsResponse.error);
                } else {
                    toast.success(
                        `Added ${addLeadsResponse.totalLeadsAddedToWorklow} leads to the campaign`
                    );
                }
            }

            GlobalState.onboarding.set(false);
            navigate("/");
        }
    };

    const handleBack = () => {
        if (step === 3) {
            setLeads([]);
            setTotalLeads(0);
        }
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const onNodeConfigChange = useCallback(
        (
            id: string,
            startNode: boolean,
            endNode: boolean,
            delay: number,
            note: string | null,
            message: string | null,
            altMessage: string | null
        ) => {
            setNodeConfig((prevConfig) => ({
                ...prevConfig,
                [id]: { startNode, endNode, delay, note, message, altMessage }
            }));
        },
        []
    );

    const onNodeConfigDelete = useCallback((id: string) => {
        setNodeConfig((prevConfig) => {
            const newConfig = { ...prevConfig };
            delete newConfig[id];
            return newConfig;
        });
    }, []);

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="flex flex-row gap-8 flex-1 items-center w-full justify-between">
                        <div className="flex flex-col items-center justify-center w-full flex-1">
                            <Input
                                className="w-96"
                                label="Campaign Name"
                                placeholder="Enter campaign name"
                                value={campaignName}
                                onChange={(e) => setCampaignName(e.target.value)}
                            />
                        </div>

                        <Divider orientation="vertical" className="flex-none" />
                        <div className="flex flex-col gap-4 flex-1 items-center justify-center">
                            <div className="flex flex-col gap-4">
                                <Switch
                                    defaultSelected={excludeLeadsFoundInOtherWorkflows}
                                    onChange={(e) =>
                                        setExcludeLeadsFoundInOtherWorkflows(e.target.checked)
                                    }
                                >
                                    Exclude leads found in other workflows
                                </Switch>
                                <Switch
                                    defaultSelected={excludeFirstDegreeConnection}
                                    onChange={(e) =>
                                        setExcludeFirstDegreeConnection(e.target.checked)
                                    }
                                >
                                    Exclude first degree connection
                                </Switch>
                                <Switch
                                    defaultSelected={sendConnectionNoteAsMessage}
                                    onChange={(e) =>
                                        setSendConnectionNoteAsMessage(e.target.checked)
                                    }
                                >
                                    Send connection note as message (if 1st degree connection)
                                </Switch>
                                <div className="flex flex-row gap-x-2 items-center">
                                    <Switch
                                        defaultSelected={waitUntilConnectionRequestAccepted}
                                        onChange={(e) =>
                                            setWaitUntilConnectionRequestAccepted(e.target.checked)
                                        }
                                    >
                                        Wait until connection request is accepted
                                    </Switch>

                                    {waitUntilConnectionRequestAccepted && (
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button
                                                    variant="bordered"
                                                    className="text-black border-purple h-fit"
                                                >
                                                    {selectedDelay} days
                                                </Button>
                                            </DropdownTrigger>
                                            <DropdownMenu>
                                                {[...Array(15)].map((_, index) => (
                                                    <DropdownItem
                                                        key={index + 7}
                                                        onClick={() => setSelectedDelay(index + 7)}
                                                    >
                                                        {index + 7} days
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <OnboardingFlowBuilder
                        onNodeConfigChange={onNodeConfigChange}
                        onNodeConfigDelete={onNodeConfigDelete}
                        setNodesCallback={setNodes}
                        setEdgesCallback={setEdges}
                        nodes={nodes}
                        edges={edges}
                        nodeConfig={nodeConfig}
                    />
                );
            case 3:
                return (
                    <div className="flex flex-col items-center justify-center w-full flex-1">
                        <InlineAddLeadsToCampaign
                            setResult={(leads: LeadAddedToCampaignType[]) => setLeads(leads)}
                            setTotalLeads={(totalLeads: number) => setTotalLeads(totalLeads)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col items-center justify-start bg-gray-100 p-4 w-screen h-screen">
            <div className="flex flex-row justify-between items-center w-full">
                <img src={logo} alt="Logo" className="w-48" />

                <Button onClick={handleSkip} className="bg-black text-white">
                    Skip
                </Button>
            </div>

            <div className="flex flex-col items-center justify-start p-4 w-full h-full">
                <div className="flex flex-col justify-start gap-y-2 h-fit w-full">
                    <h1 className="text-2xl font-bold mb-4">
                        Step {step}:{" "}
                        {step === 1
                            ? "Campaign Setup"
                            : step === 2
                              ? "Create Sequence"
                              : "Add Leads"}
                    </h1>
                    <Progress
                        value={(step / 3) * 100}
                        className="mb-4"
                        classNames={{ indicator: "bg-purple" }}
                    />
                </div>

                {renderStep()}

                <div className="flex flex-row justify-end mt-4 gap-x-2 w-full">
                    {step !== 1 && (
                        <Button className="bg-black text-white" onClick={handleBack}>
                            Back
                        </Button>
                    )}
                    <Button
                        className="bg-purple text-white mb-4"
                        onClick={handleNext}
                        isDisabled={step === 1 && !campaignName}
                    >
                        {step === 3 ? "Finish" : "Next"}
                    </Button>
                </div>
            </div>

            <Toaster />
        </div>
    );
};

export default Onboarding;

function parseCSVToMap(csvString: string): { [key: string]: string[] } {
    // Parse the CSV string using PapaParse
    const parsed = Papa.parse(csvString, { header: true, skipEmptyLines: true });

    // Initialize the dictionary
    const csvDict: { [key: string]: string[] } = {};

    // Populate the dictionary
    parsed.meta.fields?.forEach((field) => {
        csvDict[field] = [];
    });

    // Iterate over each row in the parsed data
    parsed.data.forEach((row: any) => {
        parsed.meta.fields?.forEach((field) => {
            csvDict[field].push(row[field].trim());
        });
    });

    return csvDict;
}
