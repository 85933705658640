import { Button, Input } from "@nextui-org/react";
import FlowBuilder from "../../components/flow/FlowBuilder";
import { useCallback, useRef, useState } from "react";
import Api from "../../global/Api";
import { Edge, Node } from "reactflow";
import { NodeConfig } from "../../types/internal";
import { createSequenceFromReactFlow } from "../../helpers/utils";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

export default function TemplateCreate() {
    // ReactFlow hooks will cause this component to re-render when the nodes or edges change
    // const nodes = useNodes() as unknown as Node[];
    // const edges = useEdges() as unknown as Edge[];
    const nodeConfig = useRef({} as NodeConfig);

    const [templateName, setTemplateName] = useState("New Template");

    const navigate = useNavigate();

    const nodes = useRef([] as Node[]);
    const edges = useRef([] as Edge[]);

    const setNodesCallback = (newNodes: Node[]) => {
        nodes.current = newNodes;
    };

    const setEdgesCallback = (newEdges: Edge[]) => {
        edges.current = newEdges;
    };

    const onNodeConfigChange = useCallback(
        (
            id: string,
            startNode: boolean,
            endNode: boolean,
            delay: number,
            note: string | null,
            message: string | null,
            altMessage: string | null
        ) => {
            nodeConfig.current[id] = { startNode, endNode, delay, note, message, altMessage };
        },
        []
    );

    const onNodeConfigDelete = useCallback((id: string) => {
        delete nodeConfig.current[id];
    }, []);

    async function createTemplateButtonHandler(templateName: string) {
        const sequence = createSequenceFromReactFlow(
            nodes.current,
            edges.current,
            nodeConfig.current
        );

        if (typeof sequence === "string") {
            toast.error(sequence);
            return;
        }

        const response = await Api.templates.createTemplate(templateName, sequence);
        if ("error" in response) {
            toast.error("Error creating template");
            return;
        }

        navigate("/templates");
    }

    return (
        <div className="flex-1 p-8 flex flex-col gap-y-4 overflow-y-scroll">
            <h1 className="text-2xl font-semibold mb-4">Create a new Template!</h1>

            <Input
                labelPlacement="outside-left"
                label="Template Name"
                placeholder="Enter a template name"
                size="md"
                value={templateName}
                onValueChange={setTemplateName}
                classNames={{
                    inputWrapper: "w-60"
                }}
            />

            <FlowBuilder
                onNodeConfigChange={onNodeConfigChange}
                onNodeConfigDelete={onNodeConfigDelete}
                setNodesCallback={setNodesCallback}
                setEdgesCallback={setEdgesCallback}
            />

            <Button
                size="lg"
                variant="solid"
                className="bg-purple text-white w-36 self-end min-h-[40px]"
                onClick={() => createTemplateButtonHandler(templateName)}
            >
                Create
            </Button>
        </div>
    );
}
