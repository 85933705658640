import {
    Button,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tooltip,
    getKeyValue
} from "@nextui-org/react";
import { Key, useCallback, useEffect, useMemo, useState } from "react";
import Api from "../../global/Api";
import GlobalState from "../../global/GlobalState";
import { useHookstate } from "@hookstate/core";
import LeadsFilter from "./LeadsFilter";
import { linkedInUrlFromPublicId } from "../../helpers/utils";
import { RetrieveUserLeadsResponseType } from "../../types/api/user";
import { UserLeadsType } from "../../types/model";
import { IoIosInformationCircle } from "react-icons/io";

const LEADS_COLUMNS = [
    { key: "linkedInPublicId", label: "LinkedIn Public Id" },
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
    { key: "company", label: "Company Name" },
    { key: "created", label: "Created" }
];

type LeadRow = {
    key: string;
    linkedInPublicId: string;
    firstName: string | null;
    lastName: string | null;
    company: string | null;
    created: string;
};

function renderCell(item: LeadRow, columnKey: Key) {
    switch (columnKey) {
        case "linkedInPublicId":
            return (
                <a
                    href={`https://www.linkedin.com/in/${item.linkedInPublicId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="underline hover:text-blue-600"
                >
                    {item.linkedInPublicId}
                </a>
            );
        default:
            return getKeyValue(item, columnKey);
    }
}

export default function Leads() {
    const leadsState = useHookstate(GlobalState.leads);
    const leads = leadsState.get();
    const [page, setPage] = useState(1);

    // Filter by linkedin handle
    const [selectedLinkedInPublicId, setSelectedLinkedInPublicId] = useState<
        { key: string; value: string } | undefined
    >(undefined);

    const pageSize = 25;

    const fetchAllLeads = useCallback(() => {
        const leads: UserLeadsType[] = [];

        const fetchIncrementally = async () => {
            let cursorId = null;

            do {
                const response: RetrieveUserLeadsResponseType | null =
                    await Api.user.retrieveLeads(cursorId);

                if (response && response.leads.length > 0) {
                    leads.push(...response.leads);
                    GlobalState.leads.set(leads);

                    cursorId = response.leads[response.leads.length - 1].leadId;
                } else {
                    break;
                }
            } while (true);
        };

        // Start the fetching process without awaiting it
        fetchIncrementally();
    }, []);

    useEffect(() => {
        if (leadsState.get().length === 0) {
            fetchAllLeads();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rows: LeadRow[] = useMemo(() => {
        return leads.map((lead) => {
            return {
                key: lead.leadId,
                firstName: lead.firstName,
                lastName: lead.lastName,
                company: lead.company,
                created: new Date(lead.createdAt).toDateString(),
                linkedInPublicId: lead.linkedInPublicId
            };
        });
    }, [leads]);

    const linkedInPublicIds: Record<string, string> = useMemo(
        () =>
            rows.reduce(
                (acc, curr) => {
                    acc[curr.key] = curr.linkedInPublicId;
                    return acc;
                },
                {} as Record<string, string>
            ),
        [rows]
    );

    let filteredRows: LeadRow[] = rows;

    // If we have a selected LinkedIn Public Id, filter the rows.
    if (selectedLinkedInPublicId) {
        filteredRows = filteredRows.filter(
            (row) => row.linkedInPublicId === selectedLinkedInPublicId.value
        );
    }

    const tableRows = filteredRows.length;
    filteredRows = filteredRows.slice(
        (page - 1) * pageSize,
        Math.min(page * pageSize, filteredRows.length)
    );

    const handleFilter = useCallback(
        (type: string, value: { key: string; value: string } | undefined) => {
            if (type === "LINKEDIN_PUBLIC_ID") {
                setSelectedLinkedInPublicId(value);
            }
        },
        []
    );

    const exportCSVLeads = useCallback(() => {
        // Export all the rows to a CSV file.
        const csvContent = ["LinkedIn Url,First Name,Last Name,Company,Created"]
            .concat(
                rows.map(
                    (row) =>
                        `${linkedInUrlFromPublicId(row.linkedInPublicId)},"${row.firstName ?? ""}","${row.lastName ?? ""}","${row.company ?? ""}",${row.created}`
                )
            )
            .join("\n");

        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([csvContent], { type: "text/csv" }));
        a.download = "tmd-exported-leads.csv";
        a.click();
    }, [rows]);

    return (
        <div className="flex-1 p-8 flex flex-col gap-y-4 overflow-y-scroll">
            <div className="flex items-center">
                <h1 className="text-2xl font-semibold">All of your Leads!</h1>

                <Tooltip
                    content={
                        <div className="text-sm">
                            <p>Leads from all of your campaigns are viewable here</p>
                            <p>For upto date lead information view each campaign</p>
                        </div>
                    }
                >
                    <Button isIconOnly variant="ghost" radius="full" className="border-none">
                        <IoIosInformationCircle
                            className="text-purple font-medium"
                            size={"1.5em"}
                        />
                    </Button>
                </Tooltip>
            </div>

            <div className="flex flex-row justify-end gap-x-4">
                <Button
                    className="w-48 h-12"
                    color="default"
                    variant="ghost"
                    onClick={exportCSVLeads}
                >
                    Export CSV
                </Button>

                <LeadsFilter
                    linkedInPublicIds={linkedInPublicIds}
                    onFilter={handleFilter}
                    selectedLinkedInPublicIdKey={selectedLinkedInPublicId}
                    endContent={<p className="border-white text-sm">({tableRows})</p>}
                />
            </div>

            <Table
                aria-label="Table containing all the user's campaigns."
                bottomContent={
                    <div className="flex w-full justify-end">
                        <Pagination
                            classNames={{
                                cursor: "bg-purple"
                            }}
                            showControls={true}
                            variant="flat"
                            radius="full"
                            total={Math.ceil(tableRows / pageSize)}
                            page={page}
                            initialPage={page}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                }
            >
                <TableHeader columns={LEADS_COLUMNS}>
                    {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                </TableHeader>
                <TableBody
                    items={filteredRows}
                    emptyContent={"Add leads to campaigns to view them here."}
                >
                    {(item) => (
                        <TableRow key={item.key}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
