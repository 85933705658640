import { useHookstate } from "@hookstate/core";
import GlobalState from "./global/GlobalState";
import Api from "./global/Api";
import { useEffect } from "react";
import Settings from "./pages/settings/Settings";
import { Route, Routes, useLocation } from "react-router-dom";
import LeftNav from "./pages/LeftNav";
import Home from "./pages/Home";
import Campaigns from "./pages/campaigns/overview/Campaigns";
import { useClerk } from "@clerk/clerk-react";
import Leads from "./pages/leads/Leads";
import { RetrieveNewUserResponseType } from "./types/api/user";
import { ErrorResponseType } from "./types/model";
import CampaignDetails from "./pages/campaigns/details/CampaignDetails";
import CampaignCreate from "./pages/campaigns/create/CampaignCreate";
import { ReactFlowProvider } from "reactflow";
import { Spinner } from "@nextui-org/react";
import Integrations from "./pages/integrations/Integrations";
import Templates from "./pages/templates/Templates";
import TemplateCreate from "./pages/templates/TemplateCreate";
import TemplateDetails from "./pages/templates/TemplateDetails";
import { SUPPORTED_TIMEZONES } from "./helpers/utils";
import { usePostHog } from "posthog-js/react";
import Intercom from "@intercom/messenger-js-sdk";
import toast, { Toaster } from "react-hot-toast";
import Onboarding from "./pages/Onboarding";
import LongLogo from "./assets/long_logo.svg";

export default function Dashboard() {
    const { signOut, session, user: clerkUser } = useClerk();
    const userState = useHookstate(GlobalState.user);
    const workingHoursState = useHookstate(GlobalState.workingHours);
    const limitsState = useHookstate(GlobalState.limits);
    const billingState = useHookstate(GlobalState.billing);
    const linkedInConfigurationState = useHookstate(GlobalState.linkedInConfiguration);

    const user = userState.get();

    const posthog = usePostHog();

    // exchange the session for a JWT.
    useEffect(() => {
        const initializeUser = async () => {
            if (!session) {
                return;
            }

            if (!clerkUser) {
                await signOut();
                return;
            }

            const token = await session.getToken({ template: "JWT" });

            if (!token) {
                await signOut();
                return;
            }

            // Use this JWT for API calls.
            Api.setAuthToken(token);

            const retrievedUser: RetrieveNewUserResponseType | ErrorResponseType =
                await Api.user.retrieveUser();
            if ("error" in retrievedUser) {
                // User not found in the database, so we create a new user.
                if (retrievedUser.error.code === 102) {
                    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    if (!SUPPORTED_TIMEZONES.map((tz) => tz.value).includes(timezone)) {
                        timezone = "America/New_York";
                    }

                    const createNewUser = await Api.user.createNewUser(timezone);
                    if ("error" in createNewUser) {
                        console.error(createNewUser.error);
                        return;
                    }

                    GlobalState.onboarding.set(true);
                    GlobalState.intercomHash.set(createNewUser.intercomHash);
                    userState.set(createNewUser.user);
                    workingHoursState.set(createNewUser.user.workingHours);
                    limitsState.set(createNewUser.user.limits);
                    billingState.set(createNewUser.user.billing);
                    // New user has no LinkedIn integration.
                    linkedInConfigurationState.set(null);
                }
            } else {
                // Fetch LinkedIn configuration before setting existing user state
                const linkedInIntegration = await Api.integration.retrieveLinkedInIntegration();

                GlobalState.intercomHash.set(retrievedUser.intercomHash);
                userState.set(retrievedUser.user);
                workingHoursState.set(retrievedUser.user.workingHours);
                limitsState.set(retrievedUser.user.limits);
                billingState.set(retrievedUser.user.billing);

                if (linkedInIntegration) {
                    linkedInConfigurationState.set(linkedInIntegration.configuration);
                }
            }

            // Retrieve user's historical activity.
            const historicalActivity = await Api.user.retrieveUserHourlyHistoricalActivity();
            if (historicalActivity) {
                GlobalState.historicalActivity.set(historicalActivity.hourlyActivities);
            }

            // Retrieve the user's integrations
            const integrations = await Api.integration.retrieveIntegrations();
            if (!("error" in integrations)) {
                GlobalState.integrations.set(integrations.integrations);
            }

            // Retrieve the user's templates
            const templates = await Api.templates.retrieveTemplates();
            if (!("error" in templates)) {
                GlobalState.templates.set(templates.templates);
            }

            // Find pending invitations
            const invitations = await Api.user.currentPendingInvitationsCount();
            if (!("error" in invitations)) {
                GlobalState.pendingInvitationsCount.set(invitations.count);
            }
        };

        initializeUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // only run when component mounts. when component mounts then session is not null.

    useEffect(() => {
        const user = userState.get();
        if (user) {
            posthog?.identify(
                user.id, // user's unique identifier
                {
                    email: user.email,
                    name: user.firstName + " " + user.lastName
                }
            );
        }
    }, [userState, posthog]);

    useEffect(() => {
        const user = userState.get();
        if (user?.firstName) {
            toast.success(`Welcome back, ${user.firstName}!`);
        }
    }, [userState]);

    const location = useLocation();
    const pathName = "/" + location.pathname.split("/")[1];

    const intercomHash = GlobalState.intercomHash.get();

    if (user && intercomHash) {
        Intercom({
            app_id: "pvayskp8",
            user_id: user.id,
            user_hash: intercomHash,
            name: user.firstName + " " + user.lastName,
            email: user.email,
            created_at: user.createdAt.valueOf() / 1000
        });
    }

    if (pathName === "/onboarding" || GlobalState.onboarding.get() === true) {
        return <Onboarding />;
    }

    return (
        <>
            <div className="w-screen h-screen flex flex-row">
                {userState.get() ? (
                    <>
                        <LeftNav
                            logoutHandler={() => {
                                posthog.reset();
                                signOut();
                            }}
                        />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/campaigns" element={<Campaigns />} />
                            <Route path="/campaigns/:id" element={<CampaignDetails />} />
                            <Route
                                path="/campaigns/create"
                                element={
                                    <ReactFlowProvider>
                                        <CampaignCreate />
                                    </ReactFlowProvider>
                                }
                            />
                            <Route path="/leads" element={<Leads />} />
                            <Route path="/templates" element={<Templates />} />
                            <Route path="/templates/:id" element={<TemplateDetails />} />
                            <Route
                                path="/templates/create"
                                element={
                                    <ReactFlowProvider>
                                        <TemplateCreate />
                                    </ReactFlowProvider>
                                }
                            />
                            <Route path="/integrations" element={<Integrations />} />
                            <Route path="/integration/slack/callback" element={<Integrations />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/payment/stripe/callback" element={<Settings />} />
                        </Routes>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center w-full h-full">
                        <img src={LongLogo} alt="Logo" className="h-36 bg-transparent" />
                        <div className="flex flex-row gap-x-4 justify-center items-center">
                            <Spinner />
                            <p className="self-center text-xl">Loading</p>
                        </div>
                    </div>
                )}
            </div>
            <Toaster />
        </>
    );
}
