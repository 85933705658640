export function IconButton({
    onClick,
    img,
    alt
}: {
    onClick: () => void;
    img: string | JSX.Element;
    alt: string;
}) {
    return (
        <button onClick={onClick} className="min-h-10">
            {typeof img === "string" ? <img src={img} alt={alt} className="w-6 h-6 mr-4" /> : img}
        </button>
    );
}
