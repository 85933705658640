import { Dropdown, DropdownTrigger, Button, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { Key, useState } from "react";

type FlowTemplateDropDownProps = {
    onAction: (key: Key) => void;
    items: { key: string; label: string; startContent?: JSX.Element }[];
};

export default function FlowTemplateDropDown(props: FlowTemplateDropDownProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown isOpen={isOpen}>
            <DropdownTrigger>
                <Button
                    variant="bordered"
                    onMouseEnter={() => {
                        setIsOpen(true);
                    }}
                    onMouseLeave={() => {
                        setIsOpen(false);
                    }}
                >
                    Templates
                </Button>
            </DropdownTrigger>
            <DropdownMenu
                aria-label="Use a template"
                items={props.items}
                onAction={props.onAction}
                onMouseEnter={() => {
                    setIsOpen(true);
                }}
                onMouseLeave={() => {
                    setIsOpen(false);
                }}
            >
                {(item) => (
                    <DropdownItem startContent={item.startContent} key={item.key}>
                        {item.label}
                    </DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
}
