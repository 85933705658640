import React, { useState, useEffect } from "react";
import { motion, Variants } from "framer-motion";

const GlowingComponent = (props: { children: React.ReactNode }) => {
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        const hasAnimated = localStorage.getItem("templateGlowAnimationShown");
        if (!hasAnimated) {
            setShouldAnimate(true);
            localStorage.setItem("templateGlowAnimationShown", "true");
        }
    }, []);

    const glowVariants: Variants = {
        initial: { boxShadow: "0 0 0 rgba(59, 130, 246, 0)" },
        animate: {
            boxShadow: [
                "0 0 0 rgba(59, 130, 246, 0)",
                "0 0 15px rgba(59, 130, 246, 0.7)",
                "0 0 0 rgba(59, 130, 246, 0)"
            ],
            transition: {
                duration: 2,
                repeat: 2,
                repeatType: "reverse" as const
            }
        }
    };

    return (
        <motion.div
            variants={glowVariants}
            style={{
                borderRadius: "inherit"
            }}
            initial="initial"
            animate={shouldAnimate ? "animate" : "initial"}
            onAnimationComplete={() => setShouldAnimate(false)}
        >
            {props.children}
        </motion.div>
    );
};

export default GlowingComponent;
