import { Button, useDisclosure } from "@nextui-org/react";
import { useCallback, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import FlowNodeConfig from "./FlowNodeConfig";
import { CiSettings } from "react-icons/ci";

export type FlowNodeData = {
    text: string;
    id: string;
    key: string;
    note: string | null;
    message: string | null;
    altMessage: string | null;
    delay: number;
    readonly?: boolean;
    onNodeConfigChange: (
        id: string,
        delay: number,
        note: string | null,
        message: string | null,
        altMessage: string | null
    ) => void;
};

export default function FlowNode(props: NodeProps<FlowNodeData>) {
    const id = props.id;
    const { text, key, onNodeConfigChange, readonly } = props.data;

    const [delay, setDelay] = useState<number>(props.data.delay);
    const [note, setNote] = useState<string | null>(props.data.note);
    const [message, setMessage] = useState<string | null>(props.data.message);
    const [altMessage, setAltMessage] = useState<string | null>(props.data.altMessage);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const saveButtonHandler = useCallback(
        (id: string, note: string, message: string, altMessage: string, delay: number) => {
            setNote(note);
            setMessage(message);
            setAltMessage(altMessage);
            setDelay(delay);
            onNodeConfigChange(id, delay, note, message, altMessage);
        },
        [onNodeConfigChange]
    );

    return (
        <>
            <Handle type="target" position={Position.Left} />

            <div className="flex flex-col min-h-[72px] h-auto w-[100px] border-solid bg-white border-purple-600 border rounded-sm">
                <Button
                    className="bg-purple-400 text-black self-end h-6 w-6 rounded-none m-0 p-0"
                    onClick={onOpen}
                    isIconOnly
                >
                    <CiSettings size="md" />
                </Button>

                <div className="flex-1 flex justify-center items-center">
                    <p className="text-[8px] text-center text-black font-semibold w-full h-fit p-auto">
                        {text}
                    </p>
                </div>

                {
                    // This code forces the modal to die and not save its local state
                    // Saving local state may confuse the user into thinking that their changes were saved
                    isOpen && (
                        <FlowNodeConfig
                            isOpen={isOpen}
                            onOpenChange={onOpenChange}
                            nodeType={key}
                            note={note}
                            message={message}
                            altMessage={altMessage}
                            delay={delay}
                            readonly={readonly}
                            onSave={(delay, note, message, altMessage) =>
                                saveButtonHandler(id, note, message, altMessage, delay)
                            }
                        />
                    )
                }
            </div>

            <Handle type="source" position={Position.Right} />
        </>
    );
}
