import "reactflow/dist/style.css";

import { v4 as uuidv4 } from "uuid";
import { Key, useCallback, useEffect, useState } from "react";
import ReactFlow, {
    Background,
    Connection,
    Controls,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    Panel,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges
} from "reactflow";
import FlowNode, { FlowNodeData } from "./FlowNode";
import FlowTerminalNode from "./FlowTerminalNode";
import FlowDropDown from "./FlowDropdown";
import { NodeConfig } from "../../types/internal";
import GlobalState from "../../global/GlobalState";
import { ImmutableArray, useHookstate } from "@hookstate/core";
import FlowTemplateDropDown from "./FlowTemplateDropdown";
import { SequenceType, TaskType } from "../../types/model";
import GlowingComponent from "../GlowingComponent";

const nodeTypes = {
    flowNode: FlowNode,
    flowTerminalNode: FlowTerminalNode
};

const nodeLabel: Record<string, string> = {
    connection: "Connection Request",
    view: "Profile View",
    message: "Send Message",
    like: "Like Post",
    follow: "Follow Profile",
    unfollow: "Unfollow Profile",
    withdraw: "Withdraw"
};

function taskTypeToKey(taskType: TaskType): string | null {
    switch (taskType) {
        case TaskType.LINKEDIN_CONNECT_WITH_NOTE:
            return "connection";
        case TaskType.LINKEDIN_VIEW_PROFILE:
            return "view";
        case TaskType.LINKEDIN_MESSAGE:
            return "message";
        case TaskType.LINKEDIN_LIKE:
            return "like";
        case TaskType.LINKEDIN_FOLLOW:
            return "follow";
        case TaskType.LINKEDIN_UNFOLLOW:
            return "unfollow";
        case TaskType.LINKEDIN_WITHDRAW:
            return "withdraw";
        default:
            return null;
    }
}

function convertSequenceToReactFlow(
    sequence: ImmutableArray<SequenceType>,
    onNodeConfigChange: (
        id: string,
        startNode: boolean,
        endNode: boolean,
        delay: number,
        note: string | null,
        message: string | null,
        altMessage: string | null
    ) => void
) {
    let initialNodes: Node[] = [];
    let initialEdges: Edge[] = [];
    let x = 200;

    // Have a start sequence
    initialNodes = [
        {
            id: uuidv4(),
            type: "flowTerminalNode",
            position: { x: 0, y: 0 },
            data: {
                key: "start",
                terminalType: "START"
            }
        }
    ];

    for (const item of sequence) {
        const key = taskTypeToKey(item.taskType);

        const props: FlowNodeData = {
            id: `${item.nodeId}`,
            key: key ?? "",
            text: key ? nodeLabel[key] : "",
            note: item.taskData?.note ?? null,
            delay: item.delay,
            message: item.taskData?.message ?? null,
            altMessage: item.taskData?.altMessage ?? null,
            onNodeConfigChange: (
                id: string,
                delay: number,
                note: string | null,
                message: string | null,
                altMessage: string | null
            ) => {
                onNodeConfigChange(id, false, false, delay, note, message, altMessage);
            }
        };

        const node = {
            id: `${item.nodeId}`,
            type: "flowNode",
            position: { x: x, y: 0 },
            data: props
        };

        x += 200;
        initialNodes.push(node);

        if (item.neighbors && item.neighbors.length > 0) {
            initialEdges.push({
                id: uuidv4(),
                source: node.id,
                target: `${item.neighbors[0].nodeId}`
            });
        }
    }

    initialEdges.push({
        id: uuidv4(),
        source: initialNodes[0].id,
        target: initialNodes[1].id
    });

    initialNodes.push({
        id: uuidv4(),
        type: "flowTerminalNode",
        position: { x: x, y: 0 },
        data: {
            key: "end",
            terminalType: "END"
        }
    });

    initialEdges.push({
        id: uuidv4(),
        source: initialNodes[initialNodes.length - 2].id,
        target: initialNodes[initialNodes.length - 1].id
    });

    return { initialNodes, initialEdges };
}

type OnboardingFlowBuilderProps = {
    onNodeConfigChange: (
        id: string,
        startNode: boolean,
        endNode: boolean,
        delay: number,
        note: string | null,
        message: string | null,
        altMessage: string | null
    ) => void;
    onNodeConfigDelete: (id: string) => void;
    setNodesCallback: (nodes: Node[]) => void;
    setEdgesCallback: (edges: Edge[]) => void;
    nodes: Node[];
    edges: Edge[];
    nodeConfig: NodeConfig;
};

export default function OnboardingFlowBuilder(props: OnboardingFlowBuilderProps) {
    const {
        onNodeConfigChange,
        onNodeConfigDelete,
        setNodesCallback,
        setEdgesCallback,
        nodeConfig
    } = props;

    const templates = useHookstate(GlobalState.templates).get();
    const templateDropdownItems = templates.map((template) => {
        return {
            key: template.templateId,
            label: template.name
        };
    });

    let initialNodes: Node[] = [];
    let initialEdges: Edge[] = [];

    if (props.nodes) {
        // Create nodes with the right data
        initialNodes = props.nodes.map((node) => ({
            ...node,
            data: {
                ...node.data,
                ...nodeConfig[node.id]
            }
        }));
    }

    if (props.edges) {
        initialEdges = props.edges;
    }

    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);

    useEffect(() => {
        setNodesCallback(nodes);
        setEdgesCallback(edges);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, edges]);

    const onEdgesChange = useCallback(
        (edges: EdgeChange[]) => {
            setEdges((eds) => applyEdgeChanges(edges, eds));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onNodesChange = useCallback(
        (changes: NodeChange[]) => {
            for (const change of changes) {
                if (change.type === "remove") {
                    onNodeConfigDelete(change.id);
                }
            }
            setNodes((eds) => applyNodeChanges(changes, eds));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onConnect = useCallback(
        (connection: Edge | Connection) => {
            setEdges((eds) => addEdge(connection, eds));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleDropdownSelection = useCallback((key: Key, nodes: Node[]) => {
        // Only called by the FlowNode component
        function localOnNodeConfigChange(
            id: string,
            delay: number,
            note: string | null,
            message: string | null,
            altMessage: string | null
        ) {
            onNodeConfigChange(id, false, false, delay, note, message, altMessage);
        }

        // Create a new node
        const startNode = key === "start";
        const endNode = key === "end";
        const type = startNode || endNode ? "flowTerminalNode" : "flowNode";

        const data =
            type === "flowNode"
                ? {
                      text: nodeLabel[key as string],
                      key,
                      onNodeConfigChange: localOnNodeConfigChange
                  }
                : {
                      key,
                      terminalType: startNode ? "START" : "END"
                  };

        // Find the max x position from all the existing nodes
        const x = nodes.reduce((maxX, node) => Math.max(maxX, node.position.x), 0);

        const newNode = {
            id: uuidv4(),
            type,
            position: { x: x + 200, y: 0 },
            data
        };

        onNodeConfigChange(newNode.id, startNode, endNode, 0, null, null, null);

        // Update the state
        setNodes([...nodes, newNode]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasConnectionRequestNode =
        nodes.filter((node) => node.data.key === "connection").length > 0;
    const hasStartNode = nodes.filter((node) => node.data.key === "start").length > 0;
    const hasEndNode = nodes.filter((node) => node.data.key === "end").length > 0;
    const hasFollowNode = nodes.filter((node) => node.data.key === "follow").length > 0;
    const hasWithdrawNode = nodes.filter((node) => node.data.key === "withdraw").length > 0;
    const hasUnfollowNode = nodes.filter((node) => node.data.key === "unfollow").length > 0;

    const disabledMenuKeys: string[] = (hasConnectionRequestNode ? ["connection"] : [])
        .concat(hasStartNode ? ["start"] : [])
        .concat(hasEndNode ? ["end"] : [])
        .concat(hasFollowNode ? ["follow"] : [])
        .concat(hasWithdrawNode ? ["withdraw"] : [])
        .concat(hasUnfollowNode ? ["unfollow"] : []);

    const loadTemplateHandler = useCallback(
        (key: Key) => {
            const templateId = key as string;
            const selectedTemplate = templates.find(
                (template) => template.templateId === templateId
            );
            if (!selectedTemplate) {
                return;
            }

            const { initialNodes: nodes, initialEdges: edges } = convertSequenceToReactFlow(
                selectedTemplate.sequence,
                onNodeConfigChange
            );

            for (const node of nodes) {
                // Check if the node is a terminal node
                if (node.type === "flowTerminalNode" && node.data.key === "start") {
                    onNodeConfigChange(node.id, true, false, 0, null, null, null);
                    continue;
                }

                if (node.type === "flowTerminalNode" && node.data.key === "end") {
                    onNodeConfigChange(node.id, false, true, 0, null, null, null);
                    continue;
                }

                const data: FlowNodeData = node.data;
                onNodeConfigChange(
                    node.id,
                    false,
                    false,
                    data.delay,
                    data.note,
                    data.message,
                    data.altMessage
                );
            }

            setNodes(nodes);
            setEdges(edges);
        },
        [onNodeConfigChange, templates]
    );

    return (
        <ReactFlow
            className="border-dashed border rounded-md border-slate-300"
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            fitView
        >
            <Background />
            <Controls />
            <Panel position="top-right">
                <div className="flex flex-row gap-x-4">
                    <FlowDropDown
                        disabledKeys={disabledMenuKeys}
                        onAction={(key: Key) => handleDropdownSelection(key, nodes)}
                    />
                    {templates.length > 0 && (
                        <div style={{ borderRadius: "10px" }}>
                            <GlowingComponent>
                                <FlowTemplateDropDown
                                    items={templateDropdownItems}
                                    onAction={loadTemplateHandler}
                                />
                            </GlowingComponent>
                        </div>
                    )}
                </div>
            </Panel>
        </ReactFlow>
    );
}
