export function NavBarButton({
    text,
    onClick,
    img,
    alt,
    active,
    isIconOnly
}: {
    text?: string;
    alt?: string;
    img: string | JSX.Element;
    active: boolean;
    isIconOnly?: boolean;
    onClick: () => void;
}) {
    let buttonClassNames = "flex flex-row items-center min-h-12 ";

    if (isIconOnly) {
        buttonClassNames += "justify-center w-full ";
    } else {
        buttonClassNames += "px-6 ";
    }

    if (active) {
        buttonClassNames += "bg-purple text-white";
    } else {
        buttonClassNames += "hover:bg-purple-200 active:bg-purple";
    }

    return (
        <button onClick={onClick} className={buttonClassNames}>
            {typeof img === "string" ? <img src={img} alt={alt} className="w-6 h-6 mr-4" /> : img}
            {text}
        </button>
    );
}
