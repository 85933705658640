import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalState";
import { useCallback, useRef } from "react";
import { UserLimitsType } from "../../types/model";
import Api from "../../global/Api";
import { Button, Card, CardBody, Slider } from "@nextui-org/react";
import toast from "react-hot-toast";

export default function Limits(): JSX.Element {
    const limitsState = useHookstate(GlobalState.limits);
    const limits = limitsState.get()!; // Component is only rendered when limits are available

    // Avoid re-rendering the component when the limits change
    const connectionRequestsLimit = useRef(limits.connectionRequestsLimit);
    const messagesLimit = useRef(limits.messagesLimit);
    const likesLimit = useRef(limits.likesLimit);
    const followLimit = useRef(limits.followLimit);
    const profileViewsLimit = useRef(limits.profileViewsLimit);
    const withdrawLimit = useRef(limits.withdrawLimit);
    const inMailLimit = useRef(limits.inMailLimit);
    const fetchProfilesLimit = useRef(limits.fetchProfilesLimit);

    const updateUserLimits = useCallback(
        async (
            limitId: string,
            connectionRequestsLimit: number,
            messagesLimit: number,
            likesLimit: number,
            followLimit: number,
            profileViewsLimit: number,
            withdrawLimit: number,
            inMailLimit: number,
            fetchProfilesLimit: number
        ) => {
            const limits: UserLimitsType = {
                connectionRequestsLimit,
                messagesLimit,
                likesLimit,
                followLimit,
                profileViewsLimit,
                withdrawLimit,
                inMailLimit,
                fetchProfilesLimit,
                id: limitId,
                // Following don't matter
                createdAt: new Date(),
                updatedAt: new Date()
            };
            const result = await Api.user.updateLimits(limits);
            if ("error" in result) {
                toast.error("Failed to update limits");
                return;
            }

            toast.success("Limits updated successfully");
            limitsState.set(result.limits);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div className="flex flex-col gap-y-6">
            <p className="text-sm">
                Limits define the number of actions which will take place daily.
                <br />
                Our algorithm also limits actions on an hourly basis as well.
            </p>
            <Card className="p-4 w-fit">
                <CardBody>
                    <div className="flex flex-col gap-y-8">
                        <Slider
                            label="Connection Requests"
                            showTooltip={true}
                            defaultValue={limits.connectionRequestsLimit}
                            className="w-[448px]"
                            onChangeEnd={(value) =>
                                (connectionRequestsLimit.current = value as number)
                            }
                        />
                        <Slider
                            label="Messages"
                            showTooltip={true}
                            defaultValue={limits.messagesLimit}
                            className="w-[448px]"
                            onChangeEnd={(value) => (messagesLimit.current = value as number)}
                        />
                        <Slider
                            label="Likes"
                            showTooltip={true}
                            defaultValue={limits.likesLimit}
                            className="w-[448px]"
                            onChangeEnd={(value) => (likesLimit.current = value as number)}
                        />
                        <Slider
                            label="Follows"
                            showTooltip={true}
                            defaultValue={limits.followLimit}
                            className="w-[448px]"
                            onChangeEnd={(value) => (followLimit.current = value as number)}
                        />
                        <Slider
                            label="Profile Views"
                            showTooltip={true}
                            defaultValue={limits.profileViewsLimit}
                            className="w-[448px]"
                            onChangeEnd={(value) => (profileViewsLimit.current = value as number)}
                        />
                        <Slider
                            label="Withdraws"
                            showTooltip={true}
                            defaultValue={limits.withdrawLimit}
                            className="w-[448px]"
                            onChangeEnd={(value) => (withdrawLimit.current = value as number)}
                        />
                        <Slider
                            label="Fetch Profiles"
                            showTooltip={true}
                            defaultValue={limits.fetchProfilesLimit}
                            className="w-[448px]"
                            onChangeEnd={(value) => (fetchProfilesLimit.current = value as number)}
                        />
                        <Button
                            size="md"
                            variant="solid"
                            className="bg-purple text-white w-36 self-end"
                            onClick={async () => {
                                // If none of the limits changes, don't make the API call
                                if (
                                    connectionRequestsLimit.current ===
                                        limits.connectionRequestsLimit &&
                                    messagesLimit.current === limits.messagesLimit &&
                                    likesLimit.current === limits.likesLimit &&
                                    followLimit.current === limits.followLimit &&
                                    profileViewsLimit.current === limits.profileViewsLimit &&
                                    withdrawLimit.current === limits.withdrawLimit &&
                                    inMailLimit.current === limits.inMailLimit &&
                                    fetchProfilesLimit.current === limits.fetchProfilesLimit
                                ) {
                                    return;
                                }

                                await updateUserLimits(
                                    limits.id,
                                    connectionRequestsLimit.current,
                                    messagesLimit.current,
                                    likesLimit.current,
                                    followLimit.current,
                                    profileViewsLimit.current,
                                    withdrawLimit.current,
                                    inMailLimit.current,
                                    fetchProfilesLimit.current
                                );
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
