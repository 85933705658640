import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import Dashboard from "./Dashboard";
import { NextUIProvider } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

function App() {
    const clerkKey = process.env.REACT_APP_CLERK_FRONTEND_API!;
    const navigate = useNavigate();

    return (
        <NextUIProvider navigate={navigate}>
            <ClerkProvider publishableKey={clerkKey}>
                <SignedOut>
                    <RedirectToSignIn />
                </SignedOut>
                <SignedIn>
                    <Dashboard />
                </SignedIn>
            </ClerkProvider>
        </NextUIProvider>
    );
}

export default App;
