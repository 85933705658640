import { SequenceType, TaskType } from "../types/model";

export class Node {
    parent: Node | null;
    children: Node[];
    id: number;
    delay: number;
    taskType: TaskType;
    note?: string | null;
    message?: string | null;
    altMessage?: string | null;

    constructor(
        id: number,
        delay: number,
        taskType: TaskType,
        note?: string | null,
        message?: string | null,
        altMessage?: string | null
    ) {
        this.id = id;
        this.delay = delay;
        this.taskType = taskType;
        this.note = note;
        this.message = message;
        this.altMessage = altMessage;

        this.parent = null;
        this.children = [];
    }

    addParent(node: Node) {
        this.parent = node;
    }

    setChild(node: Node) {
        if (this.children.length === 0) {
            this.children.push(node);
        } else {
            this.children[0] = node;
        }
    }
}

export class DAG {
    root: Node | null;
    nodes: Node[];

    constructor() {
        this.root = null;
        this.nodes = [];
    }

    setRoot(node: Node) {
        this.root = node;
    }

    addNode(node: Node) {
        this.nodes.push(node);
    }

    getSequence() {
        const sequence: SequenceType[] = [];
        if (!this.root) {
            return sequence;
        }

        // Sort nodes by nodeId in ascending order
        this.nodes.sort((a, b) => a.id - b.id);

        for (const node of this.nodes) {
            const neighbors = node.children.map((child) => {
                return {
                    nodeId: child.id
                };
            });

            sequence.push({
                nodeId: node.id,
                delay: node.delay,
                taskType: node.taskType,
                taskData: {
                    note: node.note,
                    message: node.message,
                    altMessage: node.altMessage
                },
                neighbors
            });
        }

        return sequence;
    }
}
