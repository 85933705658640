import { ImmutableObject } from "@hookstate/core";
import { Edge, Node } from "reactflow";
import { NodeConfig } from "../types/internal";
import { DAG, Node as DagNode } from "./dag";
import { SequenceType, TaskType } from "../types/model";

type ErrorMessage = string;

export const formatTime = (time: ImmutableObject<Date> | Date): string => {
    const date = new Date(time);
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
};

export function createSequenceFromReactFlow(
    nodes: Node[],
    edges: Edge[],
    config: NodeConfig
): SequenceType[] | ErrorMessage {
    // Find first node
    const startNode = nodes.find((node) => {
        return config[node.id]?.startNode;
    });
    if (!startNode) {
        return "No Start Action found";
    }

    // Find last node
    const endNode = nodes.find((node) => {
        return config[node.id]?.endNode;
    });
    if (!endNode) {
        return "No End Action found";
    }

    if (nodes.length === 2) {
        return "Only Start and End Actions found";
    }

    // Map all the edges
    // One source to one target
    const edgeMap = edges.reduce(
        (acc, edge) => {
            acc[edge.source] = edge.target;
            return acc;
        },
        {} as Record<string, string>
    );

    const dagNodes: DagNode[] = [];
    function findNextNode(node: Node): Node | null {
        const nextNodeId = edgeMap[node.id];
        if (!nextNodeId) {
            return null;
        }
        return nodes.find((node) => node.id === nextNodeId) ?? null;
    }

    let currentNode = findNextNode(startNode);
    if (!currentNode) {
        return "Start Action not connected to any other action.";
    }

    function taskTypeFromKey(key: string): TaskType | null {
        switch (key) {
            case "connection":
                return TaskType.LINKEDIN_CONNECT_WITH_NOTE;
            case "view":
                return TaskType.LINKEDIN_VIEW_PROFILE;
            case "message":
                return TaskType.LINKEDIN_MESSAGE;
            case "like":
                return TaskType.LINKEDIN_LIKE;
            case "follow":
                return TaskType.LINKEDIN_FOLLOW;
            case "unfollow":
                return TaskType.LINKEDIN_UNFOLLOW;
            case "withdraw":
                return TaskType.LINKEDIN_WITHDRAW;
            default:
                return null;
        }
    }

    // First starting actionable node.
    let nodeId = 1;
    while (currentNode) {
        if (currentNode === endNode) {
            break;
        }
        const delay = config[currentNode.id]?.delay ?? 0;
        const note = config[currentNode.id]?.note ?? null;
        const message = config[currentNode.id]?.message ?? null;
        const altMessage = config[currentNode.id]?.altMessage ?? null;
        const key = currentNode.data.key as string;

        let taskType = taskTypeFromKey(key);
        if (!taskType) {
            return "Something went wrong.";
        }

        if (taskType === TaskType.LINKEDIN_MESSAGE && !message) {
            return "Please specify message";
        }

        if (taskType === TaskType.LINKEDIN_MESSAGE && !altMessage) {
            return "Please specify alternative message";
        }

        dagNodes.push(new DagNode(nodeId, delay, taskType, note, message, altMessage));

        nodeId += 1;

        const nextNode = findNextNode(currentNode);
        currentNode = nextNode;
    }

    if (currentNode !== endNode) {
        return "End Action not reachable from Start Action";
    }

    // Check if any nodes are unreachable
    if (dagNodes.length !== nodes.length - 2) {
        return "Some actions are unreachable from the start action.";
    }

    // Convert to DAG and Node
    const dag = new DAG();
    dag.setRoot(dagNodes[0]);
    for (let i = 0; i < dagNodes.length; i++) {
        dag.addNode(dagNodes[i]);
    }

    for (let i = 1; i < dagNodes.length; i++) {
        dagNodes[i].addParent(dagNodes[i - 1]);
        dagNodes[i - 1].setChild(dagNodes[i]);
    }

    return dag.getSequence();
}

export function linkedInUrlFromPublicId(publicId: string): string {
    return `https://www.linkedin.com/in/${publicId}`;
}

export const readUploadedFileAsText = (inputFile: File): Promise<string | ArrayBuffer | null> => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };

        temporaryFileReader.readAsText(inputFile);
    });
};

export const SUPPORTED_TIMEZONES: { key: number; value: string }[] = [
    { key: 1, value: "America/New_York" },
    { key: 2, value: "America/Chicago" },
    { key: 3, value: "America/Denver" },
    { key: 4, value: "America/Los_Angeles" },
    { key: 5, value: "America/Phoenix" },
    { key: 6, value: "America/Anchorage" },
    { key: 7, value: "America/Adak" },
    { key: 8, value: "Pacific/Honolulu" },
    { key: 9, value: "Europe/Amsterdam" },
    { key: 10, value: "Europe/Andorra" },
    { key: 11, value: "Europe/Astrakhan" },
    { key: 12, value: "Europe/Athens" },
    { key: 13, value: "Europe/Belgrade" },
    { key: 14, value: "Europe/Berlin" },
    { key: 15, value: "Europe/Bratislava" },
    { key: 16, value: "Europe/Brussels" },
    { key: 17, value: "Europe/Bucharest" },
    { key: 18, value: "Europe/Budapest" },
    { key: 19, value: "Europe/Busingen" },
    { key: 20, value: "Europe/Chisinau" },
    { key: 21, value: "Europe/Copenhagen" },
    { key: 22, value: "Europe/Dublin" },
    { key: 23, value: "Europe/Gibraltar" },
    { key: 24, value: "Europe/Guernsey" },
    { key: 25, value: "Europe/Helsinki" },
    { key: 26, value: "Europe/Isle_of_Man" },
    { key: 27, value: "Europe/Istanbul" },
    { key: 28, value: "Europe/Jersey" },
    { key: 29, value: "Europe/Kaliningrad" },
    { key: 30, value: "Europe/Kiev" },
    { key: 31, value: "Europe/Kirov" },
    { key: 32, value: "Europe/Lisbon" },
    { key: 33, value: "Europe/Ljubljana" },
    { key: 34, value: "Europe/London" },
    { key: 35, value: "Europe/Luxembourg" },
    { key: 36, value: "Europe/Madrid" },
    { key: 37, value: "Europe/Malta" },
    { key: 38, value: "Europe/Mariehamn" },
    { key: 39, value: "Europe/Minsk" },
    { key: 40, value: "Europe/Monaco" },
    { key: 41, value: "Europe/Moscow" },
    { key: 42, value: "Europe/Oslo" },
    { key: 43, value: "Europe/Paris" },
    { key: 44, value: "Europe/Podgorica" },
    { key: 45, value: "Europe/Prague" },
    { key: 46, value: "Europe/Riga" },
    { key: 47, value: "Europe/Rome" },
    { key: 48, value: "Europe/Samara" },
    { key: 49, value: "Europe/San_Marino" },
    { key: 50, value: "Europe/Sarajevo" },
    { key: 51, value: "Europe/Saratov" },
    { key: 52, value: "Europe/Simferopol" },
    { key: 53, value: "Europe/Skopje" },
    { key: 54, value: "Europe/Sofia" },
    { key: 55, value: "Europe/Stockholm" },
    { key: 56, value: "Europe/Tallinn" },
    { key: 57, value: "Europe/Tirane" },
    { key: 58, value: "Europe/Ulyanovsk" },
    { key: 59, value: "Europe/Uzhgorod" },
    { key: 60, value: "Europe/Vaduz" },
    { key: 61, value: "Europe/Vatican" },
    { key: 62, value: "Europe/Vienna" },
    { key: 63, value: "Europe/Vilnius" },
    { key: 64, value: "Europe/Volgograd" },
    { key: 65, value: "Europe/Warsaw" },
    { key: 66, value: "Europe/Zagreb" },
    { key: 67, value: "Europe/Zaporozhye" },
    { key: 68, value: "Europe/Zurich" }
];
