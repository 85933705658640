import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import {
    Button,
    Chip,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Selection,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    getKeyValue
} from "@nextui-org/react";
import { WorkingHoursType } from "../types/model";
import moment from "moment-timezone";
import { SUPPORTED_TIMEZONES, formatTime } from "../helpers/utils";
import toast from "react-hot-toast";

const WH_COLUMNS = [
    { key: "dayOfWeek", label: "Day" },
    { key: "start", label: "Start" },
    { key: "end", label: "End" },
    { key: "timezone", label: "Timezone" },
    { key: "active", label: "Active" },
    { key: "updated", label: "Last Updated" },
    { key: "actions", label: "Actions" }
];

type WHRow = {
    key: string;
    dayOfWeek: string;
    start: string;
    end: string;
    startDate: Date;
    endDate: Date;
    timezone: string;
    active: boolean;
    updated: string;
};

function getDayString(dayOfWeek: number): string {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[dayOfWeek];
}

function getDayOfWeek(day: string): number {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days.indexOf(day);
}

const timeToUTC = (time: string): Date => moment.tz(time, "hh:mm", "UTC").toDate();

type WorkingHoursProps = {
    workingHours: WorkingHoursType[];
    onSave: (workingHours: WorkingHoursType[]) => void;
    onDelete?: () => void;
};

export default function WorkingHoursComponent({
    workingHours,
    onSave,
    onDelete
}: WorkingHoursProps): JSX.Element {
    const rows: WHRow[] = workingHours
        .map((wh) => ({
            key: wh.id,
            dayOfWeek: getDayString(wh.dayOfWeek),
            start: formatTime(wh.start),
            end: formatTime(wh.end),
            startDate: wh.start,
            endDate: wh.end,
            timezone: wh.timezone,
            active: wh.active,
            updated: new Date(wh.updatedAt).toDateString()
        }))
        .sort((a, b) => {
            return getDayOfWeek(a.dayOfWeek) - getDayOfWeek(b.dayOfWeek);
        })

    // Keep track of one selected timezone for all the rows
    const [selectedTimezone, setSelectedTimezone] = useState<Selection>(
        new Set(rows.length > 0 ? [rows[0].timezone] : ["America/New_York"])
    );

    const selectedValue = useMemo(
        () => Array.from(selectedTimezone).join(", ").replaceAll("_", " "),
        [selectedTimezone]
    );

    useEffect(() => {
        handleTimezoneChange(selectedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimezone]);

    // Keep track of all the updated rows
    const updatedRows = useRef<WHRow[]>([]);

    console.log(selectedTimezone);

    const handleTimezoneChange = useCallback(
        (timezone: string) => {
            // All rows need to be updated with the new timezone, but other changes should be kept
            const allCurrentUpdatedRows = updatedRows.current.map((r) => r.key);
            rows.forEach((row) => {
                if (!allCurrentUpdatedRows.includes(row.key)) {
                    updatedRows.current.push({ ...row, timezone });
                } else {
                    const index = updatedRows.current.findIndex((r) => r.key === row.key);
                    updatedRows.current[index].timezone = timezone;
                }
            });
        },
        [rows]
    );

    const handleActiveChange = useCallback((row: WHRow, active: boolean) => {
        const index = updatedRows.current.findIndex((r) => r.key === row.key);
        if (index !== -1) {
            updatedRows.current[index].active = active;
            return;
        }
        updatedRows.current.push({ ...row, active });
    }, []);

    const handleTimeChange = useCallback((row: WHRow, time: string, isStart: boolean) => {
        const index = updatedRows.current.findIndex((r) => r.key === row.key);
        if (index !== -1) {
            if (isStart) {
                updatedRows.current[index].start = time;
            } else {
                updatedRows.current[index].end = time;
            }
            return;
        }
        updatedRows.current.push({ ...row, [isStart ? "start" : "end"]: time });
    }, []);

    const handleSaveButton = useCallback(async () => {
        // Validate start and end times
        const invalidRows = updatedRows.current.filter((row) => {
            return !row.start || !row.end;
        });

        if (invalidRows.length > 0) {
            toast.error("Please fill in all the start and end times.");
            return;
        }

        const updatedWorkingHours: WorkingHoursType[] = updatedRows.current.map((row) => {
            const { key, active, timezone } = row;
            return {
                id: key,
                dayOfWeek: getDayOfWeek(row.dayOfWeek),
                start: timeToUTC(row.start),
                end: timeToUTC(row.end),
                active,
                timezone,
                // Following fields are not used in the API.
                createdAt: new Date(),
                updatedAt: new Date()
            };
        });

        // Add the remaining working hours to the array
        const remainingWorkingHours = workingHours.filter(
            (wh) => !updatedWorkingHours.some((uwh) => uwh.id === wh.id)
        );
        updatedWorkingHours.push(...remainingWorkingHours);

        onSave(updatedWorkingHours);
    }, [onSave, workingHours]);

    return (
        <div className="flex flex-col gap-y-6">
            <Table aria-label="Table containing all the user's campaigns.">
                <TableHeader columns={WH_COLUMNS}>
                    {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                </TableHeader>
                <TableBody items={rows} emptyContent={"No rows to display."}>
                    {(item) => (
                        <TableRow key={item.key}>
                            {(columnKey) => (
                                <TableCell>
                                    {columnKey === "active" ? (
                                        <Chip
                                            className="capitalize"
                                            color={item.active ? "success" : "warning"}
                                            size="md"
                                            variant="flat"
                                        >
                                            {item.active ? "Active" : "Inactive"}
                                        </Chip>
                                    ) : columnKey === "actions" ? (
                                        <Switch
                                            defaultSelected={item.active}
                                            aria-label="Turn on/off working hour."
                                            onValueChange={(active) =>
                                                handleActiveChange(item, active)
                                            }
                                        />
                                    ) : columnKey === "timezone" ? (
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button variant="bordered">{selectedValue}</Button>
                                            </DropdownTrigger>
                                            <DropdownMenu
                                                classNames={{ base: "max-h-96 overflow-y-scroll" }}
                                                items={SUPPORTED_TIMEZONES}
                                                selectionMode="single"
                                                disallowEmptySelection
                                                selectedKeys={selectedTimezone}
                                                onSelectionChange={setSelectedTimezone}
                                            >
                                                {(tz) => (
                                                    <DropdownItem key={tz.value} color="default">
                                                        {tz.value}
                                                    </DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    ) : columnKey === "start" ? (
                                        <input
                                            type="time"
                                            defaultValue={item.start}
                                            onChange={(e) =>
                                                handleTimeChange(item, e.target.value, true)
                                            }
                                        />
                                    ) : columnKey === "end" ? (
                                        <input
                                            type="time"
                                            defaultValue={item.end}
                                            onChange={(e) =>
                                                handleTimeChange(item, e.target.value, false)
                                            }
                                        />
                                    ) : (
                                        getKeyValue(item, columnKey)
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <div className="flex flex-row gap-x-4 justify-end">
                {onDelete && (
                    <Button
                        size="md"
                        variant="solid"
                        className="bg-red-500 text-white w-36 self-end"
                        onClick={onDelete}
                    >
                        Delete Override
                    </Button>
                )}
                <Button
                    size="md"
                    variant="solid"
                    className="bg-purple text-white w-36 self-end"
                    onClick={handleSaveButton}
                >
                    Save
                </Button>
            </div>
        </div>
    );
}
