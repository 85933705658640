import { useNavigate, useParams } from "react-router";
import GlobalState from "../../global/GlobalState";
import { ImmutableArray, useHookstate } from "@hookstate/core";
import { useCallback, useRef, useState } from "react";
import FlowBuilder from "../../components/flow/FlowBuilder";
import { SequenceType } from "../../types/model";
import { Button } from "@nextui-org/react";
import Api from "../../global/Api";
import toast from "react-hot-toast";

export default function TemplateDetails() {
    const params = useParams();
    const templateId = params.id as string;
    const navigate = useNavigate();
    const template = useHookstate(GlobalState.templates)
        .get()
        .find((c) => c.templateId === templateId);
    const sequence = template?.sequence || [];

    const [updating, setUpdating] = useState(false);

    // Store the updates to the sequence. No need to re-render the component.
    const updates = useRef<SequenceType[]>([]);

    const updateSequence = useCallback(
        async (sequence: ImmutableArray<SequenceType>) => {
            if (updates.current.length === 0) {
                return;
            }

            if (!templateId) {
                return;
            }

            setUpdating(true);

            const seq: SequenceType[] = sequence.map((s) => {
                const foundUpdate = updates.current.find((u) => u.nodeId === s.nodeId);
                return (
                    foundUpdate ?? {
                        nodeId: s.nodeId,
                        delay: s.delay,
                        taskType: s.taskType,
                        taskData: {
                            note: s.taskData.note,
                            message: s.taskData.message,
                            altMessage: s.taskData.altMessage
                        },
                        neighbors: [...s.neighbors]
                    }
                );
            });

            const response = await Api.templates.updateTemplate(templateId, seq);

            if ("error" in response) {
                toast.error("Error updating the sequence.");
                setUpdating(false);
                return;
            }

            // Update the template in the global state.
            GlobalState.templates.set((templates) => [
                ...templates.map((t) => {
                    if (t.templateId === templateId) {
                        return response.template;
                    }
                    return t;
                })
            ]);

            // Clear the updates.
            updates.current = [];
            setUpdating(false);

            toast.success("Template updated successfully.");
        },
        [templateId]
    );

    if (sequence.length === 0) {
        navigate("/templates");
        return <div>Template not found</div>;
    }

    return (
        <div className="flex-1 p-8 overflow-y-scroll flex flex-col gap-y-4">
            <h1 className="text-2xl font-semibold">{template?.name}</h1>
            <p>Edit your template sequence.</p>

            <FlowBuilder
                sequence={sequence}
                readonly={true}
                onNodeConfigChange={(
                    id: string,
                    _startNode: boolean,
                    _endNode: boolean,
                    delay: number,
                    note: string | null,
                    message: string | null,
                    altMessage: string | null
                ) => {
                    const nodeId = parseInt(id);
                    const foundSequence = sequence.find((s) => s.nodeId === nodeId) as SequenceType;
                    if (!foundSequence) {
                        return;
                    }

                    updates.current = [
                        ...updates.current.filter((u) => u.nodeId !== nodeId),
                        {
                            ...foundSequence,
                            delay,
                            taskData: {
                                ...foundSequence?.taskData,
                                note,
                                message,
                                altMessage
                            }
                        }
                    ];
                }}
                onNodeConfigDelete={() => {}} // in readonly nodes cannot be deleted
            />

            <Button
                color="default"
                className="bg-purple text-white self-end w-36 min-h-[40px]"
                size="md"
                onClick={() => updateSequence(sequence)}
                isLoading={updating}
            >
                Update
            </Button>
        </div>
    );
}
