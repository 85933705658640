import { hookstate } from "@hookstate/core";
import {
    CampaignType,
    LinkedInConfigurationType,
    TemplateType,
    UserBillingType,
    UserHourlyHistoricalActivityType,
    UserIntegrationType,
    UserLeadsType,
    UserLimitsType,
    UserType,
    WorkflowExecutionType,
    WorkingHoursType
} from "../types/model";

type GlobalStateType = {
    onboarding: boolean;
    authToken: string | null;
    user: UserType | null;
    intercomHash: string | null;
    workingHours: WorkingHoursType[];
    limits: UserLimitsType | null;
    billing: UserBillingType | null;
    linkedInConfiguration: LinkedInConfigurationType | null;
    historicalActivity: UserHourlyHistoricalActivityType[];
    campaigns: CampaignType[];
    leads: UserLeadsType[];
    campaignExecutions: Record<string, WorkflowExecutionType[]>;
    campaignExecutionLeads: Record<string, UserLeadsType[]>;
    templates: TemplateType[];
    integrations: UserIntegrationType[];
    pendingInvitationsCount: number;
    uiConfiguration: {
        historicalActivitySelection: "Today" | "Last 7 Days" | "Last 30 Days" | "Lifetime";
    };
    hideOutsideWorkingHoursWarning: boolean;
};

const object: GlobalStateType = {
    onboarding: false,
    authToken: null,
    user: null,
    billing: null,
    intercomHash: null,
    workingHours: [],
    limits: null,
    linkedInConfiguration: null,
    historicalActivity: [],
    campaigns: [],
    leads: [],
    campaignExecutions: {},
    campaignExecutionLeads: {},
    pendingInvitationsCount: 0,
    templates: [],
    integrations: [],
    uiConfiguration: {
        historicalActivitySelection: "Today"
    },
    hideOutsideWorkingHoursWarning: false
};

// Manage global state for all components
const GlobalState = hookstate(object);

export default GlobalState;
