import {
    Button,
    Textarea,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Input,
    Select,
    SelectItem,
    ModalFooter
} from "@nextui-org/react";
import { useState, useCallback, Key } from "react";
import { FaMagic } from "react-icons/fa";
import Api from "../../global/Api";
import toast from "react-hot-toast";

function computeDelay(delay: number, selected: string): number {
    switch (selected) {
        case "seconds":
            return delay;
        case "minutes":
            return delay * 60;
        case "hours":
            return delay * 60 * 60;
        case "days":
            return delay * 60 * 60 * 24;
        default:
            return delay;
    }
}

type FlowNodeConfigProps = {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    nodeType: string;
    note: string | null;
    message: string | null;
    altMessage: string | null;
    delay: number;
    readonly?: boolean;
    onSave: (delay: number, note: string, message: string, altMessage: string) => void;
};

export default function FlowNodeConfig(props: FlowNodeConfigProps) {
    const { isOpen, onOpenChange, nodeType, onSave, readonly } = props;

    const [note, setNote] = useState<string>(props.note ?? "");
    const [message, setMessage] = useState<string>(props.message ?? "");
    const [altMessage, setAltMessage] = useState<string>(props.altMessage ?? "");
    const [delay, setDelay] = useState<number>(props.delay);
    const [selected, setSelected] = useState<string>("seconds");

    const rewriteWithAI = useCallback(async (text: string, note: boolean): Promise<string> => {
        const res = await Api.ai.rewriteWithAI(text, note);
        return res ? res.rewrittenText : text;
    }, []);

    const renderCell = useCallback(
        (nodeType: string, note: string, message: string, altMessage: string) => {
            switch (nodeType) {
                case "connection":
                    return (
                        <>
                            <div className="grid grid-cols-4 justify-start w-full gap-2">
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setNote(note + "##first_name##");
                                    }}
                                >
                                    First Name
                                </Button>
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setNote(note + "##last_name##");
                                    }}
                                >
                                    Last Name
                                </Button>
                                {/* <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setNote(note + "##title##");
                                    }}
                                >
                                    Title
                                </Button> */}
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setNote(note + "##company_name##");
                                    }}
                                >
                                    Company Name
                                </Button>
                                {/* <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setNote(note + "##company_domain##");
                                    }}
                                >
                                    Company Domain
                                </Button> */}
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setNote(note + "##company_linkedin_url##");
                                    }}
                                >
                                    Company LI Url
                                </Button>
                            </div>

                            <p className="text-sm text-slate-800">
                                To learn more about personalization{" "}
                                <a
                                    href="https://help.themagicdrip.com/en/articles/9564173-how-do-i-personalize"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-purple underline"
                                >
                                    click here
                                </a>
                            </p>

                            <div className="flex flex-col justify-start mt-8">
                                <Textarea
                                    label="Connection Note"
                                    labelPlacement="outside"
                                    placeholder="Enter your description"
                                    defaultValue={note}
                                    value={note}
                                    className="max-w-full"
                                    classNames={{
                                        input: "min-h-28"
                                    }}
                                    onValueChange={(value: string) => setNote(value)}
                                    isInvalid={note.length > 300}
                                    errorMessage={
                                        note.length > 300 ? "Note exceeds 300 characters." : ""
                                    }
                                />
                                <Button
                                    isIconOnly
                                    isDisabled={note.length < 5}
                                    onClick={async () => {
                                        const rewrittenNote = await rewriteWithAI(note, true);
                                        setNote(rewrittenNote);
                                    }}
                                    className="bg-transparent self-end"
                                >
                                    <FaMagic />
                                </Button>
                            </div>
                        </>
                    );
                case "message":
                    return (
                        <>
                            <div className="grid grid-cols-4 justify-start w-full gap-2">
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setMessage(message + "##first_name##");
                                    }}
                                >
                                    First Name
                                </Button>
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setMessage(message + "##last_name##");
                                    }}
                                >
                                    Last Name
                                </Button>
                                {/* <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setMessage(message + "##title##");
                                    }}
                                >
                                    Title
                                </Button> */}
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setMessage(message + "##company_name##");
                                    }}
                                >
                                    Company Name
                                </Button>
                                {/* <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setMessage(message + "##company_domain##");
                                    }}
                                >
                                    Company Domain
                                </Button> */}
                                <Button
                                    className="bg-purple text-white"
                                    onClick={() => {
                                        setMessage(message + "##company_linkedin_url##");
                                    }}
                                >
                                    Company LI Url
                                </Button>
                            </div>

                            <p className="text-sm text-slate-800">
                                To learn more about personalization{" "}
                                <a
                                    href="https://help.themagicdrip.com/en/articles/9564173-how-do-i-personalize"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-purple underline"
                                >
                                    click here
                                </a>
                            </p>

                            <div className="grid grid-cols-2 gap-2 mt-8">
                                <div className="flex flex-col justify-start">
                                    <Textarea
                                        isRequired
                                        label="Message"
                                        labelPlacement="outside"
                                        placeholder="Enter your message"
                                        defaultValue={message}
                                        value={message}
                                        classNames={{
                                            input: "min-h-28"
                                        }}
                                        onValueChange={(value: string) => setMessage(value)}
                                    />
                                    <Button
                                        isIconOnly
                                        isDisabled={message.length < 5}
                                        onClick={async () => {
                                            const rewrittenMessage = await rewriteWithAI(
                                                message,
                                                false
                                            );
                                            setMessage(rewrittenMessage);
                                        }}
                                        className="bg-transparent self-end"
                                    >
                                        <FaMagic />
                                    </Button>
                                </div>

                                <div className="flex flex-col justify-start">
                                    <Textarea
                                        isRequired
                                        label="Alternate Message"
                                        labelPlacement="outside"
                                        placeholder="Enter your alternate message"
                                        defaultValue={altMessage}
                                        classNames={{
                                            input: "min-h-28"
                                        }}
                                        onValueChange={(value: string) => setAltMessage(value)}
                                    />
                                    <Button
                                        isIconOnly
                                        isDisabled={altMessage.length < 5}
                                        onClick={async () => {
                                            const rewrittenMessage = await rewriteWithAI(
                                                altMessage,
                                                false
                                            );
                                            setAltMessage(rewrittenMessage);
                                        }}
                                        className="bg-transparent self-end"
                                    >
                                        <FaMagic />
                                    </Button>
                                </div>
                            </div>
                        </>
                    );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const saveButtonHandler = useCallback(
        (
            note: string,
            message: string,
            altMessage: string,
            delay: number,
            selected: string,
            onClose: () => void
        ) => {
            // Check if the note is of valid length
            if (note.length > 300) {
                toast.error("Note exceeds 300 characters.");
                return;
            }

            const delayInSeconds = computeDelay(delay, selected);
            if (delayInSeconds > 30 * 24 * 3600) {
                toast.error("Action cannot be delayed by more than 30 days.");
                return;
            }

            onSave(delayInSeconds, note, message, altMessage);
            onClose();
        },
        [onSave]
    );

    return (
        <Modal
            isOpen={isOpen}
            size="2xl"
            onOpenChange={onOpenChange}
            classNames={{
                closeButton: "hover:bg-black/5 active:bg-black/10"
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            Configure your Action!
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex flex-col justify-start gap-y-4">
                                {renderCell(nodeType, note, message, altMessage)}
                                {
                                    <div className="flex flex-row gap-2">
                                        <Input
                                            isDisabled={readonly}
                                            classNames={{ base: "w-[320px]" }}
                                            type="number"
                                            label="Delay"
                                            placeholder="Enter a delay to be execute after this step."
                                            labelPlacement="outside"
                                            value={`${delay}`}
                                            min={0}
                                            step={1}
                                            onValueChange={(value: string) => {
                                                const delayValue = parseInt(value, 10);
                                                setDelay(delayValue);
                                            }}
                                        />
                                        <Select
                                            isDisabled={readonly}
                                            classNames={{ base: "max-w-[128px]" }}
                                            selectionMode="single"
                                            selectedKeys={[selected]}
                                            onSelectionChange={(selected: Set<Key> | string) => {
                                                if (typeof selected === "string") {
                                                    setSelected(selected);
                                                    return;
                                                }
                                                const value = selected.values().next()
                                                    .value as string;
                                                setSelected(value);
                                            }}
                                        >
                                            <SelectItem key={"seconds"} value={"seconds"}>
                                                Seconds
                                            </SelectItem>
                                            <SelectItem key={"minutes"} value={"minutes"}>
                                                Minutes
                                            </SelectItem>
                                            <SelectItem key={"hours"} value={"hours"}>
                                                Hours
                                            </SelectItem>
                                            <SelectItem key={"days"} value={"days"}>
                                                Days
                                            </SelectItem>
                                        </Select>
                                    </div>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onPress={() =>
                                    saveButtonHandler(
                                        note,
                                        message,
                                        altMessage,
                                        delay,
                                        selected,
                                        onClose
                                    )
                                }
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}
