import { useMatch, useNavigate } from "react-router";
import { UserButton } from "@clerk/clerk-react";
import { IoIosLogOut } from "react-icons/io";
import { IconContext } from "react-icons";
import { IconButton } from "../components/IconButton";
import { NavBarButton } from "../components/NavBarButton";
import { useState } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { GoWorkflow } from "react-icons/go";
import { IoSettingsOutline } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import ShortLogo from "../assets/short_logo.svg";
import LongLogo from "../assets/long_logo.svg";
import { RiExpandLeftLine } from "react-icons/ri";
import { Button, Divider } from "@nextui-org/react";
import { RiExpandRightLine } from "react-icons/ri";
import { GrIntegration } from "react-icons/gr";
import { HiOutlineTemplate } from "react-icons/hi";
import { SlDocs } from "react-icons/sl";
import { TbHelpOctagon } from "react-icons/tb";

type LeftNavProps = {
    logoutHandler: () => void;
};

export default function LeftNav({ logoutHandler }: LeftNavProps) {
    const navigate = useNavigate();
    const [shrink, setShrink] = useState(false);

    const homePath = useMatch("/");
    const campaignsPath = useMatch("/campaigns");
    const campaignsIdPath = useMatch("/campaigns/:id");
    const campaignsCreatePath = useMatch("/campaigns/create");
    const leadsPath = useMatch("/leads");
    const templatesPath = useMatch("/templates");
    const templatesIdPath = useMatch("/templates/:id");
    const templatesCreatePath = useMatch("/templates/create");
    const integrationsPath = useMatch("/integrations");
    const integrationsCallbackPath = useMatch("/integration/slack/callback");
    const settingsPath = useMatch("/settings");
    const paymentStripeCallbackPath = useMatch("/payment/stripe/callback");

    const handleNavigate = (path: string) => {
        return function () {
            navigate(path);
        };
    };

    return (
        <IconContext.Provider value={{ size: "1.25em" }}>
            {!shrink ? (
                <div className="bg-[#F6F8FA] w-52 flex-none flex flex-col pt-2 transition-[width] duration-400">
                    <div className="w-full">
                        <img src={LongLogo} alt="Logo" className="w-fit h-[60px] bg-transparent" />
                    </div>

                    <Divider orientation="horizontal" className="w-full bg-slate-300 mb-4" />

                    <div className="flex-1 *:w-full">
                        <NavBarButton
                            active={Boolean(homePath)}
                            alt="Home"
                            img={<IoHomeOutline className="mr-4" />}
                            text="Home"
                            onClick={handleNavigate("/")}
                        />
                        <NavBarButton
                            active={
                                Boolean(campaignsPath) ||
                                Boolean(campaignsIdPath) ||
                                Boolean(campaignsCreatePath)
                            }
                            alt="Campaigns"
                            img={<GoWorkflow className="mr-4" />}
                            text="Campaigns"
                            onClick={handleNavigate("/campaigns")}
                        />
                        <NavBarButton
                            active={Boolean(leadsPath)}
                            alt="Leads"
                            img={<BsPeopleFill className="mr-4" />}
                            text="Leads"
                            onClick={handleNavigate("/leads")}
                        />
                        <NavBarButton
                            active={
                                Boolean(templatesPath) ||
                                Boolean(templatesIdPath) ||
                                Boolean(templatesCreatePath)
                            }
                            alt="Templates"
                            text="Templates"
                            img={<HiOutlineTemplate className="mr-4" />}
                            onClick={handleNavigate("/templates")}
                        />
                        <NavBarButton
                            active={Boolean(integrationsPath) || Boolean(integrationsCallbackPath)}
                            alt="Integrations"
                            img={<GrIntegration className="mr-4" />}
                            text="Integrations"
                            onClick={handleNavigate("/integrations")}
                        />
                        <NavBarButton
                            active={Boolean(settingsPath) || Boolean(paymentStripeCallbackPath)}
                            alt="Settings"
                            img={<IoSettingsOutline className="mr-4" />}
                            text="Settings"
                            onClick={handleNavigate("/settings")}
                        />
                    </div>

                    <div className="flex flex-col">
                        <div className="self-end mb-4">
                            <Button
                                isIconOnly
                                className="bg-transparent border-y-2 border-l-2 border-slate-200 rounded-none hover:bg-purple-600 hover:text-white hover:border-white"
                                onClick={() => setShrink(true)}
                            >
                                <RiExpandLeftLine />
                            </Button>
                        </div>
                    </div>

                    <Divider orientation="horizontal" className="w-full bg-slate-300" />

                    <div className="flex flex-col py-2">
                        <p className="text-xs text-slate-500 font-semibold px-4 mb-2">
                            QUICK LINKS
                        </p>

                        <Button
                            className="rounded-none flex flex-row items-center justify-start px-6 w-full bg-transparent text-slate-500 hover:bg-slate-200 hover:text-slate-700"
                            startContent={<SlDocs className="mr-4" />}
                            onClick={() => window.open("https://docs.themagicdrip.com", "_blank")}
                        >
                            Docs
                        </Button>

                        <Button
                            className="rounded-none flex flex-row items-center justify-start px-6 w-full bg-transparent text-slate-500 hover:bg-slate-200 hover:text-slate-700"
                            startContent={<TbHelpOctagon className="mr-4" />}
                            onClick={() => window.open("https://help.themagicdrip.com", "_blank")}
                        >
                            Help Center
                        </Button>
                    </div>

                    <Divider orientation="horizontal" className="w-full bg-slate-300" />

                    <div className="flex flex-row justify-between items-center px-6 py-2">
                        <UserButton />
                        <IconButton alt="Logout" img={<IoIosLogOut />} onClick={logoutHandler} />
                    </div>
                </div>
            ) : (
                <div className="bg-[#F6F8FA] w-20 flex-none flex flex-col pt-2 transition-[width] duration-400">
                    <div className="w-full items-center justify-center flex">
                        <img
                            src={ShortLogo}
                            alt="Logo"
                            className="w-[60px] h-[60px] bg-transparent"
                        />
                    </div>

                    <Divider orientation="horizontal" className="w-full bg-slate-300 mb-4" />

                    <div className="flex-1 *:w-full">
                        <NavBarButton
                            active={Boolean(homePath)}
                            img={<IoHomeOutline />}
                            isIconOnly
                            onClick={handleNavigate("/")}
                        />
                        <NavBarButton
                            active={
                                Boolean(campaignsPath) ||
                                Boolean(campaignsIdPath) ||
                                Boolean(campaignsCreatePath)
                            }
                            img={<GoWorkflow />}
                            isIconOnly
                            onClick={handleNavigate("/campaigns")}
                        />
                        <NavBarButton
                            active={Boolean(leadsPath)}
                            img={<BsPeopleFill />}
                            isIconOnly
                            onClick={handleNavigate("/leads")}
                        />
                        <NavBarButton
                            active={
                                Boolean(templatesPath) ||
                                Boolean(templatesIdPath) ||
                                Boolean(templatesCreatePath)
                            }
                            img={<HiOutlineTemplate />}
                            isIconOnly
                            onClick={handleNavigate("/templates")}
                        />
                        <NavBarButton
                            active={Boolean(integrationsPath) || Boolean(integrationsCallbackPath)}
                            isIconOnly
                            img={<GrIntegration />}
                            onClick={handleNavigate("/integrations")}
                        />
                        <NavBarButton
                            active={Boolean(settingsPath) || Boolean(paymentStripeCallbackPath)}
                            isIconOnly
                            img={<IoSettingsOutline />}
                            onClick={handleNavigate("/settings")}
                        />
                    </div>

                    <div className="flex flex-col items-center justify-center">
                        <Button
                            isIconOnly
                            className="w-full mb-4 bg-transparent rounded-none hover:bg-purple-600 hover:text-white hover:border-white"
                            onClick={() => setShrink(false)}
                        >
                            <RiExpandRightLine />
                        </Button>
                    </div>

                    <Divider orientation="horizontal" className="w-full bg-slate-300" />

                    <div className="flex flex-col py-2">
                        <Button
                            className="rounded-none items-center justify-center w-full bg-transparent text-slate-500 hover:bg-slate-200 hover:text-slate-700"
                            onClick={() => window.open("https://docs.themagicdrip.com", "_blank")}
                            isIconOnly
                        >
                            <SlDocs />
                        </Button>

                        <Button
                            className="rounded-none items-center justify-center w-full bg-transparent text-slate-500 hover:bg-slate-200 hover:text-slate-700"
                            onClick={() => window.open("https://help.themagicdrip.com", "_blank")}
                            isIconOnly
                        >
                            <TbHelpOctagon />
                        </Button>
                    </div>

                    <Divider orientation="horizontal" className="w-full bg-slate-300" />

                    <div className="flex flex-col items-center gap-y-4 py-2">
                        <UserButton />
                    </div>
                </div>
            )}
        </IconContext.Provider>
    );
}
