import { ImmutableArray, useHookstate } from "@hookstate/core";
import { SequenceType } from "../../../types/model";
import { useCallback, useRef, useState } from "react";
import Api from "../../../global/Api";
import GlobalState from "../../../global/GlobalState";
import FlowBuilder from "../../../components/flow/FlowBuilder";
import { Button } from "@nextui-org/react";
import toast from "react-hot-toast";

type CampaignSequenceProps = {
    campaignId: string;
};

export default function CampaignSequence(props: CampaignSequenceProps) {
    const { campaignId } = props;

    const sequence =
        useHookstate(GlobalState.campaigns)
            .get()
            .find((c) => c.workflowId === campaignId)?.sequence || [];

    const [updating, setUpdating] = useState(false);

    // Store the updates to the sequence. No need to re-render the component.
    const updates = useRef<SequenceType[]>([]);

    const updateSequence = useCallback(
        async (sequence: ImmutableArray<SequenceType>) => {
            if (updates.current.length === 0) {
                return;
            }

            setUpdating(true);

            const seq = sequence.map((s) => {
                const foundUpdate = updates.current.find((u) => u.nodeId === s.nodeId);
                return (
                    foundUpdate ?? {
                        nodeId: s.nodeId,
                        delay: s.delay,
                        taskType: s.taskType,
                        taskData: {
                            note: s.taskData.note,
                            message: s.taskData.message,
                            altMessage: s.taskData.altMessage
                        },
                        neighbors: [...s.neighbors]
                    }
                );
            });

            const response = await Api.campaign.updateCampaign(campaignId, undefined, seq);
            if ("error" in response) {
                toast.error("Error updating the sequence.");
                setUpdating(false);
                return;
            }

            // Update the campaign in the global state.
            const updatedCampaign = response.campaign;
            GlobalState.campaigns.set((prevState) =>
                prevState.map((c) => (c.workflowId === campaignId ? updatedCampaign : c))
            );

            // Clear the updates.
            updates.current = [];
            setUpdating(false);

            toast.success("Campaign updated successfully.");
        },
        [campaignId]
    );

    return (
        <div className="h-[600px] flex flex-col justify-start gap-y-4">
            <p>Only changes to a particular action's configurations is allowed.</p>
            <FlowBuilder
                sequence={sequence}
                readonly={true}
                onNodeConfigChange={(
                    id: string,
                    _startNode: boolean,
                    _endNode: boolean,
                    delay: number,
                    note: string | null,
                    message: string | null,
                    altMessage: string | null
                ) => {
                    const nodeId = parseInt(id);
                    const foundSequence = sequence.find((s) => s.nodeId === nodeId) as SequenceType;
                    if (!foundSequence) {
                        return;
                    }

                    updates.current = [
                        ...updates.current.filter((u) => u.nodeId !== nodeId),
                        {
                            ...foundSequence,
                            delay,
                            taskData: {
                                ...foundSequence?.taskData,
                                note,
                                message,
                                altMessage
                            }
                        }
                    ];
                }}
                onNodeConfigDelete={() => {}} // in readonly nodes cannot be deleted
            />
            <Button
                isLoading={updating}
                size="lg"
                variant="solid"
                className="bg-purple text-white w-36 self-end min-h-[40px]"
                onClick={() => updateSequence(sequence)}
            >
                Update
            </Button>
        </div>
    );
}
