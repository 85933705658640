import { Key, useMemo, useState } from "react";
import useOutsideClick from "../../helpers/OutsideClick";
import { Button, Divider, Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { CiFilter } from "react-icons/ci";

type LeadsFilterProps = {
    // Handle the filter change.
    onFilter: (
        type: "LINKEDIN_PUBLIC_ID" | "CURRENT_TASK" | "STATUS",
        value: { key: string; value: string } | undefined
    ) => void;

    // LinkedIn Public Ids to display in the filter.
    linkedInPublicIds: Record<string, string>;
    selectedLinkedInPublicIdKey: { key: string; value: string } | undefined;

    endContent?: React.ReactNode;
};

export default function LeadsFilter(props: LeadsFilterProps) {
    const { linkedInPublicIds, onFilter } = props;

    // States
    const [show, setShow] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selection, setSelection] = useState<{ key: string; value: string } | undefined>(
        props.selectedLinkedInPublicIdKey
    );

    const workflowIdsToLinkedinIds = useMemo(
        () =>
            Object.keys(linkedInPublicIds).map((key) => ({
                key,
                value: linkedInPublicIds[key]
            })),
        [linkedInPublicIds]
    );

    const tabClassName: string[] = Array(3).fill("rounded-none bg-purple-200 hover:bg-purple");
    tabClassName[selectedTab] += " bg-purple text-white";

    // Close the filter when clicking outside of it.
    const ref = useOutsideClick(() => setShow(false));

    return (
        <div ref={ref} className="relative self-end">
            <Button
                className="bg-purple text-white w-48 h-12"
                startContent={<CiFilter />}
                variant="solid"
                onClick={() => {
                    setShow((prevState) => !prevState);
                }}
                endContent={props.endContent}
            >
                Filters
            </Button>

            {show && (
                <div className="w-[500px] h-72 absolute top-14 -left-[308px] bg-white z-10 rounded-lg shadow animate-appearance-in border border-slate-200">
                    <div className="flex flex-row w-auto h-full">
                        <div className="flex flex-col justify-center">
                            <Button className={tabClassName[0]} onClick={() => setSelectedTab(0)}>
                                LinkedIn Public Id
                            </Button>
                            {/* <Button className={tabClassName[1]} onClick={() => setSelectedTab(1)}>
                                Current Task
                            </Button>
                            <Button className={tabClassName[2]} onClick={() => setSelectedTab(2)}>
                                Status
                            </Button> */}
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-y-2 flex-1 p-4">
                            {selectedTab === 0 && (
                                <Autocomplete
                                    defaultItems={workflowIdsToLinkedinIds}
                                    label="LinkedIn Public Id"
                                    placeholder="Search for a LinkedIn Account"
                                    defaultSelectedKey={selection?.key}
                                    defaultInputValue={selection?.value}
                                    onSelectionChange={(item: Key) => {
                                        const key = item as string;
                                        setSelection({ key, value: linkedInPublicIds[key] });
                                        onFilter(
                                            "LINKEDIN_PUBLIC_ID",
                                            key ? { key, value: linkedInPublicIds[key] } : undefined
                                        );
                                    }}
                                >
                                    {(account) => (
                                        <AutocompleteItem key={account.key}>
                                            {account.value}
                                        </AutocompleteItem>
                                    )}
                                </Autocomplete>
                            )}
                            {/* {selectedTab === 1 && (
                                <Autocomplete
                                    defaultItems={allTasks}
                                    label="Task Type"
                                    placeholder="Search for the current task"
                                    defaultSelectedKey={selectedCurrentTask}
                                    defaultInputValue={
                                        displayNameFromTaskType(selectedCurrentTask ?? null) ??
                                        undefined
                                    }
                                    onSelectionChange={(item: Key) => {
                                        const key = item as TaskType;
                                        setSelectedCurrentTask(key);
                                        onFilter(
                                            "CURRENT_TASK",
                                            key
                                                ? {
                                                      key,
                                                      value: displayNameFromTaskType(key ?? null)
                                                  }
                                                : undefined
                                        );
                                    }}
                                >
                                    {(account) => (
                                        <AutocompleteItem key={account.key}>
                                            {account.value}
                                        </AutocompleteItem>
                                    )}
                                </Autocomplete>
                            )}
                            {selectedTab === 2 && (
                                <Autocomplete
                                    defaultItems={allStatus}
                                    label="Status Type"
                                    placeholder="Search for a specific status"
                                    defaultSelectedKey={selectedStatus}
                                    defaultInputValue={
                                        selectedStatus
                                            ? allStatus.find((s) => s.key === selectedStatus)?.value
                                            : undefined
                                    }
                                    onSelectionChange={(item: Key) => {
                                        const key = item as WorkflowExecutionStatusType;
                                        setSelectedStatus(key);
                                        onFilter(
                                            "STATUS",
                                            key ? allStatus.find((s) => s.key === key) : undefined
                                        );
                                    }}
                                >
                                    {(account) => (
                                        <AutocompleteItem key={account.key}>
                                            {account.value}
                                        </AutocompleteItem>
                                    )}
                                </Autocomplete>
                            )} */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
