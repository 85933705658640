import { useHookstate } from "@hookstate/core";
import { Key, useCallback, useEffect, useMemo, useState } from "react";
import GlobalState from "../../../global/GlobalState";
import Api from "../../../global/Api";
import {
    TaskType,
    UserLeadsType,
    WorkflowExecutionStatusType,
    WorkflowExecutionTaskType,
    WorkflowExecutionType,
    WorkflowLeadStatusType
} from "../../../types/model";
import {
    Autocomplete,
    AutocompleteItem,
    Button,
    Chip,
    Divider,
    Pagination,
    Selection,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tooltip,
    getKeyValue
} from "@nextui-org/react";
import { CiFilter } from "react-icons/ci";
import useOutsideClick from "../../../helpers/OutsideClick";
import { RetrieveCampaignExecutionsResponseType } from "../../../types/api/campaign";
import { MdDeleteOutline } from "react-icons/md";
import toast from "react-hot-toast";
import Sidebar from "../../../components/Sidebar";
import { IoIosInformationCircle } from "react-icons/io";
import LinkedInLogo from "../../../assets/li_logo.png";

const CAMPAIGN_EXECUTION_COLUMNS = [
    { key: "linkedInPublicId", label: "LinkedIn Public Id" },
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
    { key: "company", label: "Company Name" },
    { key: "leadStatus", label: "Lead Status" },
    { key: "status", label: "Overall Status" },
    { key: "task", label: "Current Action" },
    { key: "active", label: "Active" }
];

type CampaignLeadsProps = {
    campaignId: string;
};

type CampaignExecutionLeadsType = {
    key: string;
    linkedInPublicId: string;
    firstName: string | null;
    lastName: string | null;
    title: string | null;
    company: string | null;
    companyDomain: string | null;
    companyLinkedInUrl: string | null;
    customVariables: Record<string, string> | null;
    leadStatus: WorkflowLeadStatusType;
    status: WorkflowExecutionStatusType;
    currentTask: WorkflowExecutionTaskType | null;
    active: boolean;
    waitUntilConnectionRequestAccepted: boolean;
};

function displayNameFromTaskType(taskType: TaskType | null): string {
    switch (taskType) {
        case TaskType.LINKEDIN_CONNECT_WITH_NOTE:
            return "Connection Request";
        case TaskType.LINKEDIN_MESSAGE:
            return "Message";
        case TaskType.LINKEDIN_FOLLOW:
            return "Follow";
        case TaskType.LINKEDIN_UNFOLLOW:
            return "Unfollow";
        case TaskType.LINKEDIN_LIKE:
            return "Like";
        case TaskType.LINKEDIN_VIEW_PROFILE:
            return "View Profile";
        case TaskType.LINKEDIN_IN_MAIL:
            return "InMail";
        case TaskType.LINKEDIN_WITHDRAW:
            return "Withdraw";
        case TaskType.LINKEDIN_FETCH_PROFILE:
            return "Fetch Profile";
        default:
            return "";
    }
}

type CampaignLeadsFilterProps = {
    // Handle the filter change.
    onFilter: (
        type: "LINKEDIN_PUBLIC_ID" | "CURRENT_TASK" | "STATUS",
        value: { key: string; value: string } | undefined
    ) => void;

    // LinkedIn Public Ids to display in the filter.
    linkedInPublicIds: Record<string, string>;
    selectedLinkedInPublicIdKey: { key: string; value: string } | undefined;

    // The selected current task.
    selectedCurrentTask: TaskType | undefined;

    // The selected status.
    selectedStatus: WorkflowExecutionStatusType | undefined;

    endContent?: React.ReactNode;
};

function CampaignLeadsFilter(props: CampaignLeadsFilterProps) {
    const { linkedInPublicIds, onFilter } = props;

    // States
    const [show, setShow] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selection, setSelection] = useState<{ key: string; value: string } | undefined>(
        props.selectedLinkedInPublicIdKey
    );
    const [selectedCurrentTask, setSelectedCurrentTask] = useState<TaskType | undefined>(
        props.selectedCurrentTask
    );
    const [selectedStatus, setSelectedStatus] = useState<WorkflowExecutionStatusType | undefined>(
        props.selectedStatus
    );

    const workflowIdsToLinkedinIds = useMemo(
        () =>
            Object.keys(linkedInPublicIds).map((key) => ({
                key,
                value: linkedInPublicIds[key]
            })),
        [linkedInPublicIds]
    );

    const allTasks = Object.values(TaskType).map((task) => ({
        key: task,
        value: displayNameFromTaskType(task)
    }));

    const allStatus = Object.values(WorkflowExecutionStatusType).map((status) => ({
        key: status,
        value: (`${status}`[0] + `${status}`.toLowerCase().slice(1)).replace("_", " ")
    }));

    const tabClassName: string[] = Array(3).fill("rounded-none bg-purple-200 hover:bg-purple");
    tabClassName[selectedTab] += " bg-purple text-white";

    // Close the filter when clicking outside of it.
    const ref = useOutsideClick(() => setShow(false));

    return (
        <div ref={ref} className="relative">
            <Button
                className="bg-purple text-white w-48 h-12"
                startContent={<CiFilter />}
                variant="solid"
                onClick={() => {
                    setShow((prevState) => !prevState);
                }}
                endContent={props.endContent}
            >
                Filters
            </Button>

            {show && (
                <div className="w-[500px] h-72 absolute top-14 -left-[308px] bg-white z-10 rounded-lg shadow animate-appearance-in border border-slate-200">
                    <div className="flex flex-row w-auto h-full">
                        <div className="flex flex-col justify-center">
                            <Button className={tabClassName[0]} onClick={() => setSelectedTab(0)}>
                                LinkedIn Public Id
                            </Button>
                            <Button className={tabClassName[1]} onClick={() => setSelectedTab(1)}>
                                Current Task
                            </Button>
                            <Button className={tabClassName[2]} onClick={() => setSelectedTab(2)}>
                                Status
                            </Button>
                        </div>
                        <Divider orientation="vertical" />
                        <div className="flex flex-col gap-y-2 flex-1 p-4">
                            {selectedTab === 0 && (
                                <Autocomplete
                                    defaultItems={workflowIdsToLinkedinIds}
                                    label="LinkedIn Public Id"
                                    placeholder="Search for a LinkedIn Account"
                                    defaultSelectedKey={selection?.key}
                                    defaultInputValue={selection?.value}
                                    onSelectionChange={(item: Key) => {
                                        const key = item as string;
                                        setSelection({ key, value: linkedInPublicIds[key] });
                                        onFilter(
                                            "LINKEDIN_PUBLIC_ID",
                                            key ? { key, value: linkedInPublicIds[key] } : undefined
                                        );
                                    }}
                                >
                                    {(account) => (
                                        <AutocompleteItem key={account.key}>
                                            {account.value}
                                        </AutocompleteItem>
                                    )}
                                </Autocomplete>
                            )}
                            {selectedTab === 1 && (
                                <Autocomplete
                                    defaultItems={allTasks}
                                    label="Task Type"
                                    placeholder="Search for the current task"
                                    defaultSelectedKey={selectedCurrentTask}
                                    defaultInputValue={
                                        displayNameFromTaskType(selectedCurrentTask ?? null) ??
                                        undefined
                                    }
                                    onSelectionChange={(item: Key) => {
                                        const key = item as TaskType;
                                        setSelectedCurrentTask(key);
                                        onFilter(
                                            "CURRENT_TASK",
                                            key
                                                ? {
                                                      key,
                                                      value: displayNameFromTaskType(key ?? null)
                                                  }
                                                : undefined
                                        );
                                    }}
                                >
                                    {(account) => (
                                        <AutocompleteItem key={account.key}>
                                            {account.value}
                                        </AutocompleteItem>
                                    )}
                                </Autocomplete>
                            )}
                            {selectedTab === 2 && (
                                <Autocomplete
                                    defaultItems={allStatus}
                                    label="Status Type"
                                    placeholder="Search for a specific status"
                                    defaultSelectedKey={selectedStatus}
                                    defaultInputValue={
                                        selectedStatus
                                            ? allStatus.find((s) => s.key === selectedStatus)?.value
                                            : undefined
                                    }
                                    onSelectionChange={(item: Key) => {
                                        const key = item as WorkflowExecutionStatusType;
                                        setSelectedStatus(key);
                                        onFilter(
                                            "STATUS",
                                            key ? allStatus.find((s) => s.key === key) : undefined
                                        );
                                    }}
                                >
                                    {(account) => (
                                        <AutocompleteItem key={account.key}>
                                            {account.value}
                                        </AutocompleteItem>
                                    )}
                                </Autocomplete>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default function CampaignLeads(props: CampaignLeadsProps) {
    const { campaignId } = props;
    const pageSize = 25;

    const [page, setPage] = useState(1);
    const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([])); // Table selection

    // Filter by linkedin handle
    const [selectedLinkedInPublicId, setSelectedLinkedInPublicId] = useState<
        { key: string; value: string } | undefined
    >(undefined);

    // Filter by current task
    const [selectedCurrentTask, setSelectedCurrentTask] = useState<TaskType | undefined>(undefined);
    // Filter by status
    const [selectedStatus, setSelectedStatus] = useState<WorkflowExecutionStatusType | undefined>(
        undefined
    );

    const campaignExecutionsState = useHookstate(GlobalState.campaignExecutions);

    const executions = useMemo(
        () => campaignExecutionsState.get()[campaignId] ?? [],
        [campaignExecutionsState, campaignId]
    );

    const retrieveExecutions = useCallback((campaignId: string) => {
        const executions: WorkflowExecutionType[] = [];
        const leads: UserLeadsType[] = [];

        const fetchIncrementally = async () => {
            let cursorId = undefined;
            console.log("Fetching campaign executions incrementally...");

            do {
                const response: RetrieveCampaignExecutionsResponseType | null =
                    await Api.campaign.retrieveCampaignExecutions(campaignId, cursorId);

                if (response && response.executions.length > 0) {
                    // Update the global state incrementally
                    executions.push(...response.executions);
                    leads.push(...response.leads);

                    // Update the global state
                    GlobalState.campaignExecutions[campaignId].set(executions);
                    GlobalState.campaignExecutionLeads[campaignId].set(leads);

                    cursorId = response.cursorId;
                } else {
                    break;
                }
            } while (true);
        };

        // Start the fetching process without awaiting it
        fetchIncrementally();
    }, []);

    useEffect(() => {
        if (executions.length === 0) {
            retrieveExecutions(campaignId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateCampaignExecutionById = useCallback(async (executionId: string) => {
        const response = await Api.campaign.retrieveCampaignExecutionById(executionId);
        if ("error" in response) {
            return;
        }

        // Update the campaign execution in the global state.
        const updatedExecution = response.execution;

        campaignExecutionsState[campaignId].set((prevState) => [
            ...prevState.map((e) => {
                if (e.id === updatedExecution.id) {
                    return updatedExecution;
                }
                return e;
            })
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateExecutionStatus = useCallback(async (executionId: string, active: boolean) => {
        if (active) {
            await Api.campaign.markExecutionAsActive(executionId);
        } else {
            await Api.campaign.markExecutionAsInActive(executionId);
        }
        await updateCampaignExecutionById(executionId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allRows: CampaignExecutionLeadsType[] = useMemo(() => {
        return executions.map((execution) => {
            return {
                key: execution.id,
                linkedInPublicId: execution.linkedInPublicId,
                waitUntilConnectionRequestAccepted: execution.waitUntilConnectionRequestAccepted,
                firstName: execution.firstName,
                lastName: execution.lastName,
                title: execution.title,
                company: execution.company,
                companyDomain: execution.companyDomain,
                companyLinkedInUrl: execution.companyLinkedInUrl,
                customVariables: execution.customVariables,
                leadStatus: execution.leadStatus,
                status: execution.status,
                active: execution.active,
                currentTask: execution.currentTask
            };
        });
    }, [executions]);

    // Map workflow execution ids to linkedIn public ids.
    const linkedInPublicIds: Record<string, string> = useMemo(
        () =>
            allRows.reduce(
                (acc, curr) => {
                    acc[curr.key] = curr.linkedInPublicId;
                    return acc;
                },
                {} as Record<string, string>
            ),
        [allRows]
    );

    let filteredRows: CampaignExecutionLeadsType[] = allRows;

    // If we have a selected LinkedIn Public Id, filter the rows.
    if (selectedLinkedInPublicId) {
        filteredRows = filteredRows.filter(
            (row) => row.linkedInPublicId === selectedLinkedInPublicId.value
        );
    }

    if (selectedCurrentTask) {
        filteredRows = filteredRows.filter(
            (row) => row.currentTask?.taskType === selectedCurrentTask
        );
    }

    if (selectedStatus) {
        filteredRows = filteredRows.filter((row) => row.status === selectedStatus);
    }

    const tableRows = filteredRows.length;
    filteredRows = filteredRows.slice(
        (page - 1) * pageSize,
        Math.min(page * pageSize, filteredRows.length)
    );

    const handleFilter = useCallback(
        (type: string, value: { key: string; value: string } | undefined) => {
            if (type === "LINKEDIN_PUBLIC_ID") {
                setSelectedLinkedInPublicId(value);
            } else if (type === "CURRENT_TASK") {
                setSelectedCurrentTask(value?.key as TaskType);
            } else if (type === "STATUS") {
                setSelectedStatus(value?.key as WorkflowExecutionStatusType);
            }
        },
        []
    );

    // Delete the selected workflow executions.
    const deleteWorkflowExecutions = useCallback(
        async (selectedKeys: Selection) => {
            // Store the deletion execution ids.
            let numberOfDeletedExecutions: Array<string> = [];
            const allDeletions =
                selectedKeys === "all" ? allRows.map((row) => row.key) : Array.from(selectedKeys);
            const batchSize = 10;

            const toastId = toast.loading(`Deleting ${allDeletions.length} leads`);

            for (let i = 0; i < allDeletions.length; i += batchSize) {
                const batch = allDeletions.slice(i, i + batchSize);
                const batchPromises = batch.map(async (key) => {
                    const executionId = key as string;
                    const response = await Api.campaign.deleteCampaignExecution(executionId);
                    return response.success;
                });

                const batchResults = await Promise.all(batchPromises);

                // Store the deletion execution ids.
                numberOfDeletedExecutions.push(
                    ...batchResults
                        .map((success: boolean, idx: number) => ({
                            success,
                            id: batch[idx] as string
                        }))
                        .filter((item) => item.success)
                        .map((item) => item.id)
                );
            }

            toast.success(`Deleted ${numberOfDeletedExecutions.length} leads`, { id: toastId });

            // update the global state
            campaignExecutionsState[campaignId].set((prevState) =>
                prevState.filter((e) => !numberOfDeletedExecutions.includes(e.id))
            );

            setSelectedKeys(new Set([]));
        },
        [allRows, campaignExecutionsState, campaignId]
    );

    const [sidebarWorkflow, setSidebarWorkflow] = useState<CampaignExecutionLeadsType | null>(null);

    return (
        <div className="flex flex-col items-end gap-y-4">
            <div className="flex flex-row gap-x-4 items-center">
                {
                    // Show the delete button only if there are selected rows.
                    <Button
                        isDisabled={!(selectedKeys === "all" || selectedKeys.size > 0)}
                        className="bg-red-500 text-white h-12 w-48"
                        startContent={<MdDeleteOutline />}
                        onClick={() => deleteWorkflowExecutions(selectedKeys)}
                    >
                        Delete {selectedKeys === "all" ? "all" : `(${selectedKeys.size})`} leads
                    </Button>
                }

                <CampaignLeadsFilter
                    onFilter={handleFilter}
                    linkedInPublicIds={linkedInPublicIds}
                    selectedLinkedInPublicIdKey={selectedLinkedInPublicId}
                    selectedCurrentTask={selectedCurrentTask}
                    selectedStatus={selectedStatus}
                    endContent={<p className="border-white text-sm">({tableRows})</p>}
                />
            </div>
            <Table
                aria-label="Table containing all the user's campaign's leads."
                bottomContent={
                    <div className="flex w-full justify-end">
                        <Pagination
                            classNames={{
                                cursor: "bg-purple"
                            }}
                            showControls={true}
                            variant="flat"
                            radius="full"
                            total={Math.ceil(tableRows / pageSize)}
                            page={page}
                            initialPage={page}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                }
                selectionMode="multiple"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
            >
                <TableHeader columns={CAMPAIGN_EXECUTION_COLUMNS}>
                    {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                </TableHeader>
                <TableBody items={filteredRows} emptyContent={"No rows to display."}>
                    {(item) => (
                        <TableRow key={item.key}>
                            {(columnKey) => (
                                <TableCell>
                                    {columnKey === "active" ? (
                                        <Switch
                                            defaultSelected={item.active}
                                            aria-label="Turn on/off lead execution"
                                            onValueChange={(isSelected: boolean) => {
                                                updateExecutionStatus(item.key, isSelected);
                                            }}
                                        />
                                    ) : columnKey === "leadStatus" ? (
                                        <Chip
                                            className="capitalize"
                                            color={
                                                [
                                                    WorkflowLeadStatusType.REJECTED,
                                                    WorkflowLeadStatusType.FETCHING,
                                                    WorkflowLeadStatusType.SCHEDULED
                                                ].includes(item.leadStatus)
                                                    ? "warning"
                                                    : item.leadStatus ===
                                                        WorkflowLeadStatusType.FETCH_FAILED
                                                      ? "danger"
                                                      : "success"
                                            }
                                            size="sm"
                                            variant="flat"
                                        >
                                            {item.leadStatus === "REJECTED"
                                                ? "Excluded"
                                                : item.leadStatus === "FETCH_FAILED"
                                                  ? "Fetch Failed"
                                                  : item.leadStatus === "FETCH_SUCCESS"
                                                    ? "Fetch Success"
                                                    : `${item.leadStatus}`[0] +
                                                      `${item.leadStatus}`.toLowerCase().slice(1)}
                                        </Chip>
                                    ) : columnKey === "status" ? (
                                        <Chip
                                            className="capitalize"
                                            color={
                                                item.status === WorkflowExecutionStatusType.FAILED
                                                    ? "danger"
                                                    : item.status ===
                                                        WorkflowExecutionStatusType.IN_PROGRESS
                                                      ? "warning"
                                                      : "success"
                                            }
                                            size="sm"
                                            variant="flat"
                                        >
                                            {`${item.status}`[0] +
                                                `${item.status}`.toLowerCase().slice(1)}
                                        </Chip>
                                    ) : columnKey === "linkedInPublicId" ? (
                                        <Button
                                            className="cursor-pointer m-0 p-0 min-w-0 bg-transparent h-fit hover:text-blue-600 underline"
                                            onClick={() => setSidebarWorkflow(item)}
                                        >
                                            {item.linkedInPublicId}
                                        </Button>
                                    ) : columnKey === "task" ? (
                                        displayNameFromTaskType(item.currentTask?.taskType ?? null)
                                    ) : (
                                        getKeyValue(item, columnKey)
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {sidebarWorkflow && (
                <Sidebar
                    width="w-[60vw]"
                    isOpen={!!sidebarWorkflow}
                    onClose={() => setSidebarWorkflow(null)}
                    header="Lead Information"
                >
                    <div className="grid grid-cols-2 gap-y-4 items-center mt-8">
                        <p className="text-black">LinkedIn Public Id</p>

                        <div className="flex gap-x-2 items-center">
                            <a
                                href={`https://www.linkedin.com/in/${sidebarWorkflow.linkedInPublicId}`}
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-600 underline"
                            >
                                {sidebarWorkflow.linkedInPublicId}
                            </a>

                            <img
                                src={LinkedInLogo}
                                alt="LinkedIn"
                                className="w-[20px] h-[20px] mr-2 cursor-pointer"
                                onClick={() => {
                                    // Open LinkedIn profile in a new tab
                                    window.open(
                                        `https://www.linkedin.com/in/${sidebarWorkflow.linkedInPublicId}`,
                                        "_blank"
                                    );
                                }}
                            />
                        </div>

                        {/* <p className="text-black">Wait Until Connection Request Accepted</p>

                        <Chip
                            color={
                                sidebarWorkflow.waitUntilConnectionRequestAccepted
                                    ? "success"
                                    : "warning"
                            }
                            size="sm"
                            variant="flat"
                        >
                            {sidebarWorkflow.waitUntilConnectionRequestAccepted ? "On" : "Off"}
                        </Chip> */}
                    </div>

                    <h2 className="text-black font-medium text-lg mt-4">Mapped Variables</h2>

                    <Table>
                        <TableHeader
                            columns={[
                                { key: "Key", label: "Key" },
                                {
                                    key: "Variable",
                                    label: "Variable",
                                    tooltip:
                                        "Use the given variable in your connection and message action"
                                },
                                { key: "Value", label: "Value" }
                            ]}
                        >
                            {(column) => (
                                <TableColumn key={column.key}>
                                    {column.label}
                                    {column.tooltip && (
                                        <Tooltip content={column.tooltip}>
                                            <Button
                                                isIconOnly
                                                variant="ghost"
                                                radius="full"
                                                className="border-none"
                                            >
                                                <IoIosInformationCircle className="text-purple" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </TableColumn>
                            )}
                        </TableHeader>
                        <TableBody
                            items={[
                                {
                                    label: "First Name",
                                    variable: "##first_name##",
                                    value: sidebarWorkflow.firstName
                                },
                                {
                                    label: "Last Name",
                                    variable: "##last_name##",
                                    value: sidebarWorkflow.lastName
                                },
                                {
                                    label: "Title",
                                    variable: "##title##",
                                    value: sidebarWorkflow.title
                                },
                                {
                                    label: "Company",
                                    variable: "##company##",
                                    value: sidebarWorkflow.company
                                },
                                {
                                    label: "Company Domain",
                                    variable: "##company_domain##",
                                    value: sidebarWorkflow.companyDomain
                                },
                                {
                                    label: "Company LinkedIn Url",
                                    variable: "##company_linkedin_url##",
                                    value: sidebarWorkflow.companyLinkedInUrl
                                }
                            ]}
                            emptyContent={"No rows to display."}
                        >
                            {(item) => (
                                <TableRow key={item.label}>
                                    <TableCell className="text-black font-medium">
                                        {item.label}
                                    </TableCell>
                                    <TableCell className="text-black font-medium">
                                        {item.variable}
                                    </TableCell>
                                    <TableCell className="text-black">{item.value}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <h2 className="text-black font-medium text-lg mt-4">Custom Variables</h2>

                    <Table>
                        <TableHeader
                            columns={[
                                {
                                    key: "Variable",
                                    label: "Variable",
                                    tooltip:
                                        "Use the given variable in your connection and message action"
                                },
                                { key: "Value", label: "Value" }
                            ]}
                        >
                            {(column) => (
                                <TableColumn key={column.key}>
                                    {column.label}
                                    {column.tooltip && (
                                        <Tooltip content={column.tooltip}>
                                            <Button
                                                isIconOnly
                                                variant="ghost"
                                                radius="full"
                                                className="border-none"
                                            >
                                                <IoIosInformationCircle className="text-purple" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </TableColumn>
                            )}
                        </TableHeader>
                        <TableBody
                            items={Object.keys(sidebarWorkflow.customVariables ?? {}).map(
                                (key) => ({
                                    label: "##" + key + "##",
                                    value: sidebarWorkflow.customVariables![key]
                                })
                            )}
                            emptyContent={"No custom variables to display."}
                        >
                            {(item) => (
                                <TableRow key={item.label}>
                                    <TableCell className="text-black font-medium">
                                        {item.label}
                                    </TableCell>
                                    <TableCell className="text-black">{item.value}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Sidebar>
            )}
        </div>
    );
}
