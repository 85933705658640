import { ImmutableArray, ImmutableObject, useHookstate } from "@hookstate/core";
import GlobalState from "../global/GlobalState";
import {
    Card,
    CardBody,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow
} from "@nextui-org/react";
import { UserHourlyHistoricalActivityType, UserType } from "../types/model";
import moment from "moment";
import Notification from "../components/Notification";
import { LineChart, DonutChart } from "@tremor/react";

type HistoricalActivityProps = {
    historicalActivity: ImmutableArray<UserHourlyHistoricalActivityType>;
};

function HistoricalActivity({ historicalActivity }: HistoricalActivityProps): JSX.Element {
    const today = moment();
    const thirtyDaysAgo = moment().subtract(29, "days");

    const last30DaysData: Record<string, Record<string, any>> = {};
    for (let d = thirtyDaysAgo.toDate(); d <= today.toDate(); d.setDate(d.getDate() + 1)) {
        const dateString = d.toISOString().split("T")[0];
        const activitiesForDay = historicalActivity.filter(
            (activity) =>
                new Date(activity.startEpoch * 1000).toISOString().split("T")[0] === dateString
        );

        const formattedDate = moment(dateString).format("MMM D");

        const aggregatedActivity = activitiesForDay.reduce(
            (acc, activity) => {
                acc["Connection Requests"] += activity.totalConnectionRequestsSent || 0;
                acc.Messages += activity.totalMessagesSent || 0;
                acc["Profile Views"] += activity.totalProfileViews || 0;
                acc.Likes += activity.totalLikes || 0;
                acc.Follows += activity.totalFollows || 0;
                acc.Withdraws += activity.totalWithdraws || 0;
                return acc;
            },
            {
                "Connection Requests": 0,
                Messages: 0,
                "Profile Views": 0,
                Likes: 0,
                Follows: 0,
                Withdraws: 0
            }
        );

        last30DaysData[dateString] = {
            date: formattedDate,
            ...aggregatedActivity
        };
    }

    return (
        <div className="w-full">
            <Card className="py-4 px-8 min-h-96">
                <CardHeader className="flex flex-row justify-between">
                    <p className="text-purple text-base font-bold">All Campaign Analytics</p>
                </CardHeader>
                <CardBody>
                    <div className="h-fit flex flex-col">
                        <LineChart
                            data={Object.values(last30DaysData)}
                            index="date"
                            categories={[
                                "Connection Requests",
                                "Messages",
                                "Profile Views",
                                "Likes",
                                "Follows",
                                "Withdraws"
                            ]}
                            colors={["indigo", "cyan", "orange", "green", "red", "rose"]}
                            yAxisWidth={60}
                            className="h-96"
                            curveType="monotone"
                            connectNulls={true}
                            onValueChange={(v) => console.log(v)}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

function LifetimeActivity({ user }: { user: ImmutableObject<UserType> | null }): JSX.Element {
    const lifetimeActivity = {
        connectionRequests: user?.lifetimeConnectionRequestsSent ?? 0,
        messages: user?.lifetimeMessagesSent ?? 0,
        profileViews: user?.lifetimeProfileViews ?? 0,
        likes: user?.lifetimeLikes ?? 0,
        follows: user?.lifetimeFollows ?? 0,
        withdraws: user?.lifetimeWithdraws ?? 0
    };

    const chartData = [
        { name: "Connection Requests", value: lifetimeActivity.connectionRequests },
        { name: "Messages", value: lifetimeActivity.messages },
        { name: "Profile Views", value: lifetimeActivity.profileViews },
        { name: "Likes", value: lifetimeActivity.likes },
        { name: "Follows", value: lifetimeActivity.follows },
        { name: "Withdraws", value: lifetimeActivity.withdraws }
    ];

    const allZero = chartData.every((item) => item.value === 0);
    const displayData = allZero ? [{ name: "No Activity", value: 1 }] : chartData;

    return (
        <Card className="w-full min-h-[28rem] h-fit py-4 px-8">
            <CardHeader>
                <p className="text-purple text-base font-bold">Lifetime Analytics</p>
            </CardHeader>
            <CardBody className="grid grid-cols-2 gap-6">
                <DonutChart
                    data={displayData}
                    category="value"
                    index="name"
                    colors={
                        allZero ? ["gray"] : ["indigo", "cyan", "orange", "green", "red", "rose"]
                    }
                    className="h-72"
                    showLabel={false}
                    valueFormatter={(value) => (allZero ? "" : value.toString())}
                />
                <Table>
                    <TableHeader
                        columns={[
                            { key: "Activity Type", label: "Activity Type" },
                            { key: "Count", label: "Count" }
                        ]}
                    >
                        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                    </TableHeader>
                    <TableBody>
                        {chartData.map(({ name, value }) => (
                            <TableRow key={name}>
                                <TableCell>{name}</TableCell>
                                <TableCell className="text-right">{value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardBody>
        </Card>
    );
}

export default function Home() {
    const userState = useHookstate(GlobalState.user);
    const user = userState.get();
    const billing = useHookstate(GlobalState.billing).get();
    const linkedInConfiguration = useHookstate(GlobalState.linkedInConfiguration).get();
    const historicalActivity = useHookstate(GlobalState.historicalActivity).get();

    // Check if the subscription has expired or not
    const expired = billing?.subscriptionActiveThroughTs
        ? moment(billing.subscriptionActiveThroughTs) < moment()
        : false;

    let subscriptionMessage: string = "";
    if (expired) {
        const expirationDate = moment(billing?.subscriptionActiveThroughTs);
        const deletionDate = expirationDate.add(3, "days").toDate().toLocaleDateString();

        if (moment().isAfter(deletionDate)) {
            subscriptionMessage = `Your subscription expired on ${expirationDate.toDate().toLocaleDateString()}. Please renew to continue using the service.`;
        } else {
            subscriptionMessage = `Your subscription has expired, so no actions will be performed. Your LinkedIn will disconnect on ${deletionDate}`;
        }
    }

    return (
        <div className="flex-1 bg-white p-8 flex flex-col gap-y-8 overflow-y-scroll">
            {expired && <Notification text={subscriptionMessage} />}
            {!linkedInConfiguration && (
                <Notification
                    text="Please connect your LinkedIn account to start automating."
                    link={{ text: "LinkedIn", url: "/integrations" }}
                />
            )}

            <HistoricalActivity historicalActivity={historicalActivity} />

            <LifetimeActivity user={user} />
        </div>
    );
}
