import { Button, Card, CardBody, CardFooter, CardHeader } from "@nextui-org/react";
import Api from "../../global/Api";
import { useHookstate } from "@hookstate/core";
import GlobalState from "../../global/GlobalState";
import { SubscriptionType } from "../../types/model";
import { FaCheckCircle } from "react-icons/fa";
import { usePostHog } from "posthog-js/react";
import toast from "react-hot-toast";

async function createCheckout(type: SubscriptionType): Promise<string | undefined | null> {
    const priceId =
        type === SubscriptionType.STARTER
            ? "price_1PKEYh09MpsTekp811rkOql3"
            : "price_1PKEZD09MpsTekp8bF0cquPH";

    const response = await Api.billing.createStripeCheckoutSession(priceId);
    if ("error" in response) {
        toast.error("Error creating the checkout session.");
        return;
    }

    return response.sessionUrl;
}

const starterFeatures: string[] = [
    "Unlimited Leads",
    "LinkedIn Sales Channel",
    "Fully Cloud-Based",
    "Graphical Sequence Builder",
    "Advance Scheduling Algorithm",
    "CSV Export & Import",
    "Limits Control Center",
    "Advanced analytics",
    "Dedicated IP Address",
    "Flexible working hours",
    "Slack Integration",
    "Intercom Support",
    "API Access",
    "Zapier Integration",
    "Rewrite with AI"
];

const proFeatures: string[] = [
    "Everything in Starter",
    "500 AI GPT-4o Credits",
    "AI Message Personalization",
    "AI Lead & Account Research",
    "Webhook",
    "Team Management",
    "Priority Support",
    "Super Premium IP"
];

function TrialBilling() {
    const billingState = useHookstate(GlobalState.billing);
    const billing = billingState.get();

    const posthog = usePostHog();

    if (!billing) {
        return <></>;
    }

    return (
        <div className="flex flex-col gap-y-8">
            <Card className="p-4 max-w-72">
                <CardHeader>
                    <p className="text-2xl text-purple">Trial</p>
                </CardHeader>
                <CardBody>
                    <p className="text-balance mb-4">
                        Active Until: {new Date(billing.subscriptionActiveThroughTs).toDateString()}
                    </p>
                    <p className="text-balance">
                        Upgrade to a subscription to be able to manage your plan, and view your
                        invoices.
                    </p>
                </CardBody>
            </Card>
            <div className="flex flex-row gap-x-4">
                <Card className="p-4 min-w-72">
                    <CardHeader className="text-2xl text-purple">Starter</CardHeader>
                    <CardBody className="gap-y-2">
                        <p className="mb-4">
                            <span className="text-4xl">$79</span>/mo
                        </p>
                        {starterFeatures.map((feature, index) => (
                            <div key={index} className="flex flex-row gap-x-2 items-center">
                                <FaCheckCircle />
                                <span>{feature}</span>
                            </div>
                        ))}
                    </CardBody>
                    <CardFooter>
                        <Button
                            className="bg-purple w-full text-white"
                            onClick={async () => {
                                const sessionUrl = await createCheckout(SubscriptionType.STARTER);

                                // Log the event to posthog
                                posthog?.capture("Subscription Checkout", {
                                    subscriptionType: SubscriptionType.STARTER
                                });

                                if (sessionUrl) {
                                    window.location.href = sessionUrl;
                                }
                            }}
                        >
                            Subscribe
                        </Button>
                    </CardFooter>
                </Card>
                {/* <Card className="p-4 min-w-72">
                    <CardHeader className="text-2xl text-purple">Pro</CardHeader>
                    <CardBody className="gap-y-2">
                        <p className="mb-4">
                            <span className="text-4xl">$199</span>/mo
                        </p>
                        {proFeatures.map((feature, index) => (
                            <div key={index} className="flex flex-row gap-x-2 items-center">
                                <FaCheckCircle />
                                <span>{feature}</span>
                            </div>
                        ))}
                    </CardBody>
                    <CardFooter>
                        <Button
                            isDisabled
                            className="bg-purple w-full text-white"
                            onClick={async () => {
                                const sessionUrl = await createCheckout(SubscriptionType.PRO);

                                // Log the event to posthog
                                posthog?.capture("Subscription Checkout", {
                                    subscriptionType: SubscriptionType.PRO
                                });

                                if (sessionUrl) {
                                    window.location.href = sessionUrl;
                                }
                            }}
                        >
                            Coming Soon
                        </Button>
                    </CardFooter>
                </Card> */}
            </div>
        </div>
    );
}

export default function Billing() {
    const billingState = useHookstate(GlobalState.billing);
    const billing = billingState.get();

    const posthog = usePostHog();

    const shouldShowClientPortal = !!billing?.stripeSubscriptionId;
    const billingPortalUrl = billing?.billingPortalUrl;

    if (!billing) {
        return (
            <Card>
                <CardHeader>Manage your subscription</CardHeader>
                <CardBody>
                    <p>Something went wrong. Please reload.</p>
                </CardBody>
            </Card>
        );
    }

    const displaySubscription =
        billing.subscription === SubscriptionType.STARTER
            ? "Starter"
            : billing.subscription === SubscriptionType.PRO
              ? "Pro"
              : billing.subscription === SubscriptionType.ENTERPRISE
                ? "Enterprise"
                : "Trial";

    const trialSubscription = billing.subscription === SubscriptionType.TRIAL;

    if (trialSubscription) {
        return <TrialBilling />;
    }

    return (
        <div className="w-fit">
            {shouldShowClientPortal ? (
                <Card className="p-4 max-w-72">
                    <CardHeader>
                        <p className="text-2xl text-purple">{displaySubscription}</p>
                    </CardHeader>
                    <CardBody>
                        <p className="text-balance mb-4">
                            Active Until:{" "}
                            {new Date(billing.subscriptionActiveThroughTs).toDateString()}
                        </p>
                        <p className="text-balance">
                            You can cancel your plan, upgrade your plan, and view your invoices
                        </p>
                    </CardBody>
                    <CardFooter>
                        {billingPortalUrl && (
                            <Button
                                className="mt-4 bg-purple text-white w-full"
                                onClick={() => {
                                    window.open(billingPortalUrl, "_blank");
                                }}
                            >
                                Manage Subscription
                            </Button>
                        )}
                    </CardFooter>
                </Card>
            ) : (
                <div className="flex flex-row gap-x-4">
                    <Card className="p-4 min-w-72">
                        <CardHeader className="text-2xl text-purple">Starter</CardHeader>
                        <CardBody className="gap-y-2">
                            <p className="mb-4">
                                <span className="text-4xl">$79</span>/mo
                            </p>
                            {starterFeatures.map((feature, index) => (
                                <div key={index} className="flex flex-row gap-x-2 items-center">
                                    <FaCheckCircle />
                                    <span>{feature}</span>
                                </div>
                            ))}
                        </CardBody>
                        <CardFooter>
                            <Button
                                className="bg-purple w-full text-white"
                                onClick={async () => {
                                    const sessionUrl = await createCheckout(
                                        SubscriptionType.STARTER
                                    );

                                    // Log the event to posthog
                                    posthog?.capture("Subscription Checkout", {
                                        subscriptionType: SubscriptionType.STARTER
                                    });

                                    if (sessionUrl) {
                                        window.location.href = sessionUrl;
                                    }
                                }}
                            >
                                Subscribe
                            </Button>
                        </CardFooter>
                    </Card>
                    {/* <Card className="p-4 min-w-72">
                        <CardHeader className="text-2xl text-purple">Pro</CardHeader>
                        <CardBody className="gap-y-2">
                            <p className="mb-4">
                                <span className="text-4xl">$199</span>/mo
                            </p>
                            {proFeatures.map((feature, index) => (
                                <div key={index} className="flex flex-row gap-x-2 items-center">
                                    <FaCheckCircle />
                                    <span>{feature}</span>
                                </div>
                            ))}
                        </CardBody>
                        <CardFooter>
                            <Button
                                isDisabled
                                className="bg-purple w-full text-white"
                                onClick={async () => {
                                    const sessionUrl = await createCheckout(SubscriptionType.PRO);

                                    // Log the event to posthog
                                    posthog?.capture("Subscription Checkout", {
                                        subscriptionType: SubscriptionType.PRO
                                    });

                                    if (sessionUrl) {
                                        window.location.href = sessionUrl;
                                    }
                                }}
                            >
                                Coming Soon
                            </Button>
                        </CardFooter>
                    </Card> */}
                </div>
            )}
        </div>
    );
}
