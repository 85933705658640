export type ErrorResponseType = {
    status: number;
    error: {
        message: string;
        details: string;
        code: number;
    };
};

export type UserType = {
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    imageUrl: string | null;
    apiKey?: string | null;
    workingHours: WorkingHoursType[];
    limits: UserLimitsType;
    billing: UserBillingType;
    createdAt: Date;
    updatedAt: Date;
    totalCampaigns: number;
    activeCampaigns: number;
    totalUniqueLeads: number;
    lifetimeConnectionRequestsSent: number;
    lifetimeMessagesSent: number;
    lifetimeProfileViews: number;
    lifetimeLikes: number;
    lifetimeFollows: number;
    lifetimeInMailSent: number;
    lifetimeWithdraws: number;
};

export type WorkingHoursType = {
    id: string;
    dayOfWeek: number;
    start: Date;
    end: Date;
    timezone: string;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;
};

export type LinkedInConfigurationType = {
    id: string;
    userId: string;
    linkedInUsername: string;
    linkedInUrnId: string | null;
    linkedInPublicId: string | null;
    allocatedIPAddress: string | null;
    createdAt: Date;
    updatedAt: Date;
};

export type UserLimitsType = {
    id: string;
    fetchProfilesLimit: number;
    connectionRequestsLimit: number;
    messagesLimit: number;
    profileViewsLimit: number;
    likesLimit: number;
    followLimit: number;
    inMailLimit: number;
    withdrawLimit: number;
    createdAt: Date;
    updatedAt: Date;
};

export type UserBillingType = {
    userId: string;
    subscription: SubscriptionType;
    subscriptionActiveThroughTs: Date;
    subscriptionCost: string;
    usedTrialPeriod: boolean;
    trialPeriodStartDate: Date;
    trialPeriodEndDate: Date;
    stripeCustomerId: string | null;
    stripeSubscriptionId: string | null;
    billingPortalUrl: string | null;
};

export enum SubscriptionType {
    TRIAL = "TRIAL",
    STARTER = "STARTER",
    PRO = "PRO",
    ENTERPRISE = "ENTERPRISE"
}

export type UserHourlyHistoricalActivityType = {
    userId: string;
    startEpoch: number;
    endEpoch: number;
    totalProfilesFetched: number;
    totalConnectionRequestsSent: number;
    totalMessagesSent: number;
    totalProfileViews: number;
    totalLikes: number;
    totalFollows: number;
    totalInMailSent: number;
    totalWithdraws: number;
    createdAt: Date;
    updatedAt: Date;
};

export type UserLeadsType = {
    userId: string;
    leadId: string;
    linkedInPublicId: string;
    firstName: string | null;
    lastName: string | null;
    title: string | null;
    company: string | null;
    companyDomain: string | null;
    companyLinkedInUrl: string | null;
    createdAt: Date;
    updatedAt: Date;
};

export type MasterLeadType = {
    id: string;
    linkedInUrnId: string;
    linkedInPublicId: string;
    firstName: string;
    lastName: string;
    company: string;
    createdAt: Date;
    updatedAt: Date;
};

export enum TaskType {
    LINKEDIN_FETCH_PROFILE = "LINKEDIN_FETCH_PROFILE",
    LINKEDIN_CONNECT_WITH_NOTE = "LINKEDIN_CONNECT_WITH_NOTE",
    LINKEDIN_MESSAGE = "LINKEDIN_MESSAGE",
    LINKEDIN_VIEW_PROFILE = "LINKEDIN_VIEW_PROFILE",
    LINKEDIN_LIKE = "LINKEDIN_LIKE",
    LINKEDIN_FOLLOW = "LINKEDIN_FOLLOW",
    LINKEDIN_UNFOLLOW = "LINKEDIN_UNFOLLOW",
    LINKEDIN_IN_MAIL = "LINKEDIN_IN_MAIL",
    LINKEDIN_WITHDRAW = "LINKEDIN_WITHDRAW"
}

export type SequenceType = {
    nodeId: number;
    delay: number;
    taskType: TaskType;
    taskData: {
        note?: string | null;
        message?: string | null;
        altMessage?: string | null;
    };
    neighbors: {
        nodeId: number;
    }[];
};

export type CampaignType = {
    workflowId: string;
    userId: string;
    name: string;
    sequence: SequenceType[];
    active: boolean;
    archived: boolean;
    totalLeadsAdded: number;
    totalLeadsAccepted: number;
    totalLeadsFinished: number;
    totalLeadsInProgress: number;
    totalLeadsRejected: number;
    connectionRequestCount: number;
    messageCount: number;
    profileViewCount: number;
    likeCount: number;
    followCount: number;
    inMailCount: number;
    withdrawCount: number;
    replyCount: number;
    acceptCount: number;
    excludeLeadsFoundInOtherWorkflows: boolean;
    excludeLeadsFirstDegreeConnection: boolean;
    excludeLeadsWithNoPhoto: boolean;
    sendConnectionNoteAsMessageIfAlreadyConnected: boolean;
    waitUntilConnectionRequestAccepted: boolean;
    waitUntilConnectionRequestAcceptedDelay: number;
    overrideWorkingHours: WorkingHoursType[] | null;
    externalCampaignId: string | null;
    createdAt: Date;
    updatedAt: Date;
};

export enum WorkflowLeadStatusType {
    NEW = "NEW",
    SCHEDULED = "SCHEDULED",
    FETCHING = "FETCHING",
    FETCH_SUCCESS = "FETCH_SUCCESS",
    FETCH_FAILED = "FETCH_FAILED",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED"
}

export enum WorkflowExecutionStatusType {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    SKIPPED = "SKIPPED",
    REPLIED = "REPLIED",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

export type CustomVariables = {
    [key: string]: string;
};

export type WorkflowExecutionType = {
    id: string;
    workflowId: string;
    leadStatus: WorkflowLeadStatusType;
    leadId: string;
    linkedInPublicId: string;
    status: WorkflowExecutionStatusType;
    scheduleNextTask: boolean;
    active: boolean;
    currentTask: WorkflowExecutionTaskType | null;
    waitUntilConnectionRequestAccepted: boolean;
    firstName: string | null;
    lastName: string | null;
    title: string | null;
    company: string | null;
    companyDomain: string | null;
    companyLinkedInUrl: string | null;
    customVariables: CustomVariables | null;
    createdAt: Date;
    updatedAt: Date;
};

export type WorkflowExecutionTaskType = {
    taskId: string;
    workflowExecutionId: string;
    taskType: TaskType;
    status: "SCHEDULED" | "QUEUED" | "SUCCESS" | "FAILED" | "SKIPPED";
    createdAt: Date;
    updatedAt: Date;
    executedAt: Date | null;
    executeAt: Date;
    taskMessage: string | null;
    taskAltMessage: string | null;
    taskNote: string | null;
};

export type TemplateType = {
    templateId: string;
    name: string;
    sequence: SequenceType[];
    createdAt: Date;
    updatedAt: Date;
};

export enum UserIntegrationCategoryType {
    SLACK = "SLACK"
}

export type UserIntegrationType = {
    id: string;
    type: UserIntegrationCategoryType;
    expiresAt: number;
    createdAt: Date;
    updatedAt: Date;
};
