import { ImmutableObject, useHookstate } from "@hookstate/core";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader
} from "@nextui-org/react";
import LinkedInLogo from "../../assets/li_logo.png";
import { FaUnlockAlt } from "react-icons/fa";
import GlobalState from "../../global/GlobalState";
import Api from "../../global/Api";
import { useCallback, useEffect, useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { IoIosEye } from "react-icons/io";
import SlackLogo from "../../assets/slack_logo.png";
import { FaKey } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { UserIntegrationCategoryType, UserIntegrationType } from "../../types/model";
import { CiCircleCheck } from "react-icons/ci";
import { IconContext } from "react-icons";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdOutlineContentCopy } from "react-icons/md";
import toast from "react-hot-toast";

type SlackCardProps = {
    slackIntegration?: ImmutableObject<UserIntegrationType>;
};

function SlackCard(props: SlackCardProps): JSX.Element {
    const { slackIntegration } = props;

    return (
        <Card className="w-[320px] h-[320px] p-4">
            <CardHeader>
                <img src={SlackLogo} alt="Slack" className="w-[20px] h-[20px] mr-2" />
                <p className="text-2xl">Slack</p>
            </CardHeader>
            <CardBody className="justify-center items-center gap-y-4">
                {slackIntegration ? (
                    <IconContext.Provider value={{ color: "green", size: "100px" }}>
                        <CiCircleCheck />
                    </IconContext.Provider>
                ) : (
                    <p>Connect to receive real-time slack alerts of executed LinkedIn actions</p>
                )}
            </CardBody>
            <CardFooter className="flex flex-row justify-center">
                {slackIntegration ? (
                    <>
                        <Button
                            size="md"
                            variant="solid"
                            color="danger"
                            className="bg-red-500 text-white w-full"
                            onClick={async () => {
                                const response = await Api.integration.disconnectSlackIntegration();
                                if (response) {
                                    // Delete the slack integration from the state
                                    GlobalState.integrations.set((prevState) => {
                                        if (!prevState) {
                                            return prevState;
                                        }

                                        return prevState.filter(
                                            (integration) =>
                                                integration.type !==
                                                UserIntegrationCategoryType.SLACK
                                        );
                                    });
                                    toast.success("Slack integration disconnected");
                                } else {
                                    toast.error("Failed to disconnect Slack integration");
                                }
                            }}
                        >
                            Disconnect
                        </Button>
                    </>
                ) : (
                    <a
                        href="https://slack.com/oauth/v2/authorize?scope=incoming-webhook%2Cchat%3Awrite&amp;user_scope=&amp;redirect_uri=https%3A%2F%2Fapp.themagicdrip.com%2Fintegration%2Fslack%2Fcallback&amp;client_id=7151613212977.7124391675351"
                        className="self-center items-center	text-black bg-white border border-[#ddd] rounded-md inline-flex h-[48px] justify-center w-full"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[20px] w-[20px] mr-4"
                            viewBox="0 0 122.8 122.8"
                        >
                            <path
                                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                                fill="#e01e5a"
                            ></path>
                            <path
                                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                                fill="#36c5f0"
                            ></path>
                            <path
                                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                                fill="#2eb67d"
                            ></path>
                            <path
                                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                                fill="#ecb22e"
                            ></path>
                        </svg>
                        Add to Slack
                    </a>
                )}
            </CardFooter>
        </Card>
    );
}

function APICard(): JSX.Element {
    const user = GlobalState.user.get();

    const buttonTitle = user?.apiKey ? "Refresh" : "Generate";

    const displayApiKey = user?.apiKey ?? "";

    const createApiKey = useCallback(
        async () => {
            const response = await Api.user.createApiKey();
            if (!response) {
                toast.error("Failed to create API key.");
                return;
            }

            GlobalState.user.set((prevState) => {
                if (!prevState) {
                    return prevState;
                }

                return {
                    ...prevState,
                    apiKey: response.apiKey
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Card className="w-[320px] h-[320px] p-4">
            <CardHeader>
                <FaKey className="w-[20px] h-[20px] mr-2" />
                <p className="text-2xl">API</p>
            </CardHeader>
            <CardBody className="flex flex-col gap-y-4 justify-center items-start">
                <div className="flex flex-row w-full">
                    <Input
                        isDisabled
                        type="email"
                        label="API Key"
                        labelPlacement="outside"
                        value={displayApiKey.slice(0, 12) + "X".repeat(6)}
                    />
                    <CopyToClipboard text={displayApiKey}>
                        <Button isIconOnly className="bg-transparent self-end">
                            <MdOutlineContentCopy />
                        </Button>
                    </CopyToClipboard>
                </div>
            </CardBody>
            <CardFooter>
                <Button
                    size="md"
                    variant="solid"
                    className="bg-green-500 text-white w-full"
                    onClick={createApiKey}
                >
                    {buttonTitle}
                </Button>
            </CardFooter>
        </Card>
    );
}

export default function Integrations(): JSX.Element {
    const linkedInConfigurationState = useHookstate(GlobalState.linkedInConfiguration);
    const linkedInConfiguration = linkedInConfigurationState.get();

    const billing = useHookstate(GlobalState.billing).get();
    const expired = billing?.subscriptionActiveThroughTs
        ? moment(billing.subscriptionActiveThroughTs) < moment()
        : false;

    const [username, setUsername] = useState<string | undefined>(
        linkedInConfiguration?.linkedInUsername
    );
    const [password, setPassword] = useState<string | undefined>();
    const [challengeCode, setChallengeCode] = useState<string | null>(null);
    const [challengeCodeRequested, setChallengeCodeRequested] = useState<boolean>(false);
    const [verificationSentToApp, setVerificationSentToApp] = useState<boolean>(false);
    const [connecting, setConnecting] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [disconnecting, setDisconnecting] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const integrations = useHookstate(GlobalState.integrations).get();

    useEffect(
        () => {
            const createSlackIntegration = async () => {
                // Check the pathname to match /integration/slack/callback
                if (
                    window.location.pathname === "/integration/slack/callback" &&
                    urlSearchParams.get("code")
                ) {
                    // Call the API to create the slack integration
                    const response = await Api.integration.createSlackIntegration(
                        urlSearchParams.get("code")!
                    );

                    if ("error" in response) {
                        toast.error(response.error.details);
                        return;
                    }

                    const integration = response.integration;
                    GlobalState.integrations.set((prevState) => {
                        return [
                            ...prevState.filter((inte) => inte.id !== integration.id),
                            integration
                        ];
                    });

                    // Reset the pathname
                    window.history.replaceState(null, "", "/integrations");
                }
            };

            createSlackIntegration();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const processChallengeCode = useCallback(
        async (challengeCode: string | null, verificationSentToApp: boolean) => {
            if (!verificationSentToApp && (!challengeCode || challengeCode.length === 0)) {
                toast.error("Please fill in the challenge code field.");
                return;
            }

            setVerifying(true);

            try {
                const response = await Api.integration.provideLinkedInChallengeToken(
                    verificationSentToApp,
                    challengeCode
                );

                if ("error" in response || !response.success) {
                    toast.error("Failed to verify. Please try again");
                    return;
                }

                // Retrieve the updated configuration
                const configuration = await Api.integration.retrieveLinkedInIntegration();
                if (configuration) {
                    linkedInConfigurationState.set(configuration.configuration);
                }
            } finally {
                setVerifying(false);
                setVerificationSentToApp(false);
                setChallengeCodeRequested(false);
                setChallengeCode(null);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const connectButtonHandler = useCallback(
        async (username: string, password: string) => {
            if (!username || !password) {
                toast.error("Please fill in the username and password fields.");
                return;
            }

            try {
                // Set the connecting state to true
                setConnecting(true);

                const response = await Api.integration.createLinkedInIntegration(
                    username,
                    password
                );

                if ("error" in response) {
                    toast.error(response.error.details);
                    return;
                }

                // Check if we need a challenge code
                if (response.challengeCodeRequested) {
                    setChallengeCodeRequested(true);
                    return;
                }

                // Check if the verification was sent to the app
                if (response.verificationSentToApp) {
                    setVerificationSentToApp(true);
                    return;
                }

                if (response.success) {
                    // Retrieve the updated configuration
                    const configuration = await Api.integration.retrieveLinkedInIntegration();
                    if (configuration) {
                        linkedInConfigurationState.set(configuration.configuration);
                    }
                }
            } finally {
                setConnecting(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const disconnectButtonHandler = useCallback(
        async () => {
            // Set the disconnecting state to true
            setDisconnecting(true);

            try {
                const response = await Api.integration.disconnectLinkedInIntegration();

                if ("error" in response) {
                    toast.error(response.error.details);
                    return;
                }

                // Update the linkedInConfiguration state
                linkedInConfigurationState.set(response.configuration);
            } finally {
                setDisconnecting(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const slackIntegration = integrations.find(
        (integration) => integration.type === UserIntegrationCategoryType.SLACK
    );

    return (
        <div className="p-8 flex flex-col gap-y-8">
            <div className="grid grid-cols-3 gap-8">
                <Card className="w-[320px] h-[320px] p-4">
                    <CardHeader>
                        <img src={LinkedInLogo} alt="LinkedIn" className="w-[20px] h-[20px] mr-2" />
                        <p className="text-2xl">LinkedIn</p>
                    </CardHeader>
                    <CardBody className="justify-center gap-y-4">
                        <Input
                            isDisabled={linkedInConfiguration !== null || expired}
                            isRequired
                            type="text"
                            label="Username"
                            classNames={{ base: "w-full" }}
                            value={username}
                            onValueChange={(value) => setUsername(value)}
                        />
                        {!linkedInConfiguration && (
                            <Input
                                isDisabled={linkedInConfiguration !== null || expired}
                                isRequired
                                type={isVisible ? "text" : "password"}
                                label="Password"
                                classNames={{ base: "w-full" }}
                                value={password}
                                onValueChange={(value) => setPassword(value)}
                                endContent={
                                    <button
                                        className="focus:outline-none"
                                        onClick={() => setIsVisible(!isVisible)}
                                    >
                                        {isVisible ? (
                                            <FaEyeSlash className="text-2xl text-default-400 pointer-events-none" />
                                        ) : (
                                            <IoIosEye className="text-2xl text-default-400 pointer-events-none" />
                                        )}
                                    </button>
                                }
                            />
                        )}
                        {linkedInConfiguration?.allocatedIPAddress && (
                            <Input
                                isDisabled
                                type="text"
                                label="Assigned IP Address"
                                defaultValue={linkedInConfiguration.allocatedIPAddress}
                                classNames={{ base: "w-full" }}
                                endContent={
                                    <Button isIconOnly className="bg-transparent">
                                        <FaUnlockAlt />
                                    </Button>
                                }
                            />
                        )}
                    </CardBody>
                    <CardFooter>
                        {linkedInConfiguration ? (
                            <Button
                                size="md"
                                variant="solid"
                                className="bg-red-500 text-white w-full"
                                isLoading={disconnecting}
                                onClick={() => disconnectButtonHandler()}
                            >
                                Disconnect
                            </Button>
                        ) : (
                            <Button
                                size="md"
                                variant="solid"
                                className="bg-green-500 text-white w-full"
                                isLoading={connecting}
                                onClick={() => connectButtonHandler(username ?? "", password ?? "")}
                            >
                                Connect
                            </Button>
                        )}
                    </CardFooter>
                </Card>

                <SlackCard slackIntegration={slackIntegration} />

                <APICard />

                <Modal isOpen={verificationSentToApp} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader className="flex flex-col gap-1">
                            Verification Sent to LinkedIn App on your phone
                        </ModalHeader>
                        <ModalBody className="flex gap-y-4">
                            <p>
                                LinkedIn sent a verification notification to your LinkedIn app.
                                Please tap Yes in the app to complete the process.
                            </p>
                            <p>
                                Only press 'Continue' below once you have completed the verification
                                in the LinkedIn app.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="default"
                                onClick={async () => setVerificationSentToApp(false)}
                            >
                                Close
                            </Button>
                            <Button
                                isLoading={verifying}
                                color="primary"
                                onClick={() => processChallengeCode(null, true)}
                            >
                                Continue
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={challengeCodeRequested} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader className="flex flex-col gap-1">
                            Enter security code
                        </ModalHeader>
                        <ModalBody>
                            <Input
                                type="text"
                                label="Security Code"
                                classNames={{ base: "w-full" }}
                                isRequired
                                value={challengeCode ?? undefined}
                                onValueChange={(value) => setChallengeCode(value)}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="default"
                                onClick={async () => setChallengeCodeRequested(false)}
                            >
                                Close
                            </Button>
                            <Button
                                isLoading={verifying}
                                color="primary"
                                onClick={async () => processChallengeCode(challengeCode, false)}
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        </div>
    );
}
