import { Handle, NodeProps, Position } from "reactflow";

type FlowTerminalNodeProps = {
    key: string;
    terminalType: "START" | "END";
};

export default function FlowTerminalNode(props: NodeProps<FlowTerminalNodeProps>) {
    const { terminalType } = props.data;
    return (
        <>
            {terminalType === "END" && <Handle type="target" position={Position.Left} />}
            <div className="flex flex-col min-h-[60px] h-auto w-[100px] border-solid bg-white border-purple-600 border rounded-sm">
                <div className="flex-1 flex justify-center items-center">
                    <p className="text-[8px] text-center text-black font-semibold w-full h-fit p-auto">
                        {props.data.terminalType === "START" ? "Start" : "End"}
                    </p>
                </div>
            </div>
            {terminalType === "START" && <Handle type="source" position={Position.Right} />}
        </>
    );
}
