import { Link } from "react-router-dom";

type NotificationProps = {
    text: string;
    link?: { text: string; url: string };
};

export default function Notification(props: NotificationProps) {
    const { text, link } = props;

    return (
        <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative flex flex-row justify-start gap-x-1 items-center"
            role="alert"
        >
            {link ? (
                <>
                    {text.split(link.text)[0]}
                    <Link className="w-fit m-0 p-0 text-blue-700 underline" to={link.url}>
                        {link.text}
                    </Link>
                    {text.split(link.text)[1]}
                </>
            ) : (
                text
            )}
        </div>
    );
}
